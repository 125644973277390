import React, { useState } from 'react'
import {
  Image,
  HStack,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  ModalContent,
  useDisclosure,
  Button,
  InputGroup,
  InputLeftElement,
  Box,
  Text,
  PinInput,
  PinInputField,
  RadioGroup,
  Radio,
  VStack,
  Stack,
  NumberInput,
  NumberInputField,
  Alert,
  AlertIcon,
  Center,
  NumberInputStepper,
  NumberIncrementStepper
} from '@chakra-ui/react'
import xxuContract from 'utils/Contract'

function ChangeContractAddress() {
  const [moonAddress, setMoonAddress] = useState('')
  const [saleAddress, setSaleAddress] = useState('')

  const setAddressContract = () => {
    xxuContract.SetContract(saleAddress, moonAddress)
  }

  return (
    <Box h="100vh">
      <Center h="100%">
        <Stack>
          <Center my='20px' fontSize='xl'>CHANGE ADDRESS CONTRACT</Center>
          <Box>
            TOKEN MOON ADDRESS{' '}
            <Input
              value={moonAddress}
              onChange={value => {
                setMoonAddress(value.target.value)
              }}
            />
          </Box>
          <Box>
            ICO CONTRACT ADDRESS{' '}
            <Input
              value={saleAddress}
              onChange={value => {
                setSaleAddress(value.target.value)
              }}
            />
          </Box>
          <Box>
            <Button
            colorScheme='teal'
              onClick={() => {
                setAddressContract()
              }}
              disabled={moonAddress && saleAddress ? false : true}
            >
              Submit
            </Button>
          </Box>
        </Stack>
      </Center>
    </Box>
  )
}

export default ChangeContractAddress
