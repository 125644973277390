// import { web3 } from 'redux/Reducers/Web3';
import { useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// Types
import xxreStoreTypes from 'redux/types'

// Redux Constants
import {
  CONNECT_WALLET_SUCCESS,
  CONNECT_WALLET_FAIL,
  CONNECT_WALLET_CLEAR
} from 'redux/Constants/ConnectWallet'

// Utils
import xxuWeb3 from 'utils/Web3'

//Web3
import Web3 from 'web3'

//Utils
import xxuContract from 'utils/Contract'

const xxaContract = {
  // ConnectWeb3: () => {
  //   const connectWallet = useSelector(
  //     (state: xxreStoreTypes.Props) => state.connectWallet
  //   )
  //   if (window.ethereum) {
  //     // let w = new Web3(window.ethereum)
  //     // let netID = await w.eth.getChainId()
  //     if (connectWallet.connect?.netID) {
  //       let web3 = new Web3(window.ethereum)
  //       return web3
  //     } else {
  //       let web3 = new Web3(new Web3.providers.HttpProvider(xxuWeb3.rpc))
  //       return web3
  //     }
  //   } else {
  //     let web3 = new Web3(new Web3.providers.HttpProvider(xxuWeb3.rpc))
  //     return web3
  //   }
  // },
  // ContractMoonToken: () => {
  //   const connectWeb3 = useSelector((state: xxreStoreTypes.Props) => state.web3)

  //   const contract = useMemo(() => {
  //     let web3 = connectWeb3.web3
  //     console.log('contractCUsd', web3)
  //     if (!web3) return null
  //     try {
  //       const c = new web3.eth.Contract(
  //         xxuContract.GetContract().MOONAbi,
  //         xxuContract.GetContract().MOONAddress
  //       )
  //       return c
  //     } catch (err) {
  //       console.log(err)
  //       return null
  //     }
  //   }, [connectWeb3])

  //   return contract
  // },
  // ContractSale: () => {
  //   // let web3 = new Web3(window.ethereum)
  //   const connectWeb3 = useSelector((state: xxreStoreTypes.Props) => state.web3)
  //   // let web3 = xxaContract.ConnectWeb3()
  //   const contract = useMemo(() => {
  //     let web3 = connectWeb3.web3
  //     console.log('contractSale', web3)
  //     if (!web3) return null
  //     try {
  //       const c = new web3.eth.Contract(
  //         xxuContract.GetContract().SaleAbi,
  //         xxuContract.GetContract().SaleAddress
  //       )
  //       return c
  //     } catch (err) {
  //       console.log(err)
  //       return null
  //     }
  //   }, [connectWeb3])

  //   return contract
  // },
  // ContractCUsd: () => {
  //   // let web3 = new Web3(window.ethereum)
  //   const connectWeb3 = useSelector((state: xxreStoreTypes.Props) => state.web3)
  //   // let web3 = xxaContract.ConnectWeb3()
  //   const contract = useMemo(() => {
  //     let web3 = connectWeb3.web3
  //     console.log('contractCUsd', web3)
  //     if (!web3) return null
  //     try {
  //       const c = new web3.eth.Contract(
  //         xxuContract.GetContract().cUSDAbi,
  //         xxuContract.GetContract().cUSDAddress
  //       )
  //       return c
  //     } catch (err) {
  //       console.log(err)
  //       return null
  //     }
  //   }, [connectWeb3])

  //   return contract
  // },
  ContractToken: (contractName: string) => {
    // let web3 = new Web3(window.ethereum)
    const connectWeb3 = useSelector((state: xxreStoreTypes.Props) => state.web3)
    // let web3 = xxaContract.ConnectWeb3()
    const contract = useMemo(() => {
      let web3 = connectWeb3.web3
      console.log('contractName', contractName)
      if (!web3) return null
      try {
        const c =
          contractName === 'sale'
            ? new web3.eth.Contract(
                xxuContract.GetContract().SaleAbi,
                xxuContract.GetContract().SaleAddress
              )
            : contractName === 'cUSD'
            ? new web3.eth.Contract(
                xxuContract.GetContract().cUSDAbi,
                xxuContract.GetContract().cUSDAddress
              )
            : contractName === 'BTC'
            ? new web3.eth.Contract(
                xxuContract.GetContract().BTCAbi,
                xxuContract.GetContract().BTCAddress
              )
            : contractName === 'ETH'
            ? new web3.eth.Contract(
                xxuContract.GetContract().ETHAbi,
                xxuContract.GetContract().ETHAddress
              )
            : contractName === 'BUSD'
            ? new web3.eth.Contract(
                xxuContract.GetContract().BUSDAbi,
                xxuContract.GetContract().BUSDAddress
              )
            : contractName === 'USDT'
            ? new web3.eth.Contract(
                xxuContract.GetContract().USDTAbi,
                xxuContract.GetContract().USDTAddress
              )
            : new web3.eth.Contract(
                xxuContract.GetContract().MOONAbi,
                xxuContract.GetContract().MOONAddress
              )
        return c
      } catch (err) {
        console.log(err)
        return null
      }
    }, [connectWeb3])

    return contract
  }
}

export default xxaContract
