import { useEffect, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Types
import xxreStoreTypes from 'redux/types'

//Actions
import xxaProfile from 'actions/Profile'

// Components
import Task from 'views/Todo/Task'
import Post from 'views/Todo/Post'
//Web3
import Web3 from 'web3'

import {
  Box,
  Grid,
  GridItem,
  useBreakpointValue,
  Flex,
  Center,
  Text,
  Divider,
  Progress,
  HStack,
  VStack,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  StatHelpText,
  StatArrow,
  CircularProgress,
  CircularProgressLabel,
  Stack,
  Avatar,
  Badge,
  Spinner,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  PinInputField,
  PinInput,
  ModalCloseButton,
  InputGroup,
  useDisclosure,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  AlertDialog,
  AlertIcon,
  Alert,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react'

import { BsCheckCircle, BsExclamationCircle, BsXCircle } from 'react-icons/bs'

import xxcAlertMessageTypes from 'components/AlertMessage/AlertMessageType'

const AlertMessage = ({
  isOpen,
  onClose,
  onConfirm,
  onCancel,
  confirmName,
  cancelName,
  type,
  title,
  message,
  cancelDisable,
  closeOnOverlayClick,
  footerPosition = 'right'
}: xxcAlertMessageTypes.Prop): JSX.Element => {
  const cancelRef: React.LegacyRef<HTMLButtonElement> | undefined = useRef(null)
  const handleClose = () => {
    onClose()
    if (onCancel) {
      onCancel()
    }
  }
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => onClose()}
        closeOnEsc={false}
        closeOnOverlayClick={closeOnOverlayClick ?? true}
        onOverlayClick={
          onCancel
            ? onCancel()
            : () => {
                return
              }
        }
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="md" fontWeight="bold">
              {/* Delete Customer */}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Center>
                {type === 'success' ? (
                  <BsCheckCircle fontSize="70px" color="#02D284" />
                ) : type === 'error' ? (
                  <BsXCircle fontSize="70px" color="#FF6F6E" />
                ) : type === 'warning' ? (
                  <BsExclamationCircle fontSize="70px" color="#FFC41C" />
                ) : (
                  <BsExclamationCircle fontSize="70px" color="#1FAAFE" />
                )}
              </Center>
              <Center mt="10px">
                <Text fontSize="xl">{title}</Text>
              </Center>
              <Center mt="5px">
                <Text textAlign="center" fontSize="md">
                  {message}
                </Text>
              </Center>
            </AlertDialogBody>

            <AlertDialogFooter
              justifyContent={footerPosition}
              gridColumnGap="3"
            >
              {cancelDisable ? null : (
                <Button ref={cancelRef} onClick={() => handleClose()}>
                  {cancelName ? cancelName : 'Cancel'}
                </Button>
              )}

              {onConfirm ? (
                <Button
                  bg="#53c8bf"
                  colorScheme="teal"
                  color="#fff"
                  onClick={() => onConfirm()}
                >
                  {confirmName ? confirmName : 'Confirm'}
                </Button>
              ) : null}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default AlertMessage
