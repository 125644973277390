import generator from 'generate-password'
import * as ls from 'local-storage'
import dayjs from 'dayjs'

const xxaDevice = {
  // GetDeviceId () : Promise<Return Type> {
  //    return new Promise(resolve => { })
  // }
  GetDeviceId: (): Promise<String> => {
    return new Promise(resolve => {
      const deviceId: string | null = window.localStorage.getItem('d')

      if (!deviceId) {
        const toDay: string = dayjs().format('YYYYMMDD')
        const deviceCode: string = generator.generate({
          length: 20,
          uppercase: true,
          numbers: true
        })

        const newDeviceId = toDay + '-' + deviceCode
        ls.set('d', newDeviceId)
        resolve(newDeviceId)
      } else {
        resolve(deviceId)
      }
    })
  }
}

export default xxaDevice