import { useEffect, useState, useMemo } from 'react'

import {
  Box,
  Center,
  IconButton,
  Text,
  Flex,
  Avatar,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons'
import { GiTiredEye } from 'react-icons/gi'

// Types
import xxreStoreTypes from 'redux/types'
import { useDispatch, useSelector } from 'react-redux'
import xxaConnectWallet from 'actions/ConnectWallet'
import xxaLogin from 'actions/Auth/Login'
import xxaProfile from 'actions/Profile'
import xxuWeb3 from 'utils/Web3'
import xxaWeb3 from 'actions/Web3'

interface Props {
  onShowSidebar: Function
  showSidebarButton?: boolean
  menu?: string
  setIsNavSmall: Function
  isNavSmall: boolean
  showConnect?: boolean
}

const Header = ({
  showSidebarButton = false,
  onShowSidebar,
  menu,
  setIsNavSmall,
  isNavSmall,
  showConnect = true
}: Props) => {
  const dispatch = useDispatch()
  const connectWallet = useSelector(
    (state: xxreStoreTypes.Props) => state.connectWallet
  )
  const [userAddress, setUserAddress] = useState('')
  const history = useHistory()
  const [userVerify, setUserVerify] = useState(true)

  const profile = useSelector((state: xxreStoreTypes.Props) => state.profile)
  const loginRedux = useSelector((state: xxreStoreTypes.Props) => state.login)
  const connectWeb3 = useSelector((state: xxreStoreTypes.Props) => state.web3)

  const disconnectWallet = async () => {
    // window.ethereum.on('close', (error: Error) => {});
    window.localStorage.removeItem('metamask')
    window.localStorage.removeItem('u')
    dispatch(xxaConnectWallet.ChangeChainID(false, '', false))
    dispatch(xxaProfile.ClearProfile())
  }

  const handleConnectWallet = async () => {
    console.log('handleConnectWallet1')
    if (connectWeb3.web3) {
      console.log('handleConnectWallet2')
      let connect = await dispatch(
        xxaConnectWallet.ConnectWallet(connectWeb3.web3)
      )
    }
  }

  const checkConnectedWallet = async () => {
    console.log('first connect')
    let web3 = connectWeb3.web3
    // let check = await xxaConnectWallet.CheckConnectWallet()
    let chainID = await web3.eth.getChainId()
    let connect = window.localStorage.getItem('metamask')
    // console.log('check',check);
    console.log('connect', connect)
    if (connect && chainID === xxuWeb3.GetWeb3().netID) {
      handleConnectWallet()
    }
  }

  useEffect(() => {
    if (profile) {
      console.log('profile', profile)
    }
  }, [profile])

  useEffect(() => {
    if (connectWallet.connect?.connectedStatus) {
      setUserAddress(connectWallet.connect?.address)
    } else {
      setUserAddress('')
    }
  }, [connectWallet])

  useEffect(() => {
    if (connectWallet.connect?.connectedStatus) {
      setUserAddress(connectWallet.connect?.address)
    } else {
      setUserAddress('')
    }
  }, [connectWallet])

  // const renderButton = (props: any, ref: any) => {
  //   return (
  //     <Button size="sm" style={{ marginLeft: '10px' }} {...props} ref={ref}>
  //       {userAddress.toString().substring(0, 6) +
  //         '...' +
  //         userAddress.toString().substring(38, 42)}
  //     </Button>
  //   )
  // }

  useEffect(() => {
    if (profile.profile?.tokensale_user_profile.verify_email) {
      setUserVerify(true)
    } else {
      setUserVerify(false)
    }
  }, [profile])

  useMemo(() => {
    // dispatch(xxaWeb3.ConnectWeb3())
    let login = xxaLogin.CheckTokenFromLocalStorage()
    if (login) {
      dispatch(xxaProfile.GetProfile())
    } else {
      xxaLogin.Logout()
    }
  }, [])

  useEffect(() => {
    console.log('connectWeb3', connectWeb3)
    if (connectWeb3.web3) {
      checkConnectedWallet()
    }
  }, [connectWeb3])

  useEffect(() => {
    if (loginRedux.login?.token) {
      dispatch(xxaProfile.GetProfile())
    }
  }, [loginRedux])

  //event change network and switch account
  useMemo(() => {
    let web3 = connectWeb3.web3
    if (typeof window.ethereum !== 'undefined') {
      window.ethereum.on('chainChanged', (chainId: string) => {
        let netID = parseInt(chainId, 16)
        console.log('chainId', netID)
        if (netID === xxuWeb3.GetWeb3().netID) {
          // dispatch(xxaConnectWallet.ChangeChainID(netID,userAddress,false))
          console.log('handleConnectWallet')
          handleConnectWallet()
        } else {
          console.log('notConnectWallet')
          dispatch(xxaConnectWallet.ChangeChainID(false, userAddress, false))
          dispatch(xxaLogin.Logout())
        }
        // window.location.reload()
      })
      window.ethereum.on('accountsChanged', async (accounts: Array<string>) => {
        console.log('accounts1', accounts[0])
        dispatch(xxaLogin.Logout())
        // let networkID = await web3.eth.getChainId()
        if (accounts[0]) {
          // dispatch(xxaConnectWallet.ChangeChainID(true, accounts[0], true))
          dispatch(xxaConnectWallet.ChangeChainID(true, accounts[0], false))
          console.log('have account')
          // setUserAddress(accounts[0])
        } else {
          console.log('disconnect')
          let netID = await web3.eth.getChainId()
          let checkChain: boolean =
            netID === xxuWeb3.GetWeb3().netID ? true : false
          dispatch(
            xxaConnectWallet.ChangeChainID(checkChain, accounts[0], false)
          )
        }
        // window.location.reload();
      })
    }
  }, [window.ethereum])

  return (
    <Flex
      bg="#F7FAFC"
      p={'10px'}
      px={showSidebarButton ? 5 : menu === 'top' ? 20 : 10}
      color="white"
      // position={showSidebarButton ? 'unset' : 'sticky'}
      // top={showSidebarButton ? 'unset' : 0}
      // zIndex="100"
      justifyContent="center"
    >
      <Box flex="1">
        {
          showSidebarButton ? (
            <IconButton
              size="md"
              colorScheme="blackAlpha"
              aria-label="Search database"
              icon={<ChevronRightIcon />}
              variant="outline"
              onClick={() => onShowSidebar()}
            />
          ) : menu === 'top' ? (
            <HStack>
              <Center>
                <GiTiredEye color="#50C7C2" fontSize={60} />
              </Center>{' '}
              <Text fontWeight="600" color="#6B7583" fontSize="3xl">
                OPTIC TOKEN
              </Text>
            </HStack>
          ) : null
          // <IconButton
          //   onClick={() => setIsNavSmall(!isNavSmall)}
          //   color="#6B7583"
          //   variant="outline"
          //   aria-label="Search database"
          //   icon={isNavSmall ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          // />
          //
        }
      </Box>
      {/* <Center flex="1" h="40px">
       
      </Center> */}
      <Box flex="1" display="flex" justifyContent="end">
        {connectWallet.connect?.connectedStatus ? (
          <HStack>
            <Text color="#6B7583" fontSize="md">
              Welcome!{' '}
              {connectWallet.connect.address.substring(0, 6) +
                '...' +
                connectWallet.connect.address.substring(38, 42)}
            </Text>
            <Menu>
              <MenuButton
                border="none"
                _active={{
                  backgroundColor: 'unset'
                }}
                _focus={{
                  border: 'none'
                }}
                _hover={{
                  backgroundColor: 'unset'
                }}
                as={IconButton}
                aria-label="Options"
                icon={
                  <Avatar
                    w="40px"
                    h="40px"
                    name={
                      connectWallet.connect.address.substring(0, 6) +
                      '...' +
                      connectWallet.connect.address.substring(38, 42)
                    }
                  />
                }
                variant="outline"
              />
              <MenuList zIndex='200' minWidth="150px">
                <MenuItem color={fontBaseColor}>
                  {userAddress.substring(0, 6) +
                    '...' +
                    userAddress.substring(38, 42)}
                </MenuItem>
                <MenuItem
                  color={fontBaseColor}
                  onClick={() => {
                    disconnectWallet()
                  }}
                >
                  LOGOUT
                </MenuItem>
              </MenuList>
            </Menu>
            {/* <Avatar w="40px" h="40px" name="Phanuwit Kittirong" /> */}
          </HStack>
        ) : showConnect === true ? (
          <Center>
            <Button
              size="sm"
              onClick={() => {
                handleConnectWallet()
              }}
              borderColor="#53C7BE"
              color="#53C7BE"
              variant="outline"
              leftIcon={
                <img
                  width="18px"
                  src="https://raw.githubusercontent.com/MetaMask/brand-resources/c3c894bb8c460a2e9f47c07f6ef32e234190a7aa/SVG/metamask-fox.svg"
                  alt=""
                />
              }
              // style={{ cursor: 'pointer', marginRight: '10px' }}
            >
              Connect Wallet
            </Button>
          </Center>
        ) : null}
      </Box>
    </Flex>
  )
}

const fontBaseColor = '#6B7583'

export default Header
