// Constants
import {
  REQUEST_PAYMENT_CLEAR_MESSAGE,
  REQUEST_PAYMENT_SUCCESS,
  REQUEST_PAYMENT__FAIL
} from 'redux/Constants/CCPPayment/RequestPayment'

// Props Types
import IAction from 'redux/Action/types'
import xxreRequestPaymentReducerTypes from 'redux/Reducers/CCPPayment/RequestPayment/types'

const requestPayment = (
  state: object = {},
  action: IAction<xxreRequestPaymentReducerTypes.Props>
) => {
  switch (action.type) {
    case REQUEST_PAYMENT_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case REQUEST_PAYMENT__FAIL:
      return {
        ...state,
        ...action.payload
      }
    case REQUEST_PAYMENT_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default requestPayment
