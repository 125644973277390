// Constants
import {
  WEB3_CONNECT_SUCCESS,
  WEB3_CONNECT_FAIL,
  WEB3_CONNECT_CLEAR_MESSAGE
} from 'redux/Constants/Web3'

// Props Types
import IAction from 'redux/Action/types'
import xxreWeb3ReducerTypes from 'redux/Reducers/Web3/types'

const web3 = (
  state: object = {},
  action: IAction<xxreWeb3ReducerTypes.Props>
) => {
  switch (action.type) {
    case WEB3_CONNECT_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case WEB3_CONNECT_FAIL:
      return {
        ...state,
        ...action.payload
      }
    case WEB3_CONNECT_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default web3
