import { useBreakpointValue } from '@chakra-ui/react'

export const useStyled = () => {
  const box1 = useBreakpointValue({ base: 6, md: 4 })
  const box2 = useBreakpointValue({ base: 6, md: 8 })
  const box3 = useBreakpointValue({ base: 6, md: 8 })
  const box4 = useBreakpointValue({ base: 6, md: 4 })
  const box5 = useBreakpointValue({ base: 6, md: 4 })
  const templateColumns = useBreakpointValue({
    base: 'repeat(6, 1fr)',
    md: 'repeat(12, 1fr)'
  })

  const boxItemShadow = 'rgb(0 0 0 / 4%) 0px 7px 11px'
  const boxBorderRadius = '16px'
  const cardPadding = '16px'

  return {
    box1,
    box2,
    box3,
    box4,
    box5,
    templateColumns,
    boxItemShadow,
    boxBorderRadius,
    cardPadding
  }
}
