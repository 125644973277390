// Constants
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_CLEAR_MESSAGES
} from './../../../Constants/Auth/Login'

// Props Types
import IAction from 'redux/Action/types'
import xxreLoginReducerTypes from 'redux/Reducers/Auth/Login/types'

const login = (
  state: object = {},
  action: IAction<xxreLoginReducerTypes.Props>
) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case LOGIN_FAIL:
      return {
        ...state,
        ...action.payload
      }
    case LOGIN_CLEAR_MESSAGES:
      return {}
    default:
      return state
  }
}

export default login
