// Interfaces
import xxroRouterProps from 'routers/types'

// Views
import Home from 'views/Home'
import TokenSale from 'views/TokenSale'
import Account from 'views/Account'
import Whitelist from 'views/Whitelist'
import VerifyEmail from 'views/VerifyEmail'
import Countdown from 'views/Countdown'
import ChangeContractAddress from 'views/ChangeContractAddress'




const xxroHomeRouter: xxroRouterProps.PropTypes[] = [
  { path: '/', exact: true, isAuth: false, layout: 'main', component: ChangeContractAddress },
  {
    path: '/tokensale/:ref?',
    exact: true,
    isAuth: true,
    layout: 'top',
    component: TokenSale
  },
  {
    path: '/account',
    exact: true,
    isAuth: false,
    layout: 'main',
    component: Account
  },
  {
    path: '/verify-email',
    exact: true,
    isAuth: false,
    layout: 'main',
    component: VerifyEmail
  },
  {
    path: '/whitelist',
    exact: true,
    isAuth: false,
    layout: 'top',
    component: Whitelist
  },
  {
    path: '/countdown',
    exact: true,
    isAuth: true,
    layout: 'top',
    component: Countdown
  },
  {
    path: '/changecontractaddress',
    exact: true,
    isAuth: false,
    layout: 'main',
    component: ChangeContractAddress
  }
]

export default xxroHomeRouter
