import React, { useEffect, useMemo } from 'react'
import {
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tfoot,
  Box,
  IconButton,
  Center,
  Stack,
  Tooltip,
  Spinner,
  Text
} from '@chakra-ui/react'
import { BiDetail } from 'react-icons/bi'
import { BsCheckCircle, BsExclamationCircle, BsXCircle } from 'react-icons/bs'
import xxaHistory from 'actions/History'
import { useDispatch, useSelector } from 'react-redux'
// Types
import xxreStoreTypes from 'redux/types'

import dayjs from 'dayjs'

function Transaction() {
  const dispatch = useDispatch()
  const historyTransaction = useSelector(
    (state: xxreStoreTypes.Props) => state.history
  )

  useMemo(() => {
    dispatch(xxaHistory.GetHistory())
  }, [])

  useEffect(() => {
    if (historyTransaction.history) {
      console.log('historyTransaction', historyTransaction.history)
    }
  }, [historyTransaction])

  return (
    <>
      <Box bg="#fff" minH="500px" p={4} borderRadius="16px">
        {historyTransaction.history ? (
          <Table variant="simple" colorScheme="blackAlpha">
            <TableCaption>Imperial to metric conversion factors</TableCaption>
            <Thead>
              <Tr>
                <Th>TRANX NO</Th>
                <Th>AMOUNT</Th>
                <Th>TYPE</Th>
                <Th>PAY AMOUNT</Th>
                {/* <Th>FROM</Th> */}
                <Th>STATUS</Th>
                <Th></Th>
              </Tr>
            </Thead>

            <Tbody>
              {historyTransaction.history.tokensale_payment_transaction.map(
                (item, index) => {
                  return (
                    <Tr key={`tnx-data-${index}`}>
                      <Td>
                        <Stack>
                          <span>
                            {item.transaction_hash ? (
                              <Tooltip
                                label={item.transaction_hash}
                                placement="top"
                              >
                                {item.transaction_hash.substring(0, 6) +
                                  '...' +
                                  item.transaction_hash.substring(60, 66)}
                              </Tooltip>
                            ) : (
                              ''
                            )}
                          </span>
                          <Text fontSize="xs" fontWeight="500" color="#4A5667">
                            {dayjs(item.date).format('YYYY-MM-DD HH:mm')}
                          </Text>
                        </Stack>
                      </Td>
                      <Td>
                        {item.token_amount.toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </Td>
                      <Td>
                        {item.type === 'ccp'
                          ? 'CCP Points'
                          : item.type === 'credit_card'
                          ? 'Credit Card'
                          : item.type === 'qr_code'
                          ? 'QR Code'
                          : 'Crypto'}
                      </Td>
                      <Td>
                        {item.payment_amount.toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}{' '}
                        {item.unit.toUpperCase()}
                      </Td>
                      {/* <Td>1F1T....4XQX</Td> */}
                      <Td>
                        {item.status === 'cancel' ? (
                          <BsXCircle color="#FF6F6E" fontSize="30px" />
                        ) : item.status === 'success' ? (
                          <BsCheckCircle color="#02D284" fontSize="30px" />
                        ) : (
                          <BsExclamationCircle
                            color="#FFC41C"
                            fontSize="30px"
                          />
                        )}
                      </Td>
                      <Td>
                        <IconButton
                          disabled={item.transaction_hash ? false : true}
                          variant="outline"
                          // colorScheme="teal"
                          borderColor="#4A5667"
                          color="#4A5667"
                          aria-label="Call Sage"
                          fontSize="18px"
                          size="sm"
                          icon={<BiDetail />}
                          onClick={() =>
                            window.open(
                              `https://testnet.bscscan.com/tx/${item.transaction_hash}`,
                              '_blank'
                            )
                          }
                        />
                      </Td>
                    </Tr>
                  )
                }
              )}
            </Tbody>
            {/* <Tfoot>
            <Tr>
              <Th>To convert</Th>
              <Th>into</Th>
              <Th isNumeric>multiply by</Th>
            </Tr>
          </Tfoot> */}
          </Table>
        ) : (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        )}
      </Box>
    </>
  )
}

export default Transaction
