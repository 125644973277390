import { Dispatch } from 'redux'

// Redux Constants
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_CLEAR_MESSAGES
} from 'redux/Constants/Auth/Login'

import {
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  GET_PROFILE_CLEAR_MESSAGE
} from 'redux/Constants/Profile/Get'

// Utils
import xxuResponse from 'utils/Response'

//Web3
import Web3 from 'web3'

//service
import xxsLoginService from 'services/Auth/Login'

const xxaLogin = {
  Login:
    (timeStamp: string, signature: string, userAddress: string) =>
    async (dispatch: Dispatch) => {
      // Clear data of example in Redux
      dispatch({
        type: LOGIN_CLEAR_MESSAGES
      })
      const response = await xxsLoginService.Login(
        userAddress,
        timeStamp,
        signature
      )
      if (response.status === 200) {
        window.localStorage.setItem('u', JSON.stringify(response.login))
        xxuResponse.Success({
          type: LOGIN_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: LOGIN_FAIL,
          errorPage: true,
          dispatch,
          error: response.error
        })
      }
    },
  GetAccessTokenFromLocalStorage: (): Promise<{
    status: number
    token?: any
  }> => {
    return new Promise(async resolve => {
      const rowToken = window.localStorage.getItem('u')
      const accessToken = rowToken ? JSON.parse(rowToken) : null
      if (accessToken) {
        resolve({
          status: 200,
          token: accessToken
        })
      } else {
        resolve({
          status: 403
        })
      }
    })
  },
  CheckTokenFromLocalStorage: () => {
    const rawToken: any = window.localStorage.getItem('u')
    const user = rawToken ? JSON.parse(rawToken) : null
    if (user?.token) {
      return true
    } else {
      return false
    }
  },
  Logout: () => async (dispatch: Dispatch) => {
    window.localStorage.removeItem('u')
    window.localStorage.removeItem('metamask')
    dispatch({
      type: LOGIN_CLEAR_MESSAGES
    })
    dispatch({
      type: GET_PROFILE_CLEAR_MESSAGE
    })
  }
}

export default xxaLogin
