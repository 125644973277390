import { Dispatch } from 'redux'

// Redux Constants
import {
  GET_ANONYMOUS_SUCCESS,
  GET_ANONYMOUS_FAIL,
  GET_ANONYMOUS_CLEAR_MESSAGES
} from 'redux/Constants/Auth/AnonymousToken'

// Utils
import xxuResponse from 'utils/Response'

//Web3
import Web3 from 'web3'

//service
import xxsAuthAnonymousTokenService from 'services/Auth/AnonymousToken'

const xxaAnonymousToken = {
  GetAnonymousToken: () => async (dispatch: Dispatch) => {
    // Clear data of example in Redux
    dispatch({
      type: GET_ANONYMOUS_CLEAR_MESSAGES
    })
    const response = await xxsAuthAnonymousTokenService.GetAnonymousToken()
    if (response.status === 200) {
      window.localStorage.setItem('a', JSON.stringify(response.anonymous))
      xxuResponse.Success({
        type: GET_ANONYMOUS_SUCCESS,
        dispatch,
        payload: response
      })
    } else {
      xxuResponse.Error({
        type: GET_ANONYMOUS_FAIL,
        errorPage: true,
        dispatch,
        error: response.error
      })
    }
  },
  GetAnonymousTokenFromLocalStorage: () => {
    return new Promise<{
      status: number
      anonymous?: any
      error?: any
    }>(async resolve => {
      const anonymousData = window.localStorage.getItem('a')

      const anonymous = anonymousData ? JSON.parse(anonymousData) : ''

      if (anonymous) {
        resolve({
          status: 200,
          anonymous: anonymous
        })
      } else {
        resolve({
          status: 403,
          error: {
            msg: 'cannot get data from local storage'
          }
        })
      }
    })
  }
}

export default xxaAnonymousToken
