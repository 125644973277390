import { Dispatch } from 'redux'

// Redux Constants
import {
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAIL,
  GET_HISTORY_CLEAR_MESSAGE
} from 'redux/Constants/History'

// Utils
import xxuResponse from 'utils/Response'

//service
import xxsHistoryService from 'services/History'

const xxaHistory = {
  ClearGetHistory: () => async (dispatch: Dispatch) => {
    dispatch({
      type: GET_HISTORY_CLEAR_MESSAGE
    })
  },
  GetHistory: () => async (dispatch: Dispatch) => {
    const response = await xxsHistoryService.GetHistory()
    if (response.status === 200) {
      xxuResponse.Success({
        type: GET_HISTORY_SUCCESS,
        dispatch,
        payload: response
      })
    } else {
      xxuResponse.Error({
        type: GET_HISTORY_FAIL,
        errorPage: true,
        dispatch,
        error: response.error
      })
    }
  }
}

export default xxaHistory
