// Constants
import {
  VERIFY_PAYMENT_CLEAR_MESSAGE,
  VERIFY_PAYMENT_FAIL,
  VERIFY_PAYMENT_SUCCESS
} from 'redux/Constants/CCPPayment/VerifyPayment'

// Props Types
import IAction from 'redux/Action/types'
import xxreVerifyPaymentReducerTypes from 'redux/Reducers/CCPPayment/VerifyPayment/types'

const verifyPayment = (
  state: object = {},
  action: IAction<xxreVerifyPaymentReducerTypes.Props>
) => {
  switch (action.type) {
    case VERIFY_PAYMENT_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case VERIFY_PAYMENT_FAIL:
      return {
        ...state,
        ...action.payload
      }
    case VERIFY_PAYMENT_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default verifyPayment
