import { useEffect, useState, useMemo, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
// Types
import xxreStoreTypes from 'redux/types'

//Actions
import xxaConnectWallet from 'actions/ConnectWallet'
import xxaLogin from 'actions/Auth/Login'
import xxaToken from 'actions/Utils/Token'

// Components
import Profile from 'components/Account/Profile'
import AlertMessage from 'components/AlertMessage/AlertMessage'

import {
  Image,
  HStack,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  ModalContent,
  useDisclosure,
  Button,
  InputGroup,
  InputLeftElement,
  Box,
  Text,
  PinInput,
  PinInputField,
  RadioGroup,
  Radio,
  VStack,
  Stack,
  NumberInput,
  NumberInputField,
  Heading,
  Center,
  Alert,
  AlertIcon,
  Grid,
  Flex,
  Spacer
} from '@chakra-ui/react'

import { LineProgressBar } from '@frogress/line'

import xxaEmail from 'actions/Email'
import xxuValidation from 'utils/Validation'

//Web3
import Web3 from 'web3'
import { Contract } from 'web3-eth-contract'

import xxaProfile from 'actions/Profile'
import xxaContract from 'actions/Contract'
import dayjs from 'dayjs'

const Countdown = (): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const contractSale: Contract | null = xxaContract.ContractToken('sale') // contract sale
  let intervalHandle: NodeJS.Timeout

  const connectWallet = useSelector(
    (state: xxreStoreTypes.Props) => state.connectWallet
  )
  const connectWeb3 = useSelector((state: xxreStoreTypes.Props) => state.web3)

  const [icoTime, setIcoTime] = useState({ start: 0, end: 0 }) //time start and end ico
  const [tokenTarget, setTokenTarget] = useState(0) // amount token will sale
  const [tokenRaised, setTokenRaised] = useState(0) // amount token sold
  const [email, setEmail] = useState('')
  const [countryOfResidence, setCountryOfResidence] = useState('')
  const [emailValidate, setEmailValidate] = useState<ReactElement | null>(null)
  const [dataCountry, setDataCountry] = useState([])
  const [clickSign, setClickSign] = useState(false)
  const [isShowAlertMessage, setIsShowAlertMessage] = useState(false)
  const [day, setDay] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  // const [newUser, setNewUser] = useState(true)

  // const todo = useSelector((state: xxreStoreTypes.Props) => state.todo)

  const convertToInternationalCurrencySystem = (labelValue: number) => {
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + 'B'
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'M'
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + 'K'
      : Math.abs(Number(labelValue))
  }

  const getFundingGoal = async () => {
    let web3 = connectWeb3.web3
    if (contractSale && web3) {
      let target = await contractSale.methods.fundingGoal().call()
      let raise = await contractSale.methods.tokenRaised().call()
      setTokenRaised(parseFloat(await web3.utils.fromWei(raise)))
      setTokenTarget(parseFloat(await web3.utils.fromWei(target)))
    }
  }
  const getIcoTime = async () => {
    if (contractSale) {
      let time = {
        start: await contractSale.methods.icoStartTime().call(),
        end: await contractSale.methods.icoEndTime().call()
      }
      let timeStamp = Math.ceil(new Date().getTime() / 1000)
      if (time.start - timeStamp < 0) {
        history.push('/dashboard/buytokens')
      }
      setIcoTime(time)
    }
  }

  useEffect(() => {
    if (connectWallet.connect?.network) {
      getFundingGoal()
      getIcoTime()
    }
  }, [connectWallet])

  const countdownStart = () => {
    const secondLocal = 1
    const minuteLocal = secondLocal * 60
    const hourLocal = minuteLocal * 60
    const dayLocal = hourLocal * 24

    let date = Math.ceil(new Date().getTime() / 1000)
    let start = Math.ceil(new Date('11/26/2021').getTime() / 1000)

    // let count = icoTime.start - date
    let count = start - date

    console.log('count', dayjs(count).format('ss'))

    setDay(Math.floor(count / dayLocal))
    setHours(Math.floor((count % dayLocal) / hourLocal))
    setMinutes(Math.floor((count % hourLocal) / minuteLocal))
    setSeconds(Math.floor((count % minuteLocal) / secondLocal))

    if (count < 0) {
      stopIntervalTask()
    }
  }

  const startIntervalTask = () => {
    return (intervalHandle = setInterval(() => {
      countdownStart()
    }, 1000))
  }
  const stopIntervalTask = () => {
    clearInterval(intervalHandle)
  }

  useMemo(() => {
    startIntervalTask()
  }, [])

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100vh - 180px)',
        alignItems: 'center'
      }}
    >
      {/* {profile.profile ? ( */}
      <Stack __css={layerStyles}>
        <Center>
          <Heading size="lg" color="#6B7683">
            TOKEN SALE START IN
          </Heading>
        </Center>
        <Box mt="40px">
          <Grid templateColumns="repeat(4, 1fr)" gap={0}>
            <Stack>
              <Center>
                <Box
                  borderRadius="16px"
                  w="61px"
                  h="61px"
                  bg="#fff"
                  boxShadow="rgb(0 0 0 / 4%) 0px 7px 11px"
                >
                  <Center h="100%">
                    <Text color="#6B7683" fontSize="3xl">
                      {day}
                    </Text>
                  </Center>
                </Box>
              </Center>

              <Center>
                <Text color="#6B7683" fontSize="md">
                  Day
                </Text>
              </Center>
            </Stack>
            <Stack>
              <Center>
                <Box
                  borderRadius="16px"
                  w="61px"
                  h="61px"
                  bg="#fff"
                  boxShadow="rgb(0 0 0 / 4%) 0px 7px 11px"
                >
                  <Center h="100%">
                    <Text color="#6B7683" fontSize="3xl">
                      {hours}
                    </Text>
                  </Center>
                </Box>
              </Center>

              <Center>
                <Text color="#6B7683" fontSize="md">
                  Hours
                </Text>
              </Center>
            </Stack>{' '}
            <Stack>
              <Center>
                <Box
                  borderRadius="16px"
                  w="61px"
                  h="61px"
                  bg="#fff"
                  boxShadow="rgb(0 0 0 / 4%) 0px 7px 11px"
                >
                  <Center h="100%">
                    <Text color="#6B7683" fontSize="3xl">
                      {minutes}
                    </Text>
                  </Center>
                </Box>
              </Center>

              <Center>
                <Text color="#6B7683" fontSize="md">
                  Minutes
                </Text>
              </Center>
            </Stack>
            <Stack>
              <Center>
                <Box
                  borderRadius="16px"
                  w="61px"
                  h="61px"
                  bg="#fff"
                  boxShadow="rgb(0 0 0 / 4%) 0px 7px 11px"
                >
                  <Center h="100%">
                    <Text color="#6B7683" fontSize="3xl">
                      {seconds}
                    </Text>
                  </Center>
                </Box>
              </Center>

              <Center>
                <Text color="#6B7683" fontSize="md">
                  Seconds
                </Text>
              </Center>
            </Stack>
          </Grid>
        </Box>
        <Box px="10px" mt="40px">
          <LineProgressBar
            label={(value: number) => (
              <Box
                h="100%"
                display="flex"
                alignItems="center"
                position="absolute"
                ml="10px"
              >
                <Text fontWeight="600" color="#fff" fontSize="lg">
                  {value}%
                </Text>
              </Box>
            )}
            progressColor="#53c8bf"
            percent={
              tokenTarget > 0
                ? parseFloat(
                    ((tokenRaised / tokenTarget) * 100).toLocaleString(
                      'en-US',
                      {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      }
                    )
                  ) > 100
                  ? 100
                  : parseFloat(
                      ((tokenRaised / tokenTarget) * 100).toLocaleString(
                        'en-US',
                        {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        }
                      )
                    )
                : 0
            }
            rounded={36}
            height={32}
            containerStyle={{ border: `2px solid ${primaryColor}` }}
          />
        </Box>
        <Box px="10px" mt="5px">
          <Flex>
            <Box color="#6B7683">RAISED - {tokenRaised}</Box>
            <Spacer />
            <Box color="#6B7683">
              TARGET - {convertToInternationalCurrencySystem(tokenTarget)}
            </Box>
          </Flex>
        </Box>
        <Center mt="40px">
          <Text fontSize="lg" color="#6B7683" fontWeight="600">
            COMING SOON
          </Text>
        </Center>
      </Stack>

      <AlertMessage
        type="success"
        title="Success"
        message="You're almost there! We sent an email to big.phanuwit@gmail.com, please check your email."
        isOpen={isShowAlertMessage}
        onClose={() => setIsShowAlertMessage(false)}
        // onConfirm={()=>{console.log('object')}}
        footerPosition="center"
      />
    </Box>
  )
}

const layerStyles = {
  height: 'auto',
  width: '380px',
  bg: 'gray.50',
  borderRadius: '16px',
  padding: '16px',
  display: 'flex',
  // justifyContent:'center',
  // alignItems:'center',
  boxShadow: 'rgb(0 0 0 / 4%) 0px 7px 11px'
}

const primaryColor = '#53c8bf'
export default Countdown
