import { Dispatch } from 'redux'

// Redux Constants
import {
  PAYMENT_SUCCESS,
  PAYMENT_FAIL,
  PAYMENT_CLEAR_MESSAGE
} from 'redux/Constants/Payment'

// Utils
import xxuResponse from 'utils/Response'

//service
import xxsPaymentService from 'services/Payment'

const xxaPayment = {
  Payment:
    (tokenAmount: number, paymentType: string) =>
    async (dispatch: Dispatch) => {
      const response = await xxsPaymentService.Payment(tokenAmount, paymentType)
      if (response.status === 200) {
        xxuResponse.Success({
          type: PAYMENT_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: PAYMENT_FAIL,
          errorPage: true,
          dispatch,
          error: response.error
        })
      }
    },
  ClearPayment: () => async (dispatch: Dispatch) => {
    dispatch({
      type: PAYMENT_CLEAR_MESSAGE
    })
  }
}

export default xxaPayment
