import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// Redux Store
import configStore from 'redux/Store'

// Routers
import xxroMainRouter from 'routers'

// Layout & Views
import Layout from 'layouts'
import NotFoundPage from 'views/NotFound'

// PWA
import * as serviceWorkerRegistration from 'serviceWorkerRegistration'
import reportWebVitals from 'reportWebVitals'

// i18n & Styles
import 'i18n'
import 'styles/Main.scss'
// import 'rsuite/styles/';
import 'rsuite/dist/rsuite.min.css'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
//component
import LoadingPage from 'components/LoadingPage';
// Initial Store
const store = configStore()

const theme = extendTheme({
  colors: {
    Primary: {
      50: '#E6FFFA',
      100: '#B2F5EA',
      200: '#81E6D9',
      300: '#4FD1C5',
      400: '#38B2AC',
      500: '#319795',
      600: '#2C7A7B',
      700: '#285E61',
      800: '#234E52',
      900: '#1D4044'
    }
  }
})

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <Provider store={store}>
      <Suspense fallback={<LoadingPage />}>
        <Router>
          <Switch>
            {xxroMainRouter.map(route => {
              return (
                <Route
                  key={`router-${route.path.replaceAll('/', '-')}`}
                  path={route.path}
                  exact={route.exact}
                  render={() => {
                    return (
                      <Layout isAuth={route.isAuth} layout={route.layout}>
                        <route.component />
                      </Layout>
                    )
                  }}
                />
              )
            })}
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Router>
      </Suspense>
    </Provider>
  </ChakraProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
