import { useTranslation } from 'react-i18next'
import { useRef, useState, useMemo, useEffect } from 'react'

//component
import Navbars from 'components/Navbars'
import {
  Box,
  ChakraProvider,
  Grid,
  GridItem,
  useBreakpointValue,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@chakra-ui/react'

// Utils
import xxuLanguage from 'utils/Language'

//component
import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
//component page

import xxlDashboardPageProps from 'layouts/DashboardLayout/types'
import xxaWeb3 from 'actions/Web3'

const smVariant = { navigation: 'drawer', navigationButton: true }
const mdVariant = { navigation: 'sidebar', navigationButton: false }

const DashboardPage = ({
  children
}: xxlDashboardPageProps.PropTypes): JSX.Element => {
  const { t } = useTranslation(['common'])

  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const [isNavSmall, setIsNavSmall] = useState(true)

  const variants = useBreakpointValue({ base: smVariant, md: mdVariant })

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen)
  const [menu, setMenu] = useState('top')

  useEffect(() => {
    window.localStorage.setItem('nav', JSON.stringify(isNavSmall))
  }, [isNavSmall])

  useMemo(() => {
    let nav = window.localStorage.getItem('nav')
    if (nav) {
      setIsNavSmall(JSON.parse(nav))
    } else {
      setIsNavSmall(true)
    }
  }, [])

  return (
    <>
      {menu === 'side' ? (
        <>
          <Sidebar
            variant={variants?.navigation}
            isOpen={isSidebarOpen}
            onClose={toggleSidebar}
            isNavSmall={isNavSmall}
            setIsNavSmall={setIsNavSmall}
          />
          <Box ml={!variants?.navigationButton ? (isNavSmall ? 250 : '80px') : 0}>
            <Header
              showSidebarButton={variants?.navigationButton}
              onShowSidebar={toggleSidebar}
              menu={menu}
              setIsNavSmall={setIsNavSmall}
              isNavSmall={isNavSmall}
            />
            <Box
              bg="#F7FAFC"
              h="auto"
              minHeight="calc(100vh - 72px)"
              p={10}
              pt="5"
            >
              {children}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Header
            showSidebarButton={variants?.navigationButton}
            onShowSidebar={toggleSidebar}
            menu={menu}
            setIsNavSmall={setIsNavSmall}
            isNavSmall={isNavSmall}
          />
          <Navbars
            variant={variants?.navigation}
            isOpen={isSidebarOpen}
            onClose={toggleSidebar}
          />
          <Box
            bg="#F7FAFC"
            h="auto"
            minHeight="calc(100vh - 72px)"
            p={!variants?.navigationButton ? 20 : 5}
            pt="5"
          >
            {children}
          </Box>
        </>
      )}
    </>
  )
}

export default DashboardPage
