export const EXAMPLE = process.env.REACT_APP_EXAMPLE
export const SERVICE_GRAPHQL = process.env.REACT_APP_SERVICE_GRAPHQL

export const servicePath = {
  base_url:
    process.env.REACT_APP_RUN_ON === 'production'
      ? 'http://localhost:3000'
      : 'http://localhost:3000',
  service: {
    general:
      process.env.REACT_APP_RUN_ON === 'production'
        ? 'https://tokensale.cunicorn.co'
        : 'https://dev-tokensale.cunicorn.co'
  }
}
