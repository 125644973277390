import axios from 'axios'
import xxsAuthAnonymousTokenServiceTypes from 'services/Auth/AnonymousToken/types'
import xxaDevice from 'actions/Utils/Device'
import { servicePath } from 'configs'

const xxsAuthAnonymousTokenService = {
  GetAnonymousToken:
    async (): Promise<xxsAuthAnonymousTokenServiceTypes.GetAnonymousResponse> => {
      let response: xxsAuthAnonymousTokenServiceTypes.GetAnonymousResponse

      try {
        const responseData =
          await axios.get<xxsAuthAnonymousTokenServiceTypes.GetAnonymousResponseSuccess>(
            `${servicePath.service.general}/v1/auth/anonymous-token?domain=optic.finance`,
            {
              headers: {
                'device-Id': await xxaDevice.GetDeviceId()
              }
            }
          )

        response = {
          status: responseData.status,
          anonymous: responseData.data
        }
      } catch (error: any) {
        const err = error.toJSON()
        if (err.message === 'Network Error') {
          response = {
            status: 500,
            error: err
          }
        } else {
          response = {
            status: error.response.status,
            error: error.response.data
          }
        }
      }
      return response
    }
}

export default xxsAuthAnonymousTokenService
