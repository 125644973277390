import { Dispatch } from 'redux'

// Redux Constants
import {
  REQUEST_PAYMENT_CLEAR_MESSAGE,
  REQUEST_PAYMENT_SUCCESS,
  REQUEST_PAYMENT__FAIL
} from 'redux/Constants/CCPPayment/RequestPayment'
import {
  VERIFY_BALANCE_CLEAR_MESSAGE,
  VERIFY_BALANCE_FAIL,
  VERIFY_BALANCE_SUCCESS
} from 'redux/Constants/CCPPayment/VerifyBalance'
import {
  VERIFY_PAYMENT_CLEAR_MESSAGE,
  VERIFY_PAYMENT_FAIL,
  VERIFY_PAYMENT_SUCCESS
} from 'redux/Constants/CCPPayment/VerifyPayment'
import {
  CONFIRM_PAYMENT_CLEAR_MESSAGE,
  CONFIRM_PAYMENT__FAIL,
  CONFIRM_PAYMENT_SUCCESS
} from 'redux/Constants/CCPPayment/ConfirmPayment'

// Utils
import xxuResponse from 'utils/Response'

//service
import xxsCCPPaymentService from 'services/CCPPayment'

const xxaCCPPayment = {
  RequestPayment:
    (mobileNumber: string, paymentAmount: number, ccpPaymentAmount: number) =>
    async (dispatch: Dispatch) => {
      dispatch({
        type: REQUEST_PAYMENT_CLEAR_MESSAGE
      })
      const response = await xxsCCPPaymentService.RequestPayment(
        mobileNumber,
        paymentAmount,
        ccpPaymentAmount
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: REQUEST_PAYMENT_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: REQUEST_PAYMENT__FAIL,
          errorPage: true,
          dispatch,
          error: response.error
        })
      }
    },
  ClearRequestPayment: () => async (dispatch: Dispatch) => {
    dispatch({
      type: REQUEST_PAYMENT_CLEAR_MESSAGE
    })
  },
  VerifyBalance:
    (
      tokensalePaymentTid: string,
      mobileNumber: string,
      otp: number,
      otpRefCode: string
    ) =>
    async (dispatch: Dispatch) => {
      dispatch({
        type: VERIFY_BALANCE_CLEAR_MESSAGE
      })
      const response = await xxsCCPPaymentService.VerifyBalance(
        tokensalePaymentTid,
        mobileNumber,
        otp,
        otpRefCode
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: VERIFY_BALANCE_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: VERIFY_BALANCE_FAIL,
          errorPage: true,
          dispatch,
          error: response.error
        })
      }
    },
  ClearVerifyBalance: () => async (dispatch: Dispatch) => {
    dispatch({
      type: VERIFY_BALANCE_CLEAR_MESSAGE
    })
  },
  VerifyPayment:
    (tokensalePaymentTid: string, ccp: number) =>
    async (dispatch: Dispatch) => {
      dispatch({
        type: VERIFY_PAYMENT_CLEAR_MESSAGE
      })
      const response = await xxsCCPPaymentService.VerifyPayment(
        tokensalePaymentTid,
        ccp
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: VERIFY_PAYMENT_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: VERIFY_PAYMENT_FAIL,
          errorPage: true,
          dispatch,
          error: response.error
        })
      }
    },
  ClearVerifyPayment: () => async (dispatch: Dispatch) => {
    dispatch({
      type: VERIFY_PAYMENT_CLEAR_MESSAGE
    })
  },
  ConfirmPayment:
    (tokensalePaymentTid: string) => async (dispatch: Dispatch) => {
      dispatch({
        type: CONFIRM_PAYMENT_CLEAR_MESSAGE
      })
      const response = await xxsCCPPaymentService.ConfirmPayment(
        tokensalePaymentTid
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: CONFIRM_PAYMENT_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: CONFIRM_PAYMENT__FAIL,
          errorPage: true,
          dispatch,
          error: response.error
        })
      }
    },
  ClearConfirmPayment: () => async (dispatch: Dispatch) => {
    dispatch({
      type: CONFIRM_PAYMENT_CLEAR_MESSAGE
    })
  }
}

export default xxaCCPPayment
