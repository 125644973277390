import React, { useState, useMemo, useEffect } from 'react'
import Chart from 'react-apexcharts'
import {
  Box,
  Grid,
  GridItem,
  useBreakpointValue,
  Flex,
  Center,
  Text,
  Divider,
  Progress,
  HStack,
  VStack,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  StatHelpText,
  StatArrow,
  CircularProgress,
  CircularProgressLabel,
  Stack,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tooltip,
  IconButton,
  Spinner,
  Slider,
  SliderThumb,
  SliderTrack,
  SliderFilledTrack,
  Link,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Select,
  NumberInput,
  NumberInputField,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  MenuOptionGroup,
  MenuItemOption
} from '@chakra-ui/react'

import * as _ from 'lodash'

// Style
import { useStyled } from './dashboard.style'
// Types
import xxreStoreTypes from 'redux/types'
import { useDispatch, useSelector } from 'react-redux'
import xxcDashboardTypes from 'components/Pages/Dashboard/dashboard'

import dayjs from 'dayjs'
//icon
import { BiDetail, BiChevronDown } from 'react-icons/bi'

import { BsCheckCircle, BsExclamationCircle, BsXCircle } from 'react-icons/bs'

import { FaTelegram, FaFacebookSquare, FaTwitterSquare } from 'react-icons/fa'

import xxaHistory from 'actions/History'
import xxaRateConfig from 'actions/RateConfig'
import xxaContract from 'actions/Contract'
//Web3
import { Contract } from 'web3-eth-contract'
import xxuContract from 'utils/Contract'

const options = {
  chart: {
    id: 'apexchart-example'
  },
  xaxis: {
    categories: ['1 Apr', '2 Apr', '3 Apr', '4 Apr', '5 Apr', '6 Apr', '7 Apr']
  },
  // line color
  colors: ['#53C8BF'],
  //area color
  fill: {
    colors: ['#81eee6']
  },
  //make color
  dataLabels: {
    style: {
      colors: ['#53C8BF']
    }
  }
}

const series = [
  {
    name: 'Sale Moon',
    data: [30, 200, 10, 30, 130, 100, 200, 250]
  }
]

const Dashboard = () => {
  const dispatch = useDispatch()
  const contractMoonToken: Contract | null = xxaContract.ContractToken('MOON') // contract Moon
  const contractSale: Contract | null = xxaContract.ContractToken('sale') // contract Sale

  const [showChart, setShowChart] = useState(false)
  const [userContributions, setUserContributions] = useState<any>()
  const [useToken, setUseToken] = useState('BNB')
  const [amountTokenPay, setAmountTokenPay] = useState(0)
  const [moonWillGet, setMoonWillGet] = useState(0)
  const [tokenTarget, setTokenTarget] = useState(0) // amount token will sale
  const [tokenRaised, setTokenRaised] = useState(0) // amount token sold
  //rate buy token to moonToken
  const [tokenRateTokenBuy, setTokenRateTokenBuy] = useState(0)
  //rate buy BNB to moonToken
  const [tokenRteEth, setTokenRteEth] = useState(0)
  //rate buy THB to moonToken
  const [tokenRteTHB, setTokenRteTHB] = useState(1)
  const [moonBalance, setMoonBalance] = useState(0) // moon balance user
  const [configTokenRate, setConfigTokenRate] =
    useState<xxcDashboardTypes.RateConfig>() // moon balance user

  //redux
  const historyTransaction = useSelector(
    (state: xxreStoreTypes.Props) => state.history
  )
  const rateConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.rateConfig
  )
  const connectWallet = useSelector(
    (state: xxreStoreTypes.Props) => state.connectWallet
  )
  const connectWeb3 = useSelector((state: xxreStoreTypes.Props) => state.web3)

  const styled = useStyled()

  setTimeout(() => {
    setShowChart(true)
  }, 1000)

  const convertToInternationalCurrencySystem = (labelValue: number) => {
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + 'B'
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + 'M'
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + 'K'
      : Math.abs(Number(labelValue))
  }

  const getMoonBalanceOf = async () => {
    let web3 = connectWeb3.web3
    if (contractMoonToken) {
      let balance = await contractMoonToken.methods
        .balanceOf(connectWallet.connect?.address)
        .call()
      setMoonBalance(parseFloat(await web3.utils.fromWei(balance)))
    }
  }

  const getFundingGoal = async () => {
    let web3 = connectWeb3.web3
    if (contractSale && web3) {
      let target = await contractSale.methods.fundingGoal().call()
      let raise = await contractSale.methods.tokenRaised().call()
      console.log(
        'convertToInternationalCurrencySystem',
        convertToInternationalCurrencySystem(target)
      )

      setTokenRaised(parseFloat(await web3.utils.fromWei(raise)))
      setTokenTarget(parseFloat(await web3.utils.fromWei(target)))
    }
  }

  const getContributions = async () => {
    let web3 = connectWeb3.web3
    let data: any = []
    let token = ['cUSD', 'ETH', 'BTC', 'BUSD', 'USDT']
    let BNBbalance = await contractSale?.methods
      .userNativeBuyBalance(connectWallet.connect?.address)
      .call()
    let cUSDbalance = await contractSale?.methods
      .userTokenBuyBalance(
        connectWallet.connect?.address,
        xxuContract.GetContract().cUSDAddress
      )
      .call()
    let ETHbalance = await contractSale?.methods
      .userTokenBuyBalance(
        connectWallet.connect?.address,
        xxuContract.GetContract().ETHAddress
      )
      .call()
    let BTCbalance = await contractSale?.methods
      .userTokenBuyBalance(
        connectWallet.connect?.address,
        xxuContract.GetContract().BTCAddress
      )
      .call()
    let BUSDbalance = await contractSale?.methods
      .userTokenBuyBalance(
        connectWallet.connect?.address,
        xxuContract.GetContract().BUSDAddress
      )
      .call()
    let USDTbalance = await contractSale?.methods
      .userTokenBuyBalance(
        connectWallet.connect?.address,
        xxuContract.GetContract().USDTAddress
      )
      .call()
    data.push({
      name: 'BNB',
      price: parseFloat(web3.utils.fromWei(BNBbalance))
    })
    data.push({ name: 'cUSD', price: web3.utils.fromWei(cUSDbalance) })
    data.push({ name: 'ETH', price: web3.utils.fromWei(ETHbalance) })
    data.push({ name: 'BTC', price: web3.utils.fromWei(BTCbalance) })
    data.push({ name: 'BUSD', price: web3.utils.fromWei(BUSDbalance) })
    data.push({ name: 'USDT', price: web3.utils.fromWei(USDTbalance) })
    setUserContributions(data)
  }

  useEffect(() => {
    // if (historyTransaction.history) {
    //   let data = _.groupBy(
    //     historyTransaction.history.tokensale_payment_transaction,
    //     'unit'
    //   )
    //   let array: any = []
    //   Object.keys(data).map(item => {
    //     console.log('item', item)
    //     let sumPrice = _.sumBy(data[item], (item: any) => {
    //       return item.payment_amount
    //     })
    //     array.push({ name: item, price: sumPrice })
    //   })
    //   setUserContributions(array)
    // }
  }, [historyTransaction])

  useEffect(() => {
    if (userContributions) {
      console.log('userContributions', userContributions)
    }
  }, [userContributions])

  useEffect(() => {
    console.log(tokenRteEth, amountTokenPay)
    if (configTokenRate && amountTokenPay) {
      setMoonWillGet(
        amountTokenPay /
          (useToken === 'BNB'
            ? configTokenRate?.BNB.rate
            : useToken === 'cUSD'
            ? configTokenRate?.cUSD.rate
            : useToken === 'ETH'
            ? configTokenRate?.ETH.rate
            : useToken === 'BTC'
            ? configTokenRate?.BTC.rate
            : useToken === 'USDT'
            ? configTokenRate?.USDT.rate
            : useToken === 'BUSD'
            ? configTokenRate?.BUSD.rate
            : useToken === 'THB'
            ? configTokenRate?.THB.rate
            : 1)
      )
    } else {
      setMoonWillGet(0)
    }
  }, [useToken, amountTokenPay])

  useMemo(() => {
    dispatch(xxaHistory.GetHistory())
  }, [])

  useEffect(() => {
    // getBlockNumber()
    // getIcoTime()
    // getFundingGoal()

    dispatch(xxaRateConfig.GetRateConfig())
    getFundingGoal()
    if (connectWallet.connect?.connectedStatus) {
      getContributions()
      getMoonBalanceOf()
    }
  }, [connectWallet])

  useEffect(() => {
    if (rateConfig.rateConfig) {
      let data: any = _.keyBy(rateConfig.rateConfig.price_rate_config, 'symbol')
      setConfigTokenRate(data)
      // setTokenRteTHB(rateConfig.rateConfig.price_rate_thb)
      // setTokenRteEth(rateConfig.rateConfig.price_rate_ether)
      // setTokenRateTokenBuy(rateConfig.rateConfig.price_rate_token)
    }
  }, [rateConfig])

  // useEffect(() => {
  //   if (configTokenRate) {
  //     console.log('configTokenRate', configTokenRate.BNB)
  //   }
  // }, [configTokenRate])

  return (
    <div>
      <Box>
        <Grid templateColumns={styled.templateColumns} gap={8}>
          <GridItem
            bg="#fff"
            borderRadius={styled.boxBorderRadius}
            boxShadow={styled.boxItemShadow}
            p={styled.cardPadding}
            colSpan={styled.box1}
            h="220"
          >
            <Box>
              <Text color={fontBaseColor} fontWeight="600" fontSize="md">
                YOUR TOKENS BALANCE
              </Text>
            </Box>
            <HStack spacing="4" display="flex" alignItems="flex-end" mt="3">
              <Box borderRadius="20px" w="50px" h="50px" bg="#53C8BF"></Box>
              <HStack textAlign="end">
                <Text color={fontBaseColor} fontSize="xl" fontWeight="600">
                  {moonBalance.toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </Text>
                <Text color={fontBaseColor} fontSize="sm" fontWeight="600">
                  OPT
                </Text>
              </HStack>
            </HStack>
            {/* <Box py="2">
              <Divider borderColor="#E0E0E0" borderWidth="1px" />
            </Box> */}
            <Box pt="4">
              <Text color={fontBaseColor} fontWeight="600" fontSize="sm">
                YOUR CONTRIBUTION IN
              </Text>
              {userContributions ? (
                <StatGroup gridColumnGap="10px" pt="3">
                  {userContributions.map((item: any) => {
                    return (
                      <Stat key={`CONTRIBUTION-${item.name}`}>
                        <StatNumber color={fontBaseColor} fontSize="16px">
                          {item.price.toLocaleString('en-US', {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })}{' '}
                        </StatNumber>
                        <StatLabel color={fontBaseColor}>
                          {item.name.toUpperCase()}
                        </StatLabel>
                      </Stat>
                    )
                  })}
                </StatGroup>
              ) : null}
            </Box>
          </GridItem>
          <GridItem
            bg="#fff"
            borderRadius={styled.boxBorderRadius}
            boxShadow={styled.boxItemShadow}
            p={styled.cardPadding}
            colSpan={styled.box2}
            h="220"
          >
            <Flex h="100%" color="white">
              <Box flex="1">
                <Text color={fontBaseColor} fontWeight="600" fontSize="md">
                  TOKEN SALE PROGRESS
                </Text>
                <Box
                  display="flex"
                  alignItems="center"
                  position="relative"
                  flex="1"
                  height="calc(100% - 52px)"
                >
                  <Box
                    fontWeight="600"
                    position="absolute"
                    top="25px"
                    width="fit-content"
                    color={fontBaseColor}
                  >
                    RAISED
                    <Text>
                      {tokenRaised.toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}{' '}
                      MOON
                    </Text>
                  </Box>
                  <Slider
                    mt="24px"
                    size="lg"
                    isReadOnly
                    height="30px"
                    value={tokenRaised}
                    // min={0}
                    max={tokenTarget}
                    // step={1}
                  >
                    <SliderTrack h="10px" borderRadius="10px" bg="#f1f1f1">
                      <Box position="relative" right={10} />
                      <SliderFilledTrack bg="#53C8BF" />
                    </SliderTrack>
                    <SliderThumb boxSize="28px" bg="#53C8BF" />
                  </Slider>
                  <Box
                    top="25px"
                    width="fit-content"
                    position="absolute"
                    fontWeight="600"
                    right="0"
                    color={fontBaseColor}
                  >
                    TARGET
                    <Text>
                      {convertToInternationalCurrencySystem(tokenTarget)} MOON
                    </Text>
                  </Box>
                </Box>
                <HStack w="100%">
                  <Text w="100%" fontSize="md" color={fontBaseColor}>
                    SALES END IN
                  </Text>
                  <Text
                    w="100%"
                    fontSize="md"
                    fontWeight="600"
                    color={fontBaseColor}
                    textAlign="end"
                  >
                    1 Feb 2022
                  </Text>
                </HStack>
              </Box>
              <Center w="10px" px={4} height="100%">
                <Divider
                  borderColor="#E0E0E0"
                  borderWidth="1px"
                  orientation="vertical"
                />
              </Center>
              <Box position="relative" flex="1">
                <Text color={fontBaseColor} fontWeight="600" fontSize="md">
                  TOKEN CALCULATION
                </Text>
                <Text color={fontBaseColor} fontSize="xs">
                  Enter amount to calculate token.
                </Text>
                <HStack mt="2">
                  <InputGroup w="50%" color={fontBaseColor} size="md">
                    <NumberInput>
                      <NumberInputField
                        pr="82px"
                        value={amountTokenPay}
                        onChange={(
                          value: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setAmountTokenPay(parseFloat(value.target.value))
                        }}
                      />
                    </NumberInput>
                    <InputRightElement
                      width="80px"
                      // p={0}
                      children={
                        <>
                          <Divider
                            height="70%"
                            borderColor="#E0E0E0"
                            orientation="vertical"
                            pr="4px"
                          />
                          <Menu>
                            <MenuButton
                              width="70px"
                              variant="unstyled"
                              _focus={{ boxShadow: 'none' }}
                              as={Button}
                              // rightIcon={<BiChevronDown />}
                              fontWeight="500"
                            >
                              {useToken}
                            </MenuButton>
                            <MenuList minWidth="100px">
                              <MenuOptionGroup
                                defaultValue="BNB"
                                // title="Order"
                                type="radio"
                                onChange={(value: string | string[]) => {
                                  setUseToken(value.toString())
                                }}
                              >
                                <MenuItemOption value="BNB">BNB</MenuItemOption>
                                <MenuItemOption value="cUSD">
                                  cUSD
                                </MenuItemOption>
                                <MenuItemOption value="ETH">ETH</MenuItemOption>
                                <MenuItemOption value="BTC">BTC</MenuItemOption>
                                <MenuItemOption value="BUSD">
                                  BUSD
                                </MenuItemOption>
                                <MenuItemOption value="USDT">
                                  USDT
                                </MenuItemOption>
                                <MenuItemOption value="THB">THB</MenuItemOption>
                              </MenuOptionGroup>
                            </MenuList>
                          </Menu>
                        </>
                      }
                    />
                  </InputGroup>
                  <HStack w="50%">
                    <Text color={fontBaseColor}>=</Text>
                    <Text fontSize="lg" color={fontBaseColor}>
                      {moonWillGet.toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}{' '}
                      MOON
                    </Text>
                  </HStack>
                </HStack>
                <Text mt="1" color={fontBaseColor} fontSize="xs">
                  Amount calculated based current tokens price.
                </Text>
                <Box bottom="0" position="absolute">
                  <Button colorScheme="teal" bg="#53C8BF">
                    Buy Token
                  </Button>
                </Box>
              </Box>
            </Flex>
          </GridItem>
        </Grid>
      </Box>
      <Box mt="8">
        <Grid
          templateColumns={styled.templateColumns}
          templateRows="repeat(2, 1fr)"
          gap={8}
        >
          <GridItem
            bg="#fff"
            borderRadius={styled.boxBorderRadius}
            boxShadow={styled.boxItemShadow}
            p={styled.cardPadding}
            colSpan={styled.box3}
            rowSpan={2}
            h="400"
          >
            <Box h="100%" overflow="auto">
              {showChart ? (
                <Chart
                  options={options}
                  series={series}
                  type="area"
                  width={'100%'}
                  height={'94%'}
                />
              ) : null}
            </Box>
          </GridItem>
          <GridItem
            bg="#fff"
            borderRadius={styled.boxBorderRadius}
            boxShadow={styled.boxItemShadow}
            p={styled.cardPadding}
            colSpan={styled.box4}
            rowSpan={2}
            maxH="400px"
            // h="auto"
          >
            <Text fontWeight="600" fontSize="md" color={fontBaseColor}>
              TRANSACTIONS
            </Text>
            <Box maxH="320px" overflow="auto" overflowY="auto">
              {historyTransaction.history ? (
                <Table variant="simple" colorScheme="blackAlpha">
                  {/* <TableCaption>
                    Imperial to metric conversion factors
                  </TableCaption> */}
                  <Thead>
                    <Tr>
                      <Th>AMOUNT</Th>
                      <Th>TYPE</Th>

                      <Th>STATUS</Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {historyTransaction.history.tokensale_payment_transaction
                      .slice(0, 7)
                      .map((item, index) => {
                        return (
                          <Tr key={`tnx-data-${index}`}>
                            <Td>
                              {item.token_amount.toLocaleString('en-US', {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              })}
                            </Td>
                            <Td>
                              {item.type === 'ccp'
                                ? 'CCP Points'
                                : item.type === 'credit_card'
                                ? 'Credit Card'
                                : item.type === 'qr_code'
                                ? 'QR Code'
                                : 'Crypto'}
                            </Td>

                            <Td>
                              {item.status === 'cancel' ? (
                                <BsXCircle color="#FF6F6E" fontSize="30px" />
                              ) : item.status === 'success' ? (
                                <BsCheckCircle
                                  color="#02D284"
                                  fontSize="30px"
                                />
                              ) : (
                                <BsExclamationCircle
                                  color="#FFC41C"
                                  fontSize="30px"
                                />
                              )}
                            </Td>
                          </Tr>
                        )
                      })}
                  </Tbody>
                </Table>
              ) : (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              )}
            </Box>
          </GridItem>
          <GridItem
            bg="#fff"
            borderRadius="16px"
            boxShadow="rgb(0 0 0 / 4%) 0px 7px 11px"
            p={styled.cardPadding}
            colSpan={styled.box5}
            h="170px"
          >
            <Stack>
              <HStack>
                <FaFacebookSquare fontSize="40px" color="#3B5794" />
                <Link>OPTIC TOKEN</Link>
              </HStack>
              <HStack>
                <FaTwitterSquare fontSize="40px" color="#50ABF1" />
                <Link>optic_token</Link>
              </HStack>
              <HStack>
                <FaTelegram fontSize="40px" color="#61A8DE" />
                <Link>optic_token</Link>
              </HStack>
            </Stack>
          </GridItem>
          <GridItem
            bg="#fff"
            borderRadius="16px"
            boxShadow="rgb(0 0 0 / 4%) 0px 7px 11px"
            p={styled.cardPadding}
            colSpan={styled.box5}
            h="220px"
          >
            2
          </GridItem>
          <GridItem
            bg="#fff"
            borderRadius="16px"
            boxShadow="rgb(0 0 0 / 4%) 0px 7px 11px"
            p={styled.cardPadding}
            colSpan={styled.box5}
            h="220px"
          >
            3
          </GridItem>
        </Grid>
      </Box>
    </div>
  )
}

const fontBaseColor = '#6B7583'

export default Dashboard
