import axios from 'axios'
import xxsRateConfigServiceTypes from 'services/RateConfig/types'
import xxaDevice from 'actions/Utils/Device'
import { servicePath } from 'configs'

import xxaInitial from 'actions/Initial'
// import xxaAnonymousToken from 'actions/Auth/AnonymousToken'

const xxsRateConfigService = {
  GetRateConfig: async (
  ): Promise<xxsRateConfigServiceTypes.GetRateConfigResponse> => {
    let response: xxsRateConfigServiceTypes.GetRateConfigResponse
    const anonymousToken = await xxaInitial.CheckAnonymous()

    try {
      const responseData =
        await axios.get<xxsRateConfigServiceTypes.GetRateConfigSuccess>(
          `${servicePath.service.general}/v1/payment/config`,
          {
            headers: {
              Authorization: anonymousToken,
              'device-Id': await xxaDevice.GetDeviceId()
            }
          }
        )

      response = {
        status: responseData.status,
        rateConfig: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  }
}

export default xxsRateConfigService
