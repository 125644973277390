import { useTranslation } from 'react-i18next'
import { useRef, useState, useMemo, useEffect } from 'react'

//component
import Navbars from 'components/Navbars'
import {
  Box,
  ChakraProvider,
  Grid,
  GridItem,
  useBreakpointValue,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@chakra-ui/react'

// Utils
import xxuLanguage from 'utils/Language'

//component
import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
//component page

import xxlTopHeaderLayoutProps from 'layouts/TopHeaderLayout/types'
import xxaWeb3 from 'actions/Web3'

const smVariant = { navigation: 'drawer', navigationButton: true }
const mdVariant = { navigation: 'sidebar', navigationButton: false }

const TopHeaderLayout = ({
  children
}: xxlTopHeaderLayoutProps.PropTypes): JSX.Element => {
  const { t } = useTranslation(['common'])

  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const [isNavSmall, setIsNavSmall] = useState(true)

  const variants = useBreakpointValue({ base: smVariant, md: mdVariant })

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen)
  const [menu, setMenu] = useState('top')

  useEffect(() => {
    window.localStorage.setItem('nav', JSON.stringify(isNavSmall))
  }, [isNavSmall])

  useMemo(() => {
    let nav = window.localStorage.getItem('nav')
    if (nav) {
      setIsNavSmall(JSON.parse(nav))
    } else {
      setIsNavSmall(true)
    }
  }, [])

  return (
    <>
      <Header
        showSidebarButton={variants?.navigationButton}
        onShowSidebar={toggleSidebar}
        menu={menu}
        setIsNavSmall={setIsNavSmall}
        isNavSmall={isNavSmall}
        showConnect={false}
      />

      {children}
    </>
  )
}

export default TopHeaderLayout
