import { combineReducers } from 'redux'

import todo from 'redux/Reducers/Todo'
import post from 'redux/Reducers/Post'
import connectWallet from 'redux/Reducers/ConnectWallet'
import anonymousToken from 'redux/Reducers/Auth/AnonymousToken'
import login from 'redux/Reducers/Auth/Login'
import profile from 'redux/Reducers/Profile/Get'
import updateProfile from 'redux/Reducers/Profile/Update'
import emailSend from 'redux/Reducers/Email/Send'
import emailVerify from 'redux/Reducers/Email/Verify'
import payment from 'redux/Reducers/Payment'
import web3 from 'redux/Reducers/Web3'
import rateConfig from 'redux/Reducers/RateConfig'
import history from 'redux/Reducers/History'
import confirmPayment from 'redux/Reducers/CCPPayment/ConfirmPayment'
import requestPayment from 'redux/Reducers/CCPPayment/RequestPayment'
import verifyBalance from 'redux/Reducers/CCPPayment/VerifyBalance'
import verifyPayment from 'redux/Reducers/CCPPayment/VerifyPayment'
import getBinding from 'redux/Reducers/Binding/Get'
import verifyBinding from 'redux/Reducers/Binding/Verify'





export default combineReducers({
  post,
  todo,
  connectWallet,
  anonymousToken,
  login,
  profile,
  updateProfile,
  emailSend,
  emailVerify,
  payment,
  web3,
  rateConfig,
  history,
  confirmPayment,
  requestPayment,
  verifyBalance,
  verifyPayment,
  getBinding,
  verifyBinding
})
