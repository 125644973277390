import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
// Types
import xxreStoreTypes from 'redux/types'

//Actions
import xxaConnectWallet from 'actions/ConnectWallet'
import xxaLogin from 'actions/Auth/Login'
import xxaToken from 'actions/Utils/Token'

// Components
import Profile from 'components/Account/Profile'

import { Button, Message, toaster } from 'rsuite'
import xxaEmail from 'actions/Email'

const VerifyEmail = (): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = new URLSearchParams(useLocation().search)
  // const todo = useSelector((state: xxreStoreTypes.Props) => state.todo)
  const connectWallet = useSelector(
    (state: xxreStoreTypes.Props) => state.connectWallet
  )
  const emailVerify = useSelector(
    (state: xxreStoreTypes.Props) => state.emailVerify
  )

  const handleVerifyEmail = () => {
    let ref = params.get('ref')
    dispatch(xxaEmail.EmailVerify(ref ? ref : ''))
  }

  useEffect(() => {
    if (emailVerify.emailVerify?.msg === 'success') {
      history.replace('/countdown')
    } else {
      console.log('emailVerify',emailVerify)
    }
  }, [emailVerify])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100vh - 120px)',
        alignItems: 'center'
      }}
    >
      <Button
        onClick={() => {
          handleVerifyEmail()
        }}
        appearance="primary"
        color="blue"
      >
        Verify Email
      </Button>
    </div>
  )
}

export default VerifyEmail
