// Constants
import {
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAIL,
  GET_HISTORY_CLEAR_MESSAGE
} from 'redux/Constants/History'

// Props Types
import IAction from 'redux/Action/types'
import xxreGetHistoryReducerTypes from 'redux/Reducers/History/types'

const history = (
  state: object = {},
  action: IAction<xxreGetHistoryReducerTypes.Props>
) => {
  switch (action.type) {
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case GET_HISTORY_FAIL:
      return {
        ...state,
        ...action.payload
      }
    case GET_HISTORY_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default history
