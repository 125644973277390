// Constants
import {
  GET_RATE_CONFIG_SUCCESS,
  GET_RATE_CONFIG_FAIL,
  GET_RATE_CONFIG_CLEAR_MESSAGE
} from 'redux/Constants/RateConfig'

// Props Types
import IAction from 'redux/Action/types'
import xxrePaymentReducerTypes from 'redux/Reducers/Payment/types'

const rateConfig = (
  state: object = {},
  action: IAction<xxrePaymentReducerTypes.Props>
) => {
  switch (action.type) {
    case GET_RATE_CONFIG_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case GET_RATE_CONFIG_FAIL:
      return {
        ...state,
        ...action.payload
      }
    case GET_RATE_CONFIG_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default rateConfig
