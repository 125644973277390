import axios from 'axios'
import xxsPaymentServiceTypes from 'services/Payment/types'
import xxaDevice from 'actions/Utils/Device'
import { servicePath } from 'configs'

import xxaInitial from 'actions/Initial'

const xxsPaymentService = {
  Payment: async (
    paymentAmount: number,
    paymentType:string
  ): Promise<xxsPaymentServiceTypes.PaymentResponse> => {
    let response: xxsPaymentServiceTypes.PaymentResponse
    const token = await xxaInitial.Check()

    const data = {
      payment_amount: paymentAmount.toString(),
      callback_url: `${servicePath.base_url}/tokensale`,
      payment_type:paymentType
    }

    try {
      const responseData =
        await axios.post<xxsPaymentServiceTypes.PaymentSuccess>(
          `${servicePath.service.general}/v1/payment/request`,
          data,
          {
            headers: {
              Authorization: token,
              'device-Id': await xxaDevice.GetDeviceId()
            }
          }
        )

      response = {
        status: responseData.status,
        payment: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  }
}

export default xxsPaymentService
