import { Dispatch } from 'redux'

// Redux Constants
import {
  GET_BINDING_CLEAR_MESSAGE,
  GET_BINDING_FAIL,
  GET_BINDING_SUCCESS
} from 'redux/Constants/Binding/Get'
import {
  VERIFY_BINDING_CLEAR_MESSAGE,
  VERIFY_BINDING_FAIL,
  VERIFY_BINDING_SUCCESS
} from 'redux/Constants/Binding/Verify'

// Utils
import xxuResponse from 'utils/Response'

//service
import xxsBindingService from 'services/Binding'

const xxaBinding = {
  GetBinding: (mobileNumber: string) => async (dispatch: Dispatch) => {
    dispatch({
      type: GET_BINDING_CLEAR_MESSAGE
    })
    const response = await xxsBindingService.GetBinding(mobileNumber)
    if (response.status === 200) {
      xxuResponse.Success({
        type: GET_BINDING_SUCCESS,
        dispatch,
        payload: response
      })
    } else {
      xxuResponse.Error({
        type: GET_BINDING_FAIL,
        errorPage: true,
        dispatch,
        error: response.error
      })
    }
  },
  ClearGetBinding: () => async (dispatch: Dispatch) => {
    dispatch({
      type: GET_BINDING_CLEAR_MESSAGE
    })
  },
  VerifyBinding:
    (mobileNumber: string, otp: string, otpRefCode: string) =>
    async (dispatch: Dispatch) => {
      dispatch({
        type: VERIFY_BINDING_CLEAR_MESSAGE
      })
      const response = await xxsBindingService.VerifyBinding(
        mobileNumber,
        otp,
        otpRefCode
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: VERIFY_BINDING_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: VERIFY_BINDING_FAIL,
          errorPage: true,
          dispatch,
          error: response.error
        })
      }
    },
  ClearVerifyBinding: () => async (dispatch: Dispatch) => {
    dispatch({
      type: VERIFY_BINDING_CLEAR_MESSAGE
    })
  }
}

export default xxaBinding
