import React,{useEffect} from 'react'
import TokenSale from 'views/TokenSale';

function BuyTokens() {
  useEffect(() => {
    console.log('object')
  }, [])
  return (
    <TokenSale />
  )
}

export default BuyTokens
