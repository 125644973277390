import { useLocation, Link } from 'react-router-dom'
import { useEffect, useMemo } from 'react'
import {
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  VStack,
  Flex,
  Center,
  Text,
  Divider,
  useTheme,
  IconButton,
  DrawerFooter
} from '@chakra-ui/react'

//style
//icon
import { AiOutlineBarChart, AiOutlineDollarCircle } from 'react-icons/ai'
import { BiTransfer } from 'react-icons/bi'
import { CgProfile } from 'react-icons/cg'
import { GiTiredEye } from 'react-icons/gi'
import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons'

import { IconType } from 'react-icons'

interface Props {
  onClose: Function
  isOpen: boolean
  variant: string | undefined | 'sidebar' | 'drawer'
  isNavSmall: boolean
  setIsNavSmall:Function
}

const TabButton = ({
  isNavSmall,
  location,
  pathName,
  onClick,
  Icon,
  nameButton
}: {
  isNavSmall: boolean
  location: Location
  pathName: string
  onClick: Function
  Icon: IconType
  nameButton: string
}) => (
  <Link
    style={{ width: '100%', textDecoration: 'none', color: 'unset' }}
    to={pathName}
  >
    {isNavSmall ? (
      <Button
        size="lg"
        color={colorButton}
        isActive={location.pathname === pathName}
        _active={activeButton}
        justifyContent="flex-start"
        bg={bgButton}
        onClick={() => {
          console.log('location', location.pathname)
          onClick()
        }}
        w="100%"
        borderRadius="16px"
        leftIcon={
          <Center
            w="28px"
            h="28px"
            borderRadius="10px"
            bg={location.pathname === pathName ? bgIconActive : bgIcon}
          >
            <Icon
              color={location.pathname === pathName ? bgIcon : bgIconActive}
              fontSize={22}
            />
          </Center>
        }
      >
        <Text fontSize="md">{nameButton}</Text>
      </Button>
    ) : (
      <IconButton
        size="lg"
        borderRadius="16px"
        color={colorButton}
        isActive={location.pathname === pathName}
        _active={activeButton}
        bg={bgButton}
        aria-label={nameButton}
        icon={
          <Center
            w="28px"
            h="28px"
            borderRadius="10px"
            bg={location.pathname === pathName ? bgIconActive : bgIcon}
          >
            <Icon
              color={location.pathname === pathName ? bgIcon : bgIconActive}
              fontSize={22}
            />
          </Center>
        }
      />
    )}
  </Link>
)

const SidebarContent = ({
  onClick,
  location,
  isNavSmall
}: {
  onClick: Function
  location: Location
  isNavSmall: boolean
}) => (
  <VStack spacing={2}>
    <TabButton
      isNavSmall={isNavSmall}
      location={location}
      pathName="/dashboard"
      onClick={() => onClick()}
      Icon={AiOutlineBarChart}
      nameButton="Dashboard"
    />
    <TabButton
      isNavSmall={isNavSmall}
      location={location}
      pathName="/dashboard/buytokens"
      onClick={() => onClick()}
      Icon={AiOutlineDollarCircle}
      nameButton="Buy Tokens"
    />
    <TabButton
      isNavSmall={isNavSmall}
      location={location}
      pathName="/dashboard/transactions"
      onClick={() => onClick()}
      Icon={BiTransfer}
      nameButton="Transactions"
    />
    <TabButton
      isNavSmall={isNavSmall}
      location={location}
      pathName="/dashboard/profile"
      onClick={() => onClick()}
      Icon={CgProfile}
      nameButton="Profile"
    />
  </VStack>
)

const Sidebar = ({ isOpen, variant, onClose, isNavSmall,setIsNavSmall }: Props) => {
  const location: any = useLocation()
  const theme = useTheme()

  console.log('theme', theme)
  return variant === 'sidebar' ? (
    <Box
      position="fixed"
      left={0}
      p={4}
      pt={0}
      px={0}
      w={isNavSmall ? '250px' : '80px'}
      top={0}
      h="100%"
      bg="#F7FAFC"
      // borderWidth="1px"
      // borderRadius="lg"
    >
      <Flex bg="#F7FAFC" p={4} color="white" justifyContent="center">
        <Center flex="1" h="40px">
          <GiTiredEye color="#50C7C2" fontSize={60} />
          {/* <Text fontSize="xl">OPTIC TOKEN</Text> */}
        </Center>
      </Flex>
      <Center>
        <Divider width="80%" size="20px" orientation="horizontal" />
      </Center>
      <Box p={4}>
        <SidebarContent
          isNavSmall={isNavSmall}
          location={location}
          onClick={() => onClose()}
        />
      </Box>
      <Box position='absolute' bottom='0' right='0' p={4}>
        <IconButton
          onClick={() => setIsNavSmall(!isNavSmall)}
          color="#6B7583"
          variant="outline"
          aria-label="Search database"
          icon={isNavSmall ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        />
      </Box>
    </Box>
  ) : (
    <Drawer isOpen={isOpen} placement="left" onClose={() => onClose()}>
      <DrawerOverlay>
        <DrawerContent bg="#fff">
          <DrawerCloseButton />
          <DrawerHeader>OPTIC TOKEN</DrawerHeader>
          <DrawerBody>
            <SidebarContent
              isNavSmall={true}
              location={location}
              onClick={() => onClose()}
            />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

export default Sidebar

const activeButton = {
  bg: '#fff',
  color: '#202733',
  transform: 'scale(0.98)',
  borderColor: '#bec3c9',
  boxShadow: 'rgb(0 0 0 / 4%) 0px 7px 11px'
}

const bgButton = '#F7FAFC'
const colorButton = '#A0AFBF'
const bgIcon = '#fff'
const bgIconActive = '#50C7C2'
