import axios from 'axios'
import xxsEmailSendServiceTypes from 'services/Email/Send/types'
import xxaDevice from 'actions/Utils/Device'
import { servicePath } from 'configs'

import xxaInitial from 'actions/Initial'

const xxsEmailSendService = {
  EmailSend: async (
    email: string
  ): Promise<xxsEmailSendServiceTypes.EmailSendResponse> => {
    let response: xxsEmailSendServiceTypes.EmailSendResponse
    const token = await xxaInitial.Check()

    const data = {
      to_email: email
    }

    try {
      const responseData =
        await axios.post<xxsEmailSendServiceTypes.EmailSendSuccess>(
          `${servicePath.service.general}/v1/register/send/verify-email`,
          data,
          {
            headers: {
              Authorization: token,
              'device-Id': await xxaDevice.GetDeviceId()
            }
          }
        )

      response = {
        status: responseData.status,
        emailSend: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  }
}

export default xxsEmailSendService
