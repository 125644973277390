// Constants
import {
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  GET_PROFILE_CLEAR_MESSAGE
} from 'redux/Constants/Profile/Get'

// Props Types
import IAction from 'redux/Action/types'
import xxreProfileReducerTypes from 'redux/Reducers/Profile/Get/types'

const profile = (
  state: object = {},
  action: IAction<xxreProfileReducerTypes.Props>
) => {
  switch (action.type) {
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case GET_PROFILE_FAIL:
      return {
        ...state,
        ...action.payload
      }
    case GET_PROFILE_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default profile
