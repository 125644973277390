import { useEffect, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Types
import xxreStoreTypes from 'redux/types'

//Actions
import xxaProfile from 'actions/Profile'

// Components
import Task from 'views/Todo/Task'
import Post from 'views/Todo/Post'
//Web3
import Web3 from 'web3'

import { Message, toaster, ButtonToolbar } from 'rsuite'
import {
  Box,
  Grid,
  GridItem,
  useBreakpointValue,
  Flex,
  Center,
  Text,
  Divider,
  Progress,
  HStack,
  VStack,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  StatHelpText,
  StatArrow,
  CircularProgress,
  CircularProgressLabel,
  Stack,
  Avatar,
  Badge,
  Spinner,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  PinInputField,
  PinInput,
  ModalCloseButton,
  InputGroup,
  useDisclosure,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  AlertDialog,
  AlertIcon,
  Alert,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react'
import { FiMail } from 'react-icons/fi'
import xxaEmail from 'actions/Email'
import xxuValidation from 'utils/Validation'
import xxaBinding from 'actions/Binding'

const Profile = (): JSX.Element => {
  const dispatch = useDispatch()
  // const todo = useSelector((state: xxreStoreTypes.Props) => state.todo)
  const connectWallet = useSelector(
    (state: xxreStoreTypes.Props) => state.connectWallet
  )
  const profile = useSelector((state: xxreStoreTypes.Props) => state.profile)
  const emailSend = useSelector(
    (state: xxreStoreTypes.Props) => state.emailSend
  )
  const updateProfile = useSelector(
    (state: xxreStoreTypes.Props) => state.updateProfile
  )
  const getBinding = useSelector(
    (state: xxreStoreTypes.Props) => state.getBinding
  )
  const verifyBinding = useSelector(
    (state: xxreStoreTypes.Props) => state.verifyBinding
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [displayName, setDisplayName] = useState('')
  const [userAddress, setUserAddress] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [walletAddress, setWalletAddress] = useState('')
  const [age, setAge] = useState('')
  const [twitter, setTwitter] = useState('')
  const [telegram, setTelegram] = useState('')
  const [facebook, setFacebook] = useState('')
  const [verifyEmail, setVerifyEmail] = useState(false)
  const [mobileNumber, setMobileNumber] = useState('')
  const [otpRef, setOtpRef] = useState('')
  const [otp, setOtp] = useState('')
  const [bindingSuccess, setBindingSuccess] = useState(false)
  const cancelRef: React.LegacyRef<HTMLButtonElement> | undefined = useRef(null)
  const [isOpenAlert, setIsOpenAlert] = useState(false)
  const [otpError, setOtpError] = useState(false)

  //loadingButton
  const [saveLoading, setSaveLoading] = useState(false)
  const [sendEmailButton, setSendEmailButton] = useState(false)
  const [validateField, setValidateField] = useState(false)

  const handleUpdateProfile = () => {
    let answers = []
    if (displayName) {
      answers.push({
        question_short_name: 'display_name',
        answer: displayName
      })
    }
    if (firstName) {
      answers.push({
        question_short_name: 'first_name',
        answer: firstName
      })
    }
    if (lastName) {
      answers.push({
        question_short_name: 'last_name',
        answer: lastName
      })
    }
    if (twitter) {
      answers.push({
        question_short_name: 'twitter_id',
        answer: twitter
      })
    }
    if (telegram) {
      answers.push({
        question_short_name: 'telegram_id',
        answer: telegram
      })
    }
    if (facebook) {
      answers.push({
        question_short_name: 'facebook_id',
        answer: facebook
      })
    }
    if (age) {
      answers.push({
        question_short_name: 'age',
        answer: parseInt(age)
      })
    }

    console.log('answers', answers)
    dispatch(xxaProfile.UpdateProfile(answers, email))
    setSaveLoading(true)

    if (email) {
      //call send email
      //
    }
  }

  const handleSendEmail = () => {
    // const strEmail = email.trim()
    // const re =
    //   /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // const emailValid = re.test(strEmail)
    let emailValid = xxuValidation.Email(email)
    console.log('emailValid', emailValid)
    if (emailValid) {
      dispatch(xxaEmail.EmailSend(email))
    } else {
      toaster.push(
        <Message
          onClose={() => {
            setSaveLoading(false)
          }}
          showIcon
          type={'warning'}
        >
          Email ไม่ถูกต้อง
        </Message>,
        { placement: 'topEnd' }
      )
    }
  }

  const handleGetBinding = () => {
    if (mobileNumber) {
      dispatch(xxaBinding.GetBinding(mobileNumber))
    } else {
      toaster.push(
        <Message
          onClose={() => {
            setSaveLoading(false)
          }}
          showIcon
          type={'warning'}
        >
          กรุณากรอกเบอร์
        </Message>,
        { placement: 'topEnd' }
      )
    }
  }

  const handleSendOTP = () => {
    if (mobileNumber && otp && otpRef) {
      dispatch(xxaBinding.VerifyBinding(mobileNumber, otp, otpRef))
    } else {
      toaster.push(
        <Message
          onClose={() => {
            setSaveLoading(false)
          }}
          showIcon
          type={'warning'}
        >
          กรุณากรอกเบอร์
        </Message>,
        { placement: 'topEnd' }
      )
    }
  }

  useMemo(() => {
    if (connectWallet.connect?.connectedStatus) {
      setUserAddress(connectWallet.connect?.address)
      // dispatch(xxaProfile.GetProfile())
    }
  }, [connectWallet])

  useEffect(() => {
    if (profile.profile) {
      setDisplayName(profile.profile.tokensale_user_profile?.display_name || '')
      setFirstName(profile.profile.tokensale_user_profile?.first_name || '')
      setLastName(profile.profile.tokensale_user_profile?.last_name || '')
      setWalletAddress(
        profile.profile.tokensale_user_profile?.wallet_address || ''
      )
      setTwitter(profile.profile.tokensale_user_profile?.twitter_id || '')
      setTelegram(profile.profile.tokensale_user_profile?.telegram_id || '')
      setFacebook(profile.profile.tokensale_user_profile?.facebook_id || '')
      setAge(profile.profile.tokensale_user_profile?.age || '')
      setVerifyEmail(profile.profile.tokensale_user_profile?.verify_email)
      setEmail(profile.profile.tokensale_user_profile?.email || '')
      // setBindingSuccess(
      //   profile.profile.tokensale_user_profile?.chomchob_user_binding.length > 0
      //     ? true
      //     : false
      // )
      if (
        profile.profile.tokensale_user_profile?.chomchob_user_binding.length > 0
      ) {
        setBindingSuccess(true)
        setMobileNumber(
          profile.profile.tokensale_user_profile?.chomchob_user_binding[0]
            .mobile_number
        )
      } else if (
        profile.profile.tokensale_user_profile?.chomchob_user_binding.length ===
        0
      ) {
        setBindingSuccess(false)
        setMobileNumber('')
      }
    }
  }, [profile])

  useEffect(() => {
    if (updateProfile.updateProfile) {
      if (updateProfile.updateProfile?.msg === 'success') {
        dispatch(xxaProfile.GetProfile())
        dispatch(xxaProfile.ClearUpdateProfile())
        setSaveLoading(false)
        setTimeout(() => {
          toaster.push(
            <Message
              onClose={() => {
                setSaveLoading(false)
              }}
              showIcon
              type={'success'}
            >
              success
            </Message>,
            { placement: 'topEnd' }
          )
        }, 500)
      } else if (updateProfile.updateProfile?.msg !== 'success') {
        setTimeout(() => {
          toaster.push(
            <Message
              onClose={() => {
                dispatch(xxaProfile.ClearUpdateProfile())
                setSaveLoading(false)
              }}
              showIcon
              type={'error'}
            >
              {updateProfile.error?.msg
                ? updateProfile.error?.msg
                : 'เกิดข้อผิดพลาดบางประการ'}
            </Message>
          )
        }, 500)
      }
    }
  }, [updateProfile])

  useEffect(() => {
    if (
      !displayName ||
      !firstName ||
      !lastName ||
      !age ||
      !email ||
      !twitter ||
      !telegram ||
      !facebook
    ) {
      setValidateField(false)
    } else {
      setValidateField(true)
      // const strEmail = email.trim()
      // const re =
      //   /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      // const emailValid = re.test(strEmail)
      // console.log('emailValid', emailValid)
      // if (emailValid) {
      //   setValidateField(true)
      // } else {
      //   setValidateField(false)
      // }
    }
  }, [
    displayName,
    firstName,
    lastName,
    age,
    email,
    twitter,
    telegram,
    facebook
  ])

  useEffect(() => {
    if (emailSend.emailSend?.msg === 'success') {
      setSendEmailButton(true)
      dispatch(xxaEmail.ClearEmailSend())
      setTimeout(() => {
        toaster.push(
          <Message showIcon type={'success'}>
            {'ส่งอีเมลสำเร็จเเล้ว'}
          </Message>,
          { placement: 'topEnd' }
        )
      }, 500)
    }

    if (emailSend.error?.msg === 'register.error.email is already exists') {
      setTimeout(() => {
        toaster.push(
          <Message
            onClose={() => {
              dispatch(xxaEmail.ClearEmailSend())
              setSendEmailButton(false)
            }}
            showIcon
            type={'error'}
          >
            {updateProfile.error?.msg
              ? updateProfile.error?.msg
              : 'อีเมลนี้ถูกใช้งานแล้ว'}
          </Message>,
          { placement: 'topEnd' }
        )
      }, 500)
    }
  }, [emailSend])

  useEffect(() => {
    if (getBinding.getBinding) {
      console.log('getBinding', getBinding.getBinding.mobile_number)
      setOtpRef(getBinding.getBinding.otp_ref_code)
      onOpen()
    }
  }, [getBinding])

  useEffect(() => {
    if (verifyBinding.verifyBinding) {
      onClose()
      setTimeout(() => {
        setIsOpenAlert(false)
      }, 1500)
    } else if (verifyBinding.error) {
      setOtpError(true)
    }
  }, [verifyBinding])

  return (
    // <div>
    //   <Form layout="horizontal">
    //     <Form.Group controlId="name-6">
    //       <Form.ControlLabel>Display Name</Form.ControlLabel>
    //       <Form.Control
    //         readOnly={verifyEmail}
    //         name="name"
    //         onChange={(value: any) => {
    //           setDisplayName(value)
    //         }}
    //         value={displayName}
    //       />
    //     </Form.Group>
    //     <Form.Group controlId="name-6">
    //       <Form.ControlLabel>First Name</Form.ControlLabel>
    //       <Form.Control
    //         readOnly={verifyEmail}
    //         name="name"
    //         onChange={(value: any) => {
    //           setFirstName(value)
    //         }}
    //         value={firstName}
    //       />
    //     </Form.Group>
    //     <Form.Group controlId="name-6">
    //       <Form.ControlLabel>Last Name</Form.ControlLabel>
    //       <Form.Control
    //         readOnly={verifyEmail}
    //         name="name"
    //         onChange={(value: any) => {
    //           setLastName(value)
    //         }}
    //         value={lastName}
    //       />
    //     </Form.Group>
    //     <Form.Group controlId="name-6">
    //       <Form.ControlLabel>Age</Form.ControlLabel>
    //       <Form.Control
    //         readOnly={verifyEmail}
    //         name="name"
    //         onChange={(value: any) => {
    //           setAge(value)
    //         }}
    //         value={age}
    //       />
    //     </Form.Group>
    //     <Form.Group controlId="name-6">
    //       <Form.ControlLabel>Email</Form.ControlLabel>
    //       <Form.Control
    //         readOnly={verifyEmail}
    //         name="name"
    //         onChange={(value: any) => {
    //           setEmail(value)
    //           setSendEmailButton(false)
    //         }}
    //         value={email}
    //       />
    //       {profile?.profile?.tokensale_user_profile?.email || email ? (
    //         <Form.ControlLabel
    //           style={{ textAlign: 'left', marginLeft: '10px' }}
    //         >
    //           {verifyEmail ? (
    //             <span style={{ color: '#37AB3B' }}>Verified</span>
    //           ) : sendEmailButton ? (
    //             <span>Sent Email Verified!</span>
    //           ) : (
    //             <a
    //               style={{ cursor: 'pointer' }}
    //               onClick={() => {
    //                 handleSendEmail()
    //               }}
    //             >
    //               Send Email Verify
    //             </a>
    //           )}
    //         </Form.ControlLabel>
    //       ) : null}
    //     </Form.Group>
    //     <Form.Group controlId="name-6">
    //       <Form.ControlLabel>Twitter</Form.ControlLabel>
    //       <Form.Control
    //         readOnly={verifyEmail}
    //         name="name"
    //         onChange={(value: any) => {
    //           setTwitter(value)
    //         }}
    //         value={twitter}
    //       />
    //     </Form.Group>
    //     <Form.Group controlId="name-6">
    //       <Form.ControlLabel>Telegram</Form.ControlLabel>
    //       <Form.Control
    //         readOnly={verifyEmail}
    //         name="name"
    //         onChange={(value: any) => {
    //           setTelegram(value)
    //         }}
    //         value={telegram}
    //       />
    //     </Form.Group>
    //     <Form.Group controlId="name-6">
    //       <Form.ControlLabel>Facebook</Form.ControlLabel>
    //       <Form.Control
    //         readOnly={verifyEmail}
    //         name="name"
    //         onChange={(value: any) => {
    //           setFacebook(value)
    //         }}
    //         value={facebook}
    //       />
    //     </Form.Group>
    //     {verifyEmail ? null : (
    //       <Form.Group>
    //         <ButtonToolbar>
    //           <Button
    //             disabled={!validateField}
    //             loading={saveLoading}
    //             onClick={() => {
    //               handleUpdateProfile()
    //             }}
    //             appearance="primary"
    //             color="blue"
    //           >
    //             Save
    //           </Button>
    //         </ButtonToolbar>
    //       </Form.Group>
    //     )}
    //   </Form>
    // </div>
    <Box>
      <Grid
        // h="200px"
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(3, 1fr)"
        gap={4}
      >
        <GridItem
          bg="#fff"
          boxShadow="rgb(0 0 0 / 4%) 0px 7px 11px"
          p={4}
          borderRadius="16px"
          h="620px"
          rowSpan={2}
          colSpan={1}
        >
          <VStack>
            <Center>
              <Avatar
                size="2xl"
                name={
                  profile.profile
                    ? profile.profile.tokensale_user_profile.display_name
                      ? profile.profile.tokensale_user_profile.display_name
                      : userAddress.substring(0, 6) +
                        '...' +
                        userAddress.substring(38, 42)
                    : userAddress
                    ? userAddress.substring(0, 6) +
                      '...' +
                      userAddress.substring(38, 42)
                    : ''
                }
              />
            </Center>
            <Center pt="2">
              <Text fontWeight="600" fontSize="xl">
                {profile.profile
                  ? profile.profile.tokensale_user_profile.display_name
                    ? profile.profile.tokensale_user_profile.display_name
                    : userAddress.substring(0, 6) +
                      '...' +
                      userAddress.substring(38, 42)
                  : userAddress
                  ? userAddress.substring(0, 6) +
                    '...' +
                    userAddress.substring(38, 42)
                  : null}
              </Text>
            </Center>
            <Center>
              <FiMail fontSize="20" />
              <Text pl="2" fontWeight="500" fontSize="md">
                {email}
              </Text>
            </Center>
          </VStack>
          <Box mt="4">
            <FormControl mt="1" id="first-name" isRequired>
              <FormLabel>Display Name</FormLabel>
              <Input
                isReadOnly={verifyEmail}
                value={displayName}
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                  setDisplayName(value.target.value)
                }}
                placeholder="First name"
              />
            </FormControl>
            {/* <FormControl mt="1" id="first-name" isRequired>
              <FormLabel>First name</FormLabel>
              <Input
                value={firstName}
                isReadOnly={verifyEmail}
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                  setFirstName(value.target.value)
                }}
                placeholder="First name"
              />
            </FormControl>
            <FormControl mt="1" id="first-name" isRequired>
              <FormLabel>Last name</FormLabel>
              <Input
                value={lastName}
                isReadOnly={verifyEmail}
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                  setLastName(value.target.value)
                }}
                placeholder="First name"
              />
            </FormControl>
            <FormControl mt="1" id="first-name" isRequired>
              <FormLabel>Age</FormLabel>
              <Input
                value={age}
                isReadOnly={verifyEmail}
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                  setAge(value.target.value)
                }}
                placeholder="First name"
              />
            </FormControl> */}
            <FormControl mt="1" id="first-name" isRequired>
              <FormLabel>Facebook</FormLabel>
              <Input
                value={facebook}
                isReadOnly={verifyEmail}
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                  setFirstName(value.target.value)
                }}
                placeholder="Facebook"
              />
            </FormControl>
            <FormControl mt="1" id="first-name" isRequired>
              <FormLabel>Twitter</FormLabel>
              <Input
                value={twitter}
                isReadOnly={verifyEmail}
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                  setLastName(value.target.value)
                }}
                placeholder="Twitter"
              />
            </FormControl>
            <FormControl mt="1" id="first-name" isRequired>
              <FormLabel>Telegram</FormLabel>
              <Input
                value={telegram}
                isReadOnly={verifyEmail}
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                  setAge(value.target.value)
                }}
                placeholder="Telegram"
              />
            </FormControl>
          </Box>
          <Center mt="4">
            <Button bg="#53c8bf" colorScheme="teal" color="#fff">
              SAVE
            </Button>
          </Center>
        </GridItem>
        <GridItem
          bg="#fff"
          boxShadow="rgb(0 0 0 / 4%) 0px 7px 11px"
          borderRadius="16px"
          colSpan={1}
          p={4}
          h="260px"
        >
          <Text fontWeight="600" fontSize="md">
            YOUR ACCOUNT STATUS
          </Text>
          <Center mt="4">
            {profile.profile ? (
              profile.profile.tokensale_user_profile.verify_email ? (
                <Badge
                  p="2"
                  borderRadius="10px"
                  fontSize="18px"
                  colorScheme="green"
                >
                  Email Verified
                </Badge>
              ) : (
                <Badge
                  p="2"
                  borderRadius="10px"
                  fontSize="18px"
                  colorScheme="red"
                >
                  Email Not Verify
                </Badge>
              )
            ) : (
              <Spinner
                mb="-5px"
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="#53c8bf"
                size="xl"
              />
            )}
          </Center>
          <FormControl mt="4" id="first-name">
            <FormLabel>VERIFY EMAIL</FormLabel>
            <Input
              value={email}
              isReadOnly={verifyEmail}
              onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(value.target.value)
                setSendEmailButton(false)
              }}
              placeholder="Emaile"
            />
          </FormControl>
          <Center mt="4">
            <Button
              bg="#53c8bf"
              onClick={() => {
                handleSendEmail()
              }}
              colorScheme="teal"
              color="#fff"
            >
              SEND
            </Button>
          </Center>
        </GridItem>
        <GridItem
          borderRadius="16px"
          colSpan={1}
          bg="#fff"
          boxShadow="rgb(0 0 0 / 4%) 0px 7px 11px"
          p={4}
          h="260px"
        >
          <Text fontWeight="600" fontSize="md">
            USER CHOMCHOM BINDING
          </Text>
          <Box>
            <Center mt="4">
              {profile.profile ? (
                profile.profile.tokensale_user_profile.chomchob_user_binding
                  .length > 0 ? (
                  <Badge
                    p="2"
                    borderRadius="10px"
                    fontSize="18px"
                    colorScheme="green"
                  >
                    USER BOUND
                  </Badge>
                ) : (
                  <Badge
                    p="2"
                    borderRadius="10px"
                    fontSize="18px"
                    colorScheme="red"
                  >
                    USER NOT BOUND
                  </Badge>
                )
              ) : (
                <Spinner
                  mb="-5px"
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="#53c8bf"
                  size="xl"
                />
              )}
            </Center>
            <FormControl mt="4" id="first-name">
              <FormLabel>Mobile Number</FormLabel>
              <Input
                value={mobileNumber}
                isReadOnly={bindingSuccess}
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                  setMobileNumber(value.target.value)
                }}
                placeholder="Mobile Number"
              />
            </FormControl>
            <Center mt="4">
              <Button
                bg="#53c8bf"
                onClick={() => {
                  handleGetBinding()
                  // setIsOpenAlert(true)
                }}
                colorScheme="teal"
                color="#fff"
              >
                BINDING
              </Button>
            </Center>
          </Box>
        </GridItem>
        {/* <GridItem borderRadius="16px" colSpan={2} bg="papayawhip" /> */}
      </Grid>
      {/* modal OTP */}
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={() => {
          setIsOpenAlert(false)
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>VERIFY OTP</ModalHeader>
          <ModalCloseButton />
          <>
            <ModalBody pb={0}>
              <Center>
                <Box>
                  <HStack>
                    <PinInput
                      otp
                      placeholder=""
                      defaultValue={otp}
                      onChange={(event: any) => {
                        console.log('opt', event)
                        setOtp(event)
                        setOtpError(false)
                      }}
                    >
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                  <Box mt="2">ref : {otpRef}</Box>
                  {otpError ? (
                    <Alert mt="2" borderRadius="8px" status="error">
                      <AlertIcon />
                      OTP not correct
                    </Alert>
                  ) : null}
                </Box>
              </Center>
            </ModalBody>

            <ModalFooter>
              <Button
                disabled={otp.length < 6 ?? false}
                onClick={() => {
                  handleSendOTP()
                }}
                colorScheme="blue"
                mr={3}
              >
                Confirm OTP
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
      {/* modal AlertDialogOverlay */}
      <AlertDialog
        isOpen={isOpenAlert}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setIsOpenAlert(false)
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <Alert
              status="success"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
              borderRadius="6px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Success
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                Bonded ChomChob Users.
              </AlertDescription>
            </Alert>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  )
}

export default Profile
