import React, { useState } from 'react'
import PropagateLoader from 'react-spinners/PropagateLoader'
import {
  Image,
  HStack,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  ModalContent,
  useDisclosure,
  Button,
  InputGroup,
  InputLeftElement,
  Box,
  Text,
  PinInput,
  PinInputField,
  RadioGroup,
  Radio,
  VStack,
  Stack,
  NumberInput,
  NumberInputField,
  Heading,
  Center,
  Alert,
  AlertIcon
} from '@chakra-ui/react'

function LoadingPage() {
  let [color, setColor] = useState('#53c8bf')
  return (
    <Box h="100vh">
      <Center h="100%">
        <Stack>
          {/* <Center>
            <Text>Loading</Text>
          </Center> */}

          <PropagateLoader color={color} size={30} />
        </Stack>
      </Center>
    </Box>
  )
}

export default LoadingPage
