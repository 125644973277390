// Interfaces
import xxroRouterProps from 'routers/types'

// Routes
import xxrTodoRouter from 'routers/Todo'
import xxrAuthRouter from 'routers/Auth'
import xxrHomeRouter from 'routers/Home'
import xxroDashboardRouter from 'routers/Dashboard'


const xxroMainRouter: xxroRouterProps.PropTypes[] = [
  ...xxrTodoRouter,
  ...xxrHomeRouter,
  ...xxrAuthRouter,
  ...xxroDashboardRouter
]

export default xxroMainRouter
