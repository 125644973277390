// Constants
import {
  VERIFY_BALANCE_CLEAR_MESSAGE,
  VERIFY_BALANCE_FAIL,
  VERIFY_BALANCE_SUCCESS
} from 'redux/Constants/CCPPayment/VerifyBalance'

// Props Types
import IAction from 'redux/Action/types'
import xxreVerifyBalanceReducerTypes from 'redux/Reducers/CCPPayment/VerifyBalance/types'

const verifyBalance = (
  state: object = {},
  action: IAction<xxreVerifyBalanceReducerTypes.Props>
) => {
  switch (action.type) {
    case VERIFY_BALANCE_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case VERIFY_BALANCE_FAIL:
      return {
        ...state,
        ...action.payload
      }
    case VERIFY_BALANCE_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default verifyBalance
