// Constants
import {
  CONNECT_WALLET_SUCCESS,
  CONNECT_WALLET_FAIL,
  CONNECT_WALLET_CLEAR
} from 'redux/Constants/ConnectWallet'

// Props Types
import IAction from 'redux/Action/types'
import xxreConnectWalletReducerTypes from 'redux/Reducers/ConnectWallet/types'

const connectWallet = (
  state: object = {},
  action: IAction<xxreConnectWalletReducerTypes.Props>
) => {
  switch (action.type) {
    case CONNECT_WALLET_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case CONNECT_WALLET_FAIL:
      return {
        ...state,
        ...action.payload
      }
    case CONNECT_WALLET_CLEAR:
      return {}
    default:
      return state
  }
}

export default connectWallet
