import { resolve } from 'dns'
import { Dispatch } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
// Types
import xxreStoreTypes from 'redux/types'
// Redux Constants
import {
  CONNECT_WALLET_SUCCESS,
  CONNECT_WALLET_FAIL,
  CONNECT_WALLET_CLEAR
} from 'redux/Constants/ConnectWallet'

// Utils
import xxuWeb3 from 'utils/Web3'

//Web3
import Web3 from 'web3'

const xxaConnectWallet = {
  GetWeb3: () => {
    const connectWeb3 = useSelector((state: xxreStoreTypes.Props) => state.web3)
    return connectWeb3
  },
  // CheckChainID: () => {
  //   return new Promise<boolean>(resolve => {
  //     // let web3 = connectWeb3.web3
  //     let web3 = new Web3(window.ethereum)
  //     // let web3 : Web3 = xxaConnectWallet.GetWeb3().web3
  //     web3.eth
  //       .getChainId()
  //       .then(async netId => {
  //         console.log('netId', netId)
  //         if (netId !== 97) {
  //           // return false
  //           resolve(false)
  //         } else {
  //           // return true
  //           resolve(true)
  //         }
  //       })
  //       .catch(() => {
  //         resolve(false)
  //       })
  //     // resolve(4);
  //   })
  // },
  ChangeChainID:
    (network: boolean, address: string, connectedStatus: boolean) =>
    async (dispatch: Dispatch) => {
      const obj = {
        connectedStatus: connectedStatus,
        status: '',
        address: address,
        network: network
      }
      dispatch({
        type: connectedStatus ? CONNECT_WALLET_SUCCESS : CONNECT_WALLET_FAIL,
        payload: {
          connect: obj
        }
      })
    },

  SwitchNetwork: async () => {
    return new Promise<boolean>(async resolve => {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x61' }]
        })
        console.log('object', '1231231')
        resolve(true)
      } catch (error: any) {
        console.log('error1', error)
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x61',
                  chainName: 'Smart Chain - Testnet',
                  rpcUrls: [xxuWeb3.GetWeb3().rpc],
                  nativeCurrency: {
                    name: 'BNB',
                    symbol: 'BNB',
                    decimals: 18
                  },
                  blockExplorerUrls: ['https://testnet.bscscan.com']
                }
              ]
            })
            resolve(true)
          } catch (error) {
            console.log('error', error)
            resolve(false)
          }
        } else {
          resolve(false)
        }
      }
    })
  },
  //connect wallet function
  ConnectWallet:
    (web3: Web3) => async (dispatch: Dispatch, getState: Function) => {
      if (typeof window.ethereum !== 'undefined') {
        let netID = await web3.eth.getChainId()
        console.log('########### netID', netID)
        let checkChain: boolean =
          netID === xxuWeb3.GetWeb3().netID ? true : false
        if (checkChain) {
          try {
            window.localStorage.setItem('metamask', 'metamask')
            // const address = await window.ethereum.enable() //connect Metamask
            const address = await window.ethereum.request({
              method: 'eth_requestAccounts'
            }) //connect Metamask

            const obj = {
              connectedStatus: true,
              status: '',
              address: address[0],
              network: checkChain
            }
            dispatch({
              type: CONNECT_WALLET_SUCCESS,
              payload: {
                connect: obj
              }
            })
            return obj
          } catch (error) {
            const obj = {
              connectedStatus: false,
              status:
                '🦊 Connect to Metamask using the button on the top right.',
              address: '',
              network: checkChain
            }
            dispatch({
              type: CONNECT_WALLET_FAIL,
              payload: {
                connect: obj
              }
            })
            return obj
          }
        } else {
          let check = await xxaConnectWallet.SwitchNetwork()
          if (check) {
            // let networkID = await web3.eth.getChainId()
            const address = await window.ethereum.request({
              method: 'eth_requestAccounts'
            })
            const obj = {
              connectedStatus: true,
              status: '',
              address: address[0],
              network: checkChain
            }
            dispatch({
              type: CONNECT_WALLET_SUCCESS,
              payload: {
                connect: obj
              }
            })
            return obj
          } else {
            const obj = {
              connectedStatus: false,
              status:
                '🦊 Network not correct, Please select Binance smart chain network.',
              address: '',
              network: checkChain
            }
            return obj
          }
        }
      } else {
        window.open(
          'https://metamask.io/',
          '_blank' // <- This is what makes it open in a new window.
        )
        const obj = {
          connectedStatus: false,
          status:
            '🦊 You must install Metamask into your browser: https://metamask.io/download.html',
          address: '',
          network: false
        }
        dispatch({
          type: CONNECT_WALLET_FAIL,
          payload: {
            connect: obj
          }
        })
        return obj
      }
    }
  // CheckConnectWallet: async () => {
  //   return new Promise<boolean>(async resolve => {
  //     if (window.ethereum) {
  //       const web3 = new Web3(
  //         window.ethereum
  //           ? window.ethereum
  //           : new Web3.providers.HttpProvider(xxuWeb3.rpc)
  //       )
  //       if (web3) {
  //         let chainID = await web3.eth.getChainId()
  //         if (chainID === 97) {
  //           web3.eth.getAccounts().then(async addr => {
  //             console.log('addr', addr)
  //             if (addr.length !== 0) {
  //               resolve(true)
  //             } else {
  //               console.log('false1')

  //               resolve(false)
  //             }
  //           })
  //         } else {
  //           console.log('false2')
  //           resolve(false)
  //         }
  //       } else {
  //         console.log('false3')
  //         resolve(false)
  //       }
  //     } else {
  //       console.log('false4')
  //       resolve(false)
  //     }
  //   })
  // }
}

export default xxaConnectWallet
