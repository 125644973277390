import { useLocation, Link } from 'react-router-dom'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerContent,
  VStack,
  Flex,
  Center,
  Text,
  Divider,
  Tabs,
  TabList,
  Tab,
  HStack,
  IconButton,
  MenuList,
  Menu,
  MenuButton,
  MenuItem,
  Avatar
} from '@chakra-ui/react'

import { useScrollPosition } from '@n8tb1t/use-scroll-position'

//style
//Style
import 'styles/components/navbar.scss'
//icon
import { AiOutlineBarChart, AiOutlineDollarCircle } from 'react-icons/ai'
import { BiTransfer } from 'react-icons/bi'
import { CgProfile } from 'react-icons/cg'
import { GiTiredEye } from 'react-icons/gi'

//component page
import Dashboard from 'components/Pages/Dashboard'
import BuyTokens from 'components/Pages/BuyTokens'
import Profile from 'components/Pages/Profile'
import Transaction from 'components/Pages/Transaction'
import { IconType } from 'react-icons'

interface Props {
  onClose: Function
  isOpen: boolean
  variant: string | undefined | 'sidebar' | 'drawer'
}

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop)

const TabButton = ({
  isNavSmall,
  location,
  pathName,
  onClick,
  Icon,
  nameButton
}: {
  isNavSmall: boolean
  location: Location
  pathName: string
  onClick: Function
  Icon: IconType
  nameButton: string
}) => (
  <Link
    style={{ width: '100%', textDecoration: 'none', color: 'unset' }}
    to={pathName}
  >
    {isNavSmall ? (
      <Button
        size="lg"
        color={colorButton}
        isActive={location.pathname === pathName}
        _active={activeButton}
        justifyContent="flex-start"
        bg={bgButton}
        onClick={() => {
          console.log('location', location.pathname)
          onClick()
        }}
        w="100%"
        borderRadius="16px"
        leftIcon={
          <Center
            w="28px"
            h="28px"
            borderRadius="10px"
            bg={location.pathname === pathName ? bgIconActive : bgIcon}
          >
            <Icon
              color={location.pathname === pathName ? bgIcon : bgIconActive}
              fontSize={22}
            />
          </Center>
        }
      >
        <Text fontSize="md">{nameButton}</Text>
      </Button>
    ) : (
      <IconButton
        size="lg"
        borderRadius="16px"
        color={colorButton}
        isActive={location.pathname === pathName}
        _active={activeButton}
        bg={bgButton}
        aria-label={nameButton}
        icon={
          <Center
            w="28px"
            h="28px"
            borderRadius="10px"
            bg={location.pathname === pathName ? bgIconActive : bgIcon}
          >
            <Icon
              color={location.pathname === pathName ? bgIcon : bgIconActive}
              fontSize={22}
            />
          </Center>
        }
      />
    )}
  </Link>
)

const SidebarContent = ({
  onClick,
  location
}: {
  onClick: Function
  location: Location
}) => (
  <VStack spacing={3}>
    <TabButton
      isNavSmall={true}
      location={location}
      pathName="/dashboard"
      onClick={() => onClick()}
      Icon={AiOutlineBarChart}
      nameButton="Dashboard"
    />
    <TabButton
      isNavSmall={true}
      location={location}
      pathName="/dashboard/buytokens"
      onClick={() => onClick()}
      Icon={AiOutlineDollarCircle}
      nameButton="Buy Tokens"
    />
    <TabButton
      isNavSmall={true}
      location={location}
      pathName="/dashboard/transactions"
      onClick={() => onClick()}
      Icon={BiTransfer}
      nameButton="Transactions"
    />
    <TabButton
      isNavSmall={true}
      location={location}
      pathName="/dashboard/profile"
      onClick={() => onClick()}
      Icon={CgProfile}
      nameButton="Profile"
    />
  </VStack>
)

const NavBarButton = ({
  location,
  pathName,
  Icon,
  buttonName
}: {
  location: Location
  pathName: string
  Icon: IconType
  buttonName: string
}) => (
  <Link style={{ textDecoration: 'none', color: 'unset' }} to={pathName}>
    <Tab
      // isSelected={location.pathname === '/dashboard'}
      _selected={tabActive}
      _focus={{ boxShadow: 'none' }}
      color={colorButton}
    >
      <HStack>
        <Center
          w="28px"
          h="28px"
          borderRadius="10px"
          bg={location.pathname === pathName ? bgIconActive : bgIcon}
        >
          <Icon
            color={location.pathname === pathName ? bgIcon : bgIconActive}
            fontSize={22}
          />
        </Center>
        <Text fontSize="md">{buttonName}</Text>
      </HStack>
    </Tab>
  </Link>
)

const NavBarContent = ({
  hideAvatar,
  location
}: {
  hideAvatar: boolean
  location: Location
}) => (
  <Tabs
    size="lg"
    spacing={3}
    defaultIndex={
      location.pathname === '/dashboard'
        ? 0
        : location.pathname === '/dashboard/buytokens'
        ? 1
        : location.pathname === '/dashboard/transactions'
        ? 2
        : 3
    }
    variant="unstyled"
  >
    <TabList position="relative">
      <NavBarButton
        location={location}
        pathName="/dashboard"
        Icon={AiOutlineBarChart}
        buttonName="Dashboard"
      />
      <NavBarButton
        location={location}
        pathName="/dashboard/buytokens"
        Icon={AiOutlineDollarCircle}
        buttonName="Buy Token"
      />
      <NavBarButton
        location={location}
        pathName="/dashboard/transactions"
        Icon={BiTransfer}
        buttonName="Transactions"
      />
      <NavBarButton
        location={location}
        pathName="/dashboard/profile"
        Icon={CgProfile}
        buttonName="Profile"
      />
      <Box
        display="flex"
        alignItems="center"
        h="100%"
        position="absolute"
        right="0"
      >
        {hideAvatar ? (
          <Box className='fade-in' flex="1" display="flex" justifyContent="end">
            {1 ? (
              <HStack>
                <Text color="#6B7583" fontSize="md">
                  Welcome! gggame
                  {/* {profile.profile
                ? profile.profile.tokensale_user_profile.display_name
                  ? profile.profile.tokensale_user_profile.display_name
                  : userAddress.substring(0, 6) +
                    '...' +
                    userAddress.substring(38, 42)
                : userAddress
                ? userAddress.substring(0, 6) +
                  '...' +
                  userAddress.substring(38, 42)
                : null} */}
                </Text>
                <Menu>
                  <MenuButton
                    border="none"
                    _active={{
                      backgroundColor: 'unset'
                    }}
                    _focus={{
                      border: 'none'
                    }}
                    _hover={{
                      backgroundColor: 'unset'
                    }}
                    as={IconButton}
                    aria-label="Options"
                    icon={
                      <Avatar
                        w="40px"
                        h="40px"
                        name={
                          'gggame'
                          // profile.profile
                          //   ? profile.profile.tokensale_user_profile.display_name
                          //     ? profile.profile.tokensale_user_profile
                          //         .display_name
                          //     : 'A'
                          //   : 'A'
                        }
                      />
                    }
                    variant="outline"
                  />
                  <MenuList minWidth="150px">
                    {/* <MenuItem color={fontBaseColor}>
                    {userAddress.substring(0, 6) +
                      '...' +
                      userAddress.substring(38, 42)}
                  </MenuItem>
                  <MenuItem
                    color={fontBaseColor}
                    onClick={() => {
                      disconnectWallet()
                    }}
                  >
                    LOGOUT
                  </MenuItem> */}
                  </MenuList>
                </Menu>
                {/* <Avatar w="40px" h="40px" name="Phanuwit Kittirong" /> */}
              </HStack>
            ) : (
              <Button
                size="sm"
                // onClick={() => {
                //   handleConnectWallet()
                // }}
                borderColor="#53C7BE"
                color="#53C7BE"
                variant="outline"
                leftIcon={
                  <img
                    width="18px"
                    src="https://raw.githubusercontent.com/MetaMask/brand-resources/c3c894bb8c460a2e9f47c07f6ef32e234190a7aa/SVG/metamask-fox.svg"
                    alt=""
                  />
                }
                // style={{ cursor: 'pointer', marginRight: '10px' }}
              >
                Connect Wallet
              </Button>
            )}
          </Box>
        ) : null}
      </Box>
    </TabList>
  </Tabs>
)

const Navbars = ({ isOpen, variant, onClose }: Props) => {
  const location: any = useLocation()

  const [hideAvatar, setHideAvatar] = useState(false)

  useScrollPosition(({ prevPos, currPos }) => {
    console.log(currPos.x)
    console.log(currPos.y)
    if (currPos.y < -80) {
      setHideAvatar(true)
    } else {
      setHideAvatar(false)
    }
  })

  return variant === 'sidebar' ? (
    <Box
      left={0}
      // p={4}
      pt={0}
      px={20}
      w="100%"
      // top={0}
      h="100%"
      bg="#FFF"
      boxShadow="rgb(0 0 0 / 4%) 0px 7px 11px"
      position={isOpen ? 'unset' : 'sticky'}
      top={isOpen ? 'unset' : 0}
      zIndex="100"
      opacity="0.96"
      // borderWidth="1px"
      // borderRadius="lg"
    >
      {/* <Flex bg="#F7FAFC" p={4} color="white" justifyContent="center">
        <Center flex="1" h="40px">
          <GiTiredEye color="#50C7C2" fontSize={60} />
        </Center>
      </Flex>
      <Center>
        <Divider width="80%" size="20px" orientation="horizontal" />
      </Center>
      <Box p={4}>
        <SidebarContent
          location={location}
          setPageRender={setPageRender}
          onClick={() => onClose()}
        />
      </Box> */}
      <NavBarContent location={location} hideAvatar={hideAvatar} />
    </Box>
  ) : (
    <Drawer isOpen={isOpen} placement="left" onClose={() => onClose()}>
      <DrawerOverlay>
        <DrawerContent bg="#fff">
          <DrawerCloseButton />
          <DrawerHeader>Dashboard OPTIC TOKEN</DrawerHeader>
          <DrawerBody>
            <SidebarContent location={location} onClick={() => onClose()} />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

export default Navbars

const activeButton = {
  bg: '#fff',
  color: '#202733',
  transform: 'scale(0.98)',
  borderColor: '#bec3c9',
  boxShadow: 'rgb(0 0 0 / 4%) 0px 7px 11px'
}

const bgButton = '#F7FAFC'
const colorButton = '#A0AFBF'
const bgIcon = '#fff'
const bgIconActive = '#50C7C2'
const tabActive = {
  borderColor: '#50C7C2',
  color: '#202733',
  // transform: 'scale(0.98)',
  borderBottom: '2px solid #50C7C2'
}
