import { useEffect, useState, useMemo, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
// Types
import xxreStoreTypes from 'redux/types'

//Actions
import xxaConnectWallet from 'actions/ConnectWallet'
import xxaLogin from 'actions/Auth/Login'
import xxaToken from 'actions/Utils/Token'

// Components
import Profile from 'components/Account/Profile'
import AlertMessage from 'components/AlertMessage/AlertMessage'

import {
  Image,
  HStack,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  ModalContent,
  useDisclosure,
  Button,
  InputGroup,
  InputLeftElement,
  Box,
  Text,
  PinInput,
  PinInputField,
  RadioGroup,
  Radio,
  VStack,
  Stack,
  NumberInput,
  NumberInputField,
  Heading,
  Center,
  Alert,
  AlertIcon,
  ListItem,
  ListIcon,
  List
} from '@chakra-ui/react'

import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList
} from '@choc-ui/chakra-autocomplete'

//icon
import { BiCheckCircle, BiCircle } from 'react-icons/bi'

import xxaEmail from 'actions/Email'
import xxuValidation from 'utils/Validation'

//Web3
import Web3 from 'web3'

import MetamaskLogo from './Metamask'
import xxaProfile from 'actions/Profile'

let countDownResendLocal = 60

const Whitelist = (): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [countryOfResidence, setCountryOfResidence] = useState('')
  const [emailValidate, setEmailValidate] = useState<ReactElement | null>(null)
  const [dataCountry, setDataCountry] = useState([])
  const [clickSign, setClickSign] = useState(false)
  const [isShowAlertMessage, setIsShowAlertMessage] = useState(false)
  // const [newUser, setNewUser] = useState(true)

  //check list
  const [checkConnect, setCheckConnect] = useState(false)
  const [checkEmail, setCheckEmail] = useState(false)
  const [checkCountry, setCheckCountry] = useState(false)
  const [resendEmail, setResendEmail] = useState(false)
  const [countDownResend, setCountDownResend] = useState(60)
  const [sendEmail, setSendEmail] = useState(false)


  // const todo = useSelector((state: xxreStoreTypes.Props) => state.todo)
  const connectWallet = useSelector(
    (state: xxreStoreTypes.Props) => state.connectWallet
  )
  const connectWeb3 = useSelector((state: xxreStoreTypes.Props) => state.web3)
  const profile = useSelector((state: xxreStoreTypes.Props) => state.profile)
  const login = useSelector((state: xxreStoreTypes.Props) => state.login)
  const emailSend = useSelector(
    (state: xxreStoreTypes.Props) => state.emailSend
  )

  const updateProfile = useSelector(
    (state: xxreStoreTypes.Props) => state.updateProfile
  )

  let intervalHandle: NodeJS.Timeout

  const handleSignData = async () => {
    let emailValid = xxuValidation.Email(email)
    if (!emailValid) {
      return setEmailValidate(
        <Alert borderRadius="10px" mt="10px" status="error" size="sm">
          <AlertIcon />
          Invalid email format.
        </Alert>
      )
    }
    let checkLogin = xxaLogin.CheckTokenFromLocalStorage()
    if (connectWallet.connect?.address && !checkLogin) {
      let web3 = new Web3(window.ethereum)
      let timeStamp = new Date().getTime()
      // let signature = await web3.eth.personal.sign(
      //   timeStamp.toString(),
      //   connectWallet.connect?.address,
      //   'chomchob'
      // )
      let signature = await web3.eth.personal.sign(
        `Wallet Verification (${timeStamp.toString()})`,
        connectWallet.connect?.address,
        'chomchob'
      )
      console.log('timeStamp', timeStamp)
      console.log('signature', signature)

      if (timeStamp && signature) {
        setClickSign(false)
        dispatch(
          xxaLogin.Login(
            timeStamp.toString(),
            signature,
            connectWallet.connect?.address
          )
        )
      }
    } else {
      handleConnectWallet()
    }
  }

  // connect wallet
  const handleConnectWallet = async () => {
    let connect: any
    if (connectWeb3.web3) {
      connect = await dispatch(xxaConnectWallet.ConnectWallet(connectWeb3.web3))
    }
    console.log('connect', connect)
    if (connect.connectedStatus) {
    } else {
      // toaster.push(
      //   <Message showIcon type={'warning'}>
      //     {connect.status}
      //   </Message>,
      //   { placement: 'topCenter' }
      // )
    }
  }

  const handleUpdateProfile = () => {
    let emailValid = xxuValidation.Email(email)
    if (!emailValid) {
      return setEmailValidate(
        <Alert borderRadius="10px" mt="10px" status="error" size="sm">
          <AlertIcon />
          Invalid email format.
        </Alert>
      )
    }
    let answers = []
    if (countryOfResidence) {
      answers.push({
        question_short_name: 'country_of_residence',
        answer: countryOfResidence
      })
    }

    console.log('answers', answers)
    dispatch(xxaProfile.UpdateProfile(answers, email))
    // setSaveLoading(true)
  }

  const handleSendEmail = () => {
    let emailValid = xxuValidation.Email(email)
    console.log('emailValid', emailValid)
    if (emailValid) {
      dispatch(xxaEmail.EmailSend(email))
      console.log(email)
    } else {
      setEmailValidate(
        <Alert borderRadius="10px" mt="10px" status="error" size="sm">
          <AlertIcon />
          Invalid email format.
        </Alert>
      )
    }
  }

  const handleWhiteList = () => {
    if (connectWallet.connect?.connectedStatus) {
      let checkLogin = xxaLogin.CheckTokenFromLocalStorage()
      if (!checkLogin) {
        handleSignData()
      } else {
        handleUpdateProfile()
      }
    } else {
      setClickSign(true)
      handleConnectWallet()
    }
  }

  const startIntervalTask = () => {
    return (intervalHandle = setInterval(() => {
      countDownResendLocal = countDownResendLocal-1
      if (countDownResendLocal === 0) {
        stopIntervalTask()
        countDownResendLocal=60
        setCountDownResend(60)
        setResendEmail(false)
      } else {
        setCountDownResend(countDownResendLocal)
      }
    }, 1000))
  }
  const stopIntervalTask = () => {
    console.log('clearInterval')
    clearInterval(intervalHandle)
  }

  useMemo(() => {
    fetch('Json/country.json')
      .then(function (response) {
        console.log(response)
        return response.json()
      })
      .then(function (myJson) {
        setDataCountry(myJson)
      })
    dispatch(xxaLogin.Logout())
  }, [])

  useEffect(() => {
    if (connectWallet.connect?.connectedStatus === true && clickSign === true) {
      console.log('clickSign', clickSign)
      handleWhiteList()
    }
    if (connectWallet.connect?.connectedStatus === true) {
      setCheckConnect(true)
    } else if (connectWallet.connect?.connectedStatus === false) {
      setCheckConnect(false)
    }
    setSendEmail(false)
  }, [connectWallet])

  useEffect(() => {
    if (updateProfile.updateProfile?.msg === 'success') {
      // handleSendEmail()
      setEmailValidate(null)
      dispatch(xxaProfile.GetProfile())
    } else if (updateProfile.error) {
    }
  }, [updateProfile])

  useEffect(() => {
    if (login.login) {
      // handleUpdateProfile()
      if (!login.login.new_user) {
        history.push('/countdown')
      } else {
        handleUpdateProfile()
      }
    } else if (login.error) {
      console.log(login.error.msg)
    }
  }, [login])

  useEffect(() => {
    if (profile.profile) {
      if (login.login?.new_user === true && profile.profile.tokensale_user_profile.email) {
        handleSendEmail()
      }
      // setEmail(profile.profile.tokensale_user_profile.email ?? '')
      // setCountryOfResidence(
      //   profile.profile.tokensale_user_profile.country_of_residence
      //     ? profile.profile.tokensale_user_profile.country_of_residence
      //     : ''
      // )
    } else if (profile.error) {
      console.log(profile.error.msg)
    }
  }, [profile])

  useEffect(() => {
    if (emailSend.emailSend?.msg === 'success') {
      dispatch(xxaEmail.ClearEmailSend())
      setResendEmail(true)
      setSendEmail(true)
      startIntervalTask()
      setIsShowAlertMessage(true)
    } else if (emailSend.error) {
      setEmailValidate(
        <Alert borderRadius="10px" mt="10px" status="error" size="sm">
          <AlertIcon />
          Email is already exists.
        </Alert>
      )
      console.log(emailSend.error.msg)
    }
  }, [emailSend])

  useEffect(() => {
    if (email) {
      let emailValid = xxuValidation.Email(email)
      console.log('emailValid', emailValid)
      if (emailValid) {
        setCheckEmail(true)
      } else {
        setCheckEmail(false)
      }
    }
  }, [email])

  useEffect(() => {
    if (countryOfResidence) {
      setCheckCountry(true)
    } else {
      setCheckCountry(false)
    }
  }, [countryOfResidence])

  // useMemo(() => {
  //   handleConnectWallet()
  // }, [])

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100vh - 180px)',
        alignItems: 'center'
      }}
    >
      {/* {profile.profile ? ( */}
      <Stack __css={layerStyles}>
        <Center>
          <Heading size="lg" color="#575757">
            Join the Whitelist
          </Heading>
        </Center>

        <Box mt="20px">
          <FormControl mt="1" id="first-name">
            {/* <List>
            <ListItem>
              <ListIcon as={BiCheckCircle} color="green.500" />
              Please connect wallet
            </ListItem>
            </List> */}

            <FormLabel>
              <HStack>
                {checkConnect ? (
                  <BiCheckCircle color="#02D284" fontSize="20px" />
                ) : (
                  <BiCircle fontSize="20px" color="#575757" />
                )}
                <Text color="#575757">Please connect wallet</Text>
              </HStack>
            </FormLabel>
            <Center>
              {checkConnect ? (
                connectWallet.connect?.address
              ) : (
                <Button
                  size="sm"
                  onClick={() => {
                    handleConnectWallet()
                  }}
                  borderColor="#53C7BE"
                  color="#53C7BE"
                  variant="outline"
                  leftIcon={
                    <img
                      width="18px"
                      src="https://raw.githubusercontent.com/MetaMask/brand-resources/c3c894bb8c460a2e9f47c07f6ef32e234190a7aa/SVG/metamask-fox.svg"
                      alt=""
                    />
                  }
                  // style={{ cursor: 'pointer', marginRight: '10px' }}
                >
                  Connect Wallet
                </Button>
              )}
            </Center>
          </FormControl>
          <FormControl mt="15px" id="first-name">
            <FormLabel>
              <HStack>
                {checkEmail ? (
                  <BiCheckCircle color="#02D284" fontSize="20px" />
                ) : (
                  <BiCircle fontSize="20px" color="#575757" />
                )}
                <Text color="#575757">Please enter email</Text>
              </HStack>
            </FormLabel>
            {/* <FormLabel>Email</FormLabel> */}
            <Input
              // isReadOnly={verifyEmail}
              value={email}
              onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(value.target.value)
                setEmailValidate(null)
              }}
              placeholder="Your Email"
            />
          </FormControl>
          <FormControl mt="15px" id="first-name">
            {/* <FormLabel>Country</FormLabel> */}
            <FormLabel>
              <HStack>
                {checkCountry ? (
                  <BiCheckCircle color="#02D284" fontSize="20px" />
                ) : (
                  <BiCircle fontSize="20px" color="#575757" />
                )}
                <Text color="#575757">Please enter country</Text>
              </HStack>
            </FormLabel>
            <AutoComplete
              closeOnselect
              // onSelectOption={changes =>
              //   setCountryOfResidence(changes.optionValue)
              // }
              onChange={e => {
                setCountryOfResidence(e)
              }}
              rollNavigation
            >
              <AutoCompleteInput
                // variant="filled"
                placeholder="Your Country"
                value={countryOfResidence}
              />
              <AutoCompleteList mt="5px" maxH="320px">
                {dataCountry.map((item, oid) => (
                  <AutoCompleteItem
                    key={`option-${oid}`}
                    value={item}
                    textTransform="capitalize"
                  >
                    {item}
                  </AutoCompleteItem>
                ))}
              </AutoCompleteList>
            </AutoComplete>
          </FormControl>
          {/* {connectWallet.connect?.connectedStatus ? (
            <FormControl mt="10px" id="first-name">
              <FormLabel>Wallet Address</FormLabel>
              <Input
                isReadOnly={true}
                value="0xA382F05A0242F3f61c37C2560C6002C2FA7a9f65"
                placeholder="Your Nationality"
              />
            </FormControl>
          ) : null} */}
          {/* { ? (
            
          ) : null} */}
          {emailValidate}

          <Center mt="4">
            {sendEmail ? (
              <Button
                disabled={
                  checkConnect && checkCountry && checkEmail && !resendEmail
                    ? false
                    : true
                }
                bg="#53c8bf"
                onClick={() => {
                  handleSendEmail()
                }}
                colorScheme="teal"
                color="#fff"
              >
                Resend Email {resendEmail ? `(${countDownResend}s)` : null}
              </Button>
            ) : (
              <Button
                disabled={
                  checkConnect && checkCountry && checkEmail ? false : true
                }
                bg="#53c8bf"
                onClick={() => {
                  // setClickSign(true)
                  // handleUpdateProfile()
                  // setIsShowAlertMessage(true)
                  handleWhiteList()
                }}
                colorScheme="teal"
                color="#fff"
              >
                Register in the whitelist
              </Button>
            )}
          </Center>
        </Box>
      </Stack>
      {/* ) : (
        <Stack>
          <Center>
            <Stack>
              <MetamaskLogo />
              <Center>
                <Button
                  w="100px"
                  bg="#53c8bf"
                  onClick={() => {
                    handleSignData()
                    setClickSign(true)
                  }}
                  colorScheme="teal"
                  color="#fff"
                >
                  Sign
                </Button>
              </Center>
            </Stack>
          </Center>
        </Stack>
      )} */}
      <AlertMessage
        type="success"
        title="Success"
        message="You're almost there! We sent an email to big.phanuwit@gmail.com, please check your email."
        isOpen={isShowAlertMessage}
        onClose={() => setIsShowAlertMessage(false)}
        // onConfirm={()=>{console.log('object')}}
        footerPosition="center"
      />
    </Box>
  )
}

const layerStyles = {
  height: 'auto',
  width: '380px',
  bg: 'gray.50',
  borderRadius: '16px',
  padding: '16px',
  display: 'flex',
  // justifyContent:'center',
  // alignItems:'center',
  boxShadow: 'rgb(0 0 0 / 4%) 0px 7px 11px'
}

export default Whitelist

const options = ['apple', 'appoint', 'zap', 'cap', 'japan']
