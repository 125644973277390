import axios from 'axios'
import xxsGetHistoryServiceTypes from 'services/History/types'
import xxaDevice from 'actions/Utils/Device'
import { servicePath } from 'configs'

import xxaInitial from 'actions/Initial'

const xxsHistoryService = {
  GetHistory:
    async (): Promise<xxsGetHistoryServiceTypes.GetTransactionResponse> => {
      let response: xxsGetHistoryServiceTypes.GetTransactionResponse
      const token = await xxaInitial.Check()

      try {
        const responseData =
          await axios.get<xxsGetHistoryServiceTypes.GetTransactionSuccess>(
            `${servicePath.service.general}/v1/payment/history`,
            {
              headers: {
                Authorization: token,
                'device-Id': await xxaDevice.GetDeviceId()
              }
            }
          )

        response = {
          status: responseData.status,
          history: responseData.data
        }
      } catch (error: any) {
        const err = error.toJSON()
        if (err.message === 'Network Error') {
          response = {
            status: 500,
            error: err
          }
        } else {
          response = {
            status: error.response.status,
            error: error.response.data
          }
        }
      }
      return response
    }
}

export default xxsHistoryService
