import { Helmet } from 'react-helmet'
// import Cookies from 'js-cookie'

// Components
import FullLayout from 'layouts/Full'
import MainLayout from 'layouts/Main'
import DashboardPage from 'layouts/DashboardLayout'
import TopHeaderLayout from 'layouts/TopHeaderLayout'
import LoadingPage from 'components/LoadingPage'

import xxlCoreLayoutProps from 'layouts/types'
import xxaWeb3 from 'actions/Web3'
import xxaAuth from 'actions/Auth/AnonymousToken'

import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import xxaLogin from 'actions/Auth/Login'

const CoreLayout = ({
  isAuth,
  layout,
  children
}: xxlCoreLayoutProps.PropTypes) => {
  const dispatch = useDispatch()
  let layoutComponent = <LoadingPage />

  useMemo(() => {
    dispatch(xxaWeb3.ConnectWeb3())
    dispatch(xxaAuth.GetAnonymousToken())
    console.log('ConnectWeb3')
  }, [])
  // route require authentication
  if (isAuth) {
    const userAuth = xxaLogin.CheckTokenFromLocalStorage()
    // check user authentication (Login)
    if (userAuth) {
      if (layout === 'dashboard') {
        layoutComponent = <DashboardPage>{children}</DashboardPage>
      } else if (layout === 'full') {
        layoutComponent = <FullLayout>{children}</FullLayout>
      } else if (layout === 'top') {
        layoutComponent = <TopHeaderLayout>{children}</TopHeaderLayout>
      } else {
        layoutComponent = children
      }
    } else {
      window.location.replace('/whitelist')
    }
  } else if (layout === 'dashboard') {
    layoutComponent = <DashboardPage>{children}</DashboardPage>
  } else if (layout === 'full') {
    layoutComponent = <FullLayout>{children}</FullLayout>
  } else if (layout === 'top') {
    layoutComponent = <TopHeaderLayout>{children}</TopHeaderLayout>
  }else{
    layoutComponent = children
  }

  // if (layout === 'dashboard') {
  //   layoutComponent = <DashboardPage>{children}</DashboardPage>
  // } else {
  //   layoutComponent = <MainLayout>{children}</MainLayout>
  // }

  return (
    <div className="container-layout">
      <Helmet>
        <title>OPTIC TOKEN</title>
      </Helmet>
      <div>{layoutComponent}</div>
    </div>
  )
}

export default CoreLayout
