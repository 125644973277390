import axios from 'axios'
import xxsBindingServiceTypes from 'services/Binding/types'
import xxaDevice from 'actions/Utils/Device'
import { servicePath } from 'configs'

import xxaInitial from 'actions/Initial'

const xxsBindingService = {
  GetBinding: async (
    mobileNumber: string
  ): Promise<xxsBindingServiceTypes.GetBindingResponse> => {
    let response: xxsBindingServiceTypes.GetBindingResponse
    const token = await xxaInitial.Check()
    let data = {
      mobile_number: mobileNumber
    }
    try {
      const responseData =
        await axios.post<xxsBindingServiceTypes.GetBindingSuccess>(
          `${servicePath.service.general}/v1/user/connect/chomchob`,
          data,
          {
            headers: {
              Authorization: token,
              'device-Id': await xxaDevice.GetDeviceId()
            }
          }
        )

      response = {
        status: responseData.status,
        getBinding: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  VerifyBinding: async (
    mobileNumber: string,
    otp: string,
    otpRefCode: string
  ): Promise<xxsBindingServiceTypes.VerifyBindingResponse> => {
    let response: xxsBindingServiceTypes.VerifyBindingResponse
    const token = await xxaInitial.Check()
    
    let data = {
      mobile_number: mobileNumber,
      otp: otp,
      otp_ref_code: otpRefCode
    }

    try {
      const responseData =
        await axios.post<xxsBindingServiceTypes.VerifyBindingSuccess>(
          `${servicePath.service.general}/v1/user/verify/chomchob-user`,
          data,
          {
            headers: {
              Authorization: token,
              'device-Id': await xxaDevice.GetDeviceId()
            }
          }
        )

      response = {
        status: responseData.status,
        verifyBinding: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  }
}

export default xxsBindingService
