import axios from 'axios'
import xxsCCPPaymentServiceTypes from 'services/CCPPayment/types'
import xxaDevice from 'actions/Utils/Device'
import { servicePath } from 'configs'

import xxaInitial from 'actions/Initial'

const xxsCCPPaymentService = {
  RequestPayment: async (
    mobileNumber: string,
    paymentAmount: number,
    ccpPaymentAmount:number
  ): Promise<xxsCCPPaymentServiceTypes.RequestPaymentResponse> => {
    let response: xxsCCPPaymentServiceTypes.RequestPaymentResponse
    const token = await xxaInitial.Check()

    const data = {
      mobile_number: mobileNumber.toString(),
      payment_amount: paymentAmount.toString(),
      ccp_payment_amount:ccpPaymentAmount.toString(),
      callback_url: `${servicePath.base_url}/tokensale`
    }

    try {
      const responseData =
        await axios.post<xxsCCPPaymentServiceTypes.RequestPaymentSuccess>(
          `${servicePath.service.general}/v1/payment/ccp/request`,
          data,
          {
            headers: {
              Authorization: token,
              'device-Id': await xxaDevice.GetDeviceId()
            }
          }
        )

      response = {
        status: responseData.status,
        requestPayment: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  VerifyBalance: async (
    tokensalePaymentTid: string,
    mobileNumber: string,
    otp: number,
    otpRefCode: string
  ): Promise<xxsCCPPaymentServiceTypes.VerifyBalanceResponse> => {
    let response: xxsCCPPaymentServiceTypes.VerifyBalanceResponse
    const token = await xxaInitial.Check()

    const data = {
      tokensale_payment_tid: tokensalePaymentTid,
      mobile_number: mobileNumber.toString(),
      otp: otp.toString(),
      otp_ref_code: otpRefCode
    }

    try {
      const responseData =
        await axios.post<xxsCCPPaymentServiceTypes.VerifyBalanceSuccess>(
          `${servicePath.service.general}/v1/payment/ccp/verify/balance`,
          data,
          {
            headers: {
              Authorization: token,
              'device-Id': await xxaDevice.GetDeviceId()
            }
          }
        )

      response = {
        status: responseData.status,
        verifyBalance: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  VerifyPayment: async (
    tokensalePaymentTid: string,
    ccp: number,
  ): Promise<xxsCCPPaymentServiceTypes.VerifyPaymentResponse> => {
    let response: xxsCCPPaymentServiceTypes.VerifyPaymentResponse
    const token = await xxaInitial.Check()

    const data = {
      tokensale_payment_tid: tokensalePaymentTid,
      ccp: ccp.toString(),
    }

    try {
      const responseData =
        await axios.post<xxsCCPPaymentServiceTypes.VerifyPaymentSuccess>(
          `${servicePath.service.general}/v1/payment/ccp/verify`,
          data,
          {
            headers: {
              Authorization: token,
              'device-Id': await xxaDevice.GetDeviceId()
            }
          }
        )

      response = {
        status: responseData.status,
        verifyPayment: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  ConfirmPayment: async (
    tokensalePaymentTid: string,
  ): Promise<xxsCCPPaymentServiceTypes.ConfirmPaymentResponse> => {
    let response: xxsCCPPaymentServiceTypes.ConfirmPaymentResponse
    const token = await xxaInitial.Check()

    const data = {
      tokensale_payment_tid: tokensalePaymentTid,
      callback_url: `${servicePath.base_url}/tokensale`
    }

    try {
      const responseData =
        await axios.post<xxsCCPPaymentServiceTypes.ConfirmPaymentSuccess>(
          `${servicePath.service.general}/v1/payment/ccp/confirm`,
          data,
          {
            headers: {
              Authorization: token,
              'device-Id': await xxaDevice.GetDeviceId()
            }
          }
        )

      response = {
        status: responseData.status,
        confirmPayment: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  }
}

export default xxsCCPPaymentService
