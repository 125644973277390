import { Dispatch } from 'redux'

// Redux Constants
import {
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  GET_PROFILE_CLEAR_MESSAGE
} from 'redux/Constants/Profile/Get'
import {
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_CLEAR_MESSAGE
} from 'redux/Constants/Profile/Update'

// Utils
import xxuResponse from 'utils/Response'

//service
import xxsProfileService from 'services/Profile'

const xxaProfile = {
  GetProfile: () => async (dispatch: Dispatch) => {
    // Clear data of example in Redux
    // dispatch({
    //   type: GET_PROFILE_CLEAR_MESSAGE
    // })
    const response = await xxsProfileService.GetProfile()
    if (response.status === 200) {
      xxuResponse.Success({
        type: GET_PROFILE_SUCCESS,
        dispatch,
        payload: response
      })
    } else {
      xxuResponse.Error({
        type: GET_PROFILE_FAIL,
        errorPage: true,
        dispatch,
        error: response.error
      })
    }
  },
  ClearProfile: () => async (dispatch: Dispatch) => {
    dispatch({
      type: GET_PROFILE_CLEAR_MESSAGE
    })
  },
  ClearUpdateProfile: () => async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_PROFILE_CLEAR_MESSAGE
    })
  },
  UpdateProfile:
    (answers: { question_short_name: string; answer: any }[],email:string) =>
    async (dispatch: Dispatch) => {
      // Clear data of example in Redux
      dispatch({
        type: UPDATE_PROFILE_CLEAR_MESSAGE
      })
      const response = await xxsProfileService.UpdateProfile(answers,email)
      if (response.status === 200) {
        xxuResponse.Success({
          type: UPDATE_PROFILE_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: UPDATE_PROFILE_FAIL,
          errorPage: true,
          dispatch,
          error: response.error
        })
      }
    }
}

export default xxaProfile
