// Constants
import {
  GET_ANONYMOUS_SUCCESS,
  GET_ANONYMOUS_FAIL,
  GET_ANONYMOUS_CLEAR_MESSAGES
} from 'redux/Constants/Auth/AnonymousToken'

// Props Types
import IAction from 'redux/Action/types'
import xxreAuthAnonymousTokenReducerTypes from 'redux/Reducers/Auth/AnonymousToken/types'

const anonymousToken = (
  state: object = {},
  action: IAction<xxreAuthAnonymousTokenReducerTypes.Props>
) => {
  switch (action.type) {
    case GET_ANONYMOUS_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case GET_ANONYMOUS_FAIL:
      return {
        ...state,
        ...action.payload
      }
    case GET_ANONYMOUS_CLEAR_MESSAGES:
      return {}
    default:
      return state
  }
}

export default anonymousToken
