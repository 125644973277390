import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Types
import xxreStoreTypes from 'redux/types'

//Actions
import xxaConnectWallet from 'actions/ConnectWallet'
import xxaLogin from 'actions/Auth/Login'
import xxaToken from 'actions/Utils/Token'

// Components
import Profile from 'components/Account/Profile'

//Web3
import Web3 from 'web3'

import { Button, Message, toaster } from 'rsuite'

const Account = (): JSX.Element => {
  const dispatch = useDispatch()
  // const todo = useSelector((state: xxreStoreTypes.Props) => state.todo)
  const connectWallet = useSelector(
    (state: xxreStoreTypes.Props) => state.connectWallet
  )
  const connectWeb3 = useSelector((state: xxreStoreTypes.Props) => state.web3)
  const loginRedux = useSelector((state: xxreStoreTypes.Props) => state.login)

  const [clickSign, setClickSign] = useState(false)
  const [userAddress, setUserAddress] = useState('')
  const [loginStatus, setLoginStatus] = useState(false)

  // connect wallet
  const handleConnectWallet = async () => {
    let connect: any
    if (connectWeb3.web3) {
      connect = await dispatch(xxaConnectWallet.ConnectWallet(connectWeb3.web3))
    }
    console.log('connect', connect)
    if (connect.connectedStatus) {
    } else {
      toaster.push(
        <Message showIcon type={'warning'}>
          {connect.status}
        </Message>,
        { placement: 'topCenter' }
      )
    }
  }

  // const checkConnectWalleted = async () => {
  //   let check = await xxaConnectWallet.CheckConnectWallet()
  //   let connect = window.localStorage.getItem('metamask')
  //   if (check && connect) {
  //     handleConnectWallet()
  //   }
  // }

  const handleSignData = async () => {
    let checkLogin = xxaLogin.CheckTokenFromLocalStorage()
    if (userAddress && !checkLogin) {
      let web3 = new Web3(window.ethereum)
      let timeStamp = new Date().getTime()
      let signature = await web3.eth.personal.sign(
        timeStamp.toString(),
        userAddress.toString(),
        'chomchob'
      )
      console.log('timeStamp', timeStamp)
      console.log('signature', signature)

      if (timeStamp && signature) {
        setClickSign(false)
        dispatch(xxaLogin.Login(timeStamp.toString(), signature, userAddress))
      }
    } else {
      handleConnectWallet()
    }
  }

  useEffect(() => {
    if (connectWallet.connect?.connectedStatus) {
      setUserAddress(connectWallet.connect?.address)
    } else {
      setUserAddress('')
    }
  }, [connectWallet])

  useEffect(() => {
    if (userAddress && clickSign) {
      handleSignData()
    }
  }, [userAddress])

  useMemo(async () => {
    if (connectWallet.connect?.connectedStatus) {
      let check = xxaLogin.CheckTokenFromLocalStorage()
      if (check) {
        setLoginStatus(true)
        //call profile
        // dispatch(xaProfile.GetProfile())
      } else {
        setLoginStatus(false)
      }
    } else {
      setLoginStatus(false)
    }
  }, [connectWallet])

  useEffect(() => {
    if (loginRedux.login?.token) {
      setLoginStatus(true)
      //call profile
    } else {
      setLoginStatus(false)
    }
  }, [loginRedux])

  return (
    <div>
      {loginStatus ? (
        <Profile />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 180px)',
            flexDirection: 'column'
          }}
        >
          <img
            width="140px"
            src="https://raw.githubusercontent.com/MetaMask/brand-resources/c3c894bb8c460a2e9f47c07f6ef32e234190a7aa/SVG/metamask-fox.svg"
            alt=""
          />
          <Button
            onClick={() => {
              handleSignData()
              setClickSign(true)
            }}
            style={{ marginTop: '10px' }}
            color="blue"
            appearance="primary"
          >
            Sign In
          </Button>
        </div>
      )}
    </div>
  )
}

export default Account
