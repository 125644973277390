// Constants
import {
  GET_BINDING_CLEAR_MESSAGE,
  GET_BINDING_FAIL,
  GET_BINDING_SUCCESS
} from 'redux/Constants/Binding/Get'

// Props Types
import IAction from 'redux/Action/types'
import xxsBindingServiceTypes from 'redux/Reducers/Binding/Get/types'

const getBinding = (
  state: object = {},
  action: IAction<xxsBindingServiceTypes.Props>
) => {
  switch (action.type) {
    case GET_BINDING_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case GET_BINDING_FAIL:
      return {
        ...state,
        ...action.payload
      }
    case GET_BINDING_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default getBinding
