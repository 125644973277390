// Interfaces
import xxroRouterProps from 'routers/types'

// Views
// import DashboardPage from 'views/Dashboard'
import Dashboard from 'components/Pages/Dashboard'
import Profile from 'components/Pages/Profile'
import Transaction from 'components/Pages/Transaction'
import BuyTokens from 'components/Pages/BuyTokens'



const xxroDashboardRouter: xxroRouterProps.PropTypes[] = [
  { path: '/dashboard', exact: true, isAuth: false, layout: 'dashboard', component: Dashboard },
  { path: '/dashboard/profile', exact: true, isAuth: false, layout: 'dashboard', component: Profile },
  { path: '/dashboard/transactions', exact: true, isAuth: false, layout: 'dashboard', component: Transaction },
  { path: '/dashboard/buyTokens', exact: true, isAuth: false, layout: 'dashboard', component: BuyTokens },


]

export default xxroDashboardRouter
