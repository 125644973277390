import xxuWeb3Props from 'utils/Web3/types'

const xxuWeb3 = {
  GetWeb3: (): xxuWeb3Props.Web3 => {
    if (process.env.REACT_APP_RUN_ON === 'production') {
      return {
        rpc: 'https://data-seed-prebsc-1-s2.binance.org:8545/',
        netID: 97
      }
    } else {
      return {
        // rpc: 'http://127.0.0.1:8545/',
        // netID: 31337
        rpc: 'https://data-seed-prebsc-1-s2.binance.org:8545/',
        netID: 97
      }
    }
  }
}

export default xxuWeb3
