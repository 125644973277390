import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
// Utils
import xxuLanguage from 'utils/Language'

// Assets
// import nightPic from 'assets/Images/night.png'

// Types
import xxreStoreTypes from 'redux/types'

//Actions
import xxaConnectWallet from 'actions/ConnectWallet'
import xxaContract from 'actions/Contract'
import xxaPayment from 'actions/Payment'

//Web3
import { Contract } from 'web3-eth-contract'

//Utils
import xxuContract from 'utils/Contract'

//Style
import 'styles/views/tokenSale.scss'
import {
  SelectPicker,
  InputNumber,
  Message,
  toaster,
  Placeholder
  // RadioGroup,
  // Radio
} from 'rsuite'

import {
  Image,
  HStack,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  ModalContent,
  useDisclosure,
  Button,
  InputGroup,
  InputLeftElement,
  Box,
  Text,
  PinInput,
  PinInputField,
  RadioGroup,
  Radio,
  VStack,
  Stack,
  NumberInput,
  NumberInputField,
  Alert,
  AlertIcon,
  Center,
  NumberInputStepper,
  NumberIncrementStepper
} from '@chakra-ui/react'

import { StlViewer } from 'react-stl-viewer'

import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import xxaRateConfig from 'actions/RateConfig'
import xxaClaim from 'actions/ClaimCalculate'
import { RiQrCodeLine, RiPhoneFill } from 'react-icons/ri'
import xxaCCPPayment from 'actions/CCPPayment'
import xxcTokenSaleTypes from 'views/TokenSale/tokenSale'
import * as _ from 'lodash'
import xxaBinding from 'actions/Binding'
import xxaProfile from 'actions/Profile'

// import xxuWeb3 from 'utils/Web3'
type QuizParams = {
  ref: string
}

let chuckCurrent: number = 0

const TokenSale = (): JSX.Element => {
  // const web3 = new Web3(
  //   window.ethereum || new Web3.providers.HttpProvider(xxuWeb3.rpc)
  // )
  const dispatch = useDispatch()
  const { t } = useTranslation(['common'])
  const contractSale: Contract | null = xxaContract.ContractToken('sale') // contract sale
  const contractCUsd: Contract | null = xxaContract.ContractToken('cUSD') // contract CUSD
  const contractMoonToken: Contract | null = xxaContract.ContractToken('MOON') // contract Moon
  const contractBTC: Contract | null = xxaContract.ContractToken('BTC') // contract Moon
  const contractETH: Contract | null = xxaContract.ContractToken('ETH') // contract Moon
  const contractBUSD: Contract | null = xxaContract.ContractToken('BUSD') // contract Moon
  const contractUSDT: Contract | null = xxaContract.ContractToken('USDT') // contract Moon

  const { ref } = useParams<QuizParams>()
  let intervalHandle: NodeJS.Timeout

  const connectWallet = useSelector(
    (state: xxreStoreTypes.Props) => state.connectWallet
  )
  const connectWeb3 = useSelector((state: xxreStoreTypes.Props) => state.web3)
  const payment = useSelector((state: xxreStoreTypes.Props) => state.payment)
  const profile = useSelector((state: xxreStoreTypes.Props) => state.profile)
  const rateConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.rateConfig
  )

  //ccpPayment
  const requestPayment = useSelector(
    (state: xxreStoreTypes.Props) => state.requestPayment
  )
  const verifyBalance = useSelector(
    (state: xxreStoreTypes.Props) => state.verifyBalance
  )
  const verifyPayment = useSelector(
    (state: xxreStoreTypes.Props) => state.verifyPayment
  )
  const confirmPayment = useSelector(
    (state: xxreStoreTypes.Props) => state.confirmPayment
  )
  const getBinding = useSelector(
    (state: xxreStoreTypes.Props) => state.getBinding
  )
  const verifyBinding = useSelector(
    (state: xxreStoreTypes.Props) => state.verifyBinding
  )

  const [userAddress, setUserAddress] = useState('')
  const [icoTime, setIcoTime] = useState({ start: 0, end: 0 }) //time start and end ico
  const [funGoal, setFunGoal] = useState(0) // amount token will sale
  const [tokenRaised, setTokenRaised] = useState(0) // amount token sold
  const [useToken, setUseToken] = useState('BNB') // token use pay
  const [useTokenBalance, setUseTokenBalance] = useState(0) // balance token use pay
  const [approveCUsd, setApproveCUsd] = useState(false) // approve CUSD
  const [amountTokenPay, setAmountTokenPay] = useState(0) // amount token with pay
  const [amountFiatPay, setAmountFiatPay] = useState(0) // amount token with pay
  const [moonWillGet, setMoonWillGet] = useState(0) // token will get
  const [moonBalance, setMoonBalance] = useState(0) // moon balance user
  const [lockUserToken, setLockUserToken] = useState(0) // moon balance user
  const [lockStart, setLockStart] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]) // lock start date
  // const [chuckCurrent, setChuckCurrent] = useState(0) // lock start date

  //rate buy token to moonToken
  const [tokenRateTokenBuy, setTokenRateTokenBuy] = useState(0)
  //rate buy BNB to moonToken
  const [tokenRteEth, setTokenRteEth] = useState(0)
  //rate buy THB to moonToken
  const [tokenRteTHB, setTokenRteTHB] = useState(1)

  const [configTokenRate, setConfigTokenRate] = useState<
    xxcTokenSaleTypes.RateConfig | any
  >() // moon balance user

  const [moonWillGetTHB, setMoonWillGetTHB] = useState(0) // token will get
  const [moonLockRate, setMoonLockRate] = useState(0) // rate token lock

  //BlockNumber
  const [blockNUmber, setBlockNUmber] = useState(0) // BlockNumber
  //loading
  const [buyLoad, setBuyLoad] = useState(false)
  const [connectLoad, setConnectLoad] = useState(false)
  const [approveLoad, setApproveLoad] = useState(false)
  const [claimLoad, setClaimLoad] = useState(false)
  const [claimEnable, setClaimEnable] = useState(true)
  const [typeBuy, setTypeBuy] = useState('credit_card')
  const [otp, setOtp] = useState<any>('')
  const [mobilePhone, setMobilePhone] = useState<string>('')
  const [otpRef, setOtpRef] = useState<string>('')
  const [paymentTid, setPaymentTid] = useState<string>('')
  const [yourCCP, setYourCCP] = useState<number>(0)
  const [payCCP, setPayCCP] = useState<string>('0')
  const [ccpPayStep, setccpPayStep] = useState<number>(3)
  const [ccpBalance, setCcpBalance] = useState<number>(0)
  const [ccpPay, setCcpPay] = useState<number>(0)
  const [gatewayPay, setGatewayPay] = useState<number>(0)
  const [otpError, setOtpError] = useState<boolean>(false)
  const [bindingSuccess, setBindingSuccess] = useState<boolean>(false)
  const [limitEnable, setLimitEnable] = useState<boolean>(false)
  const [limitValue, setLimitValue] = useState<number>(0)
  const [limitReceiveBalance, setLimitReceiveBalance] = useState<number>(0)

  const [isWhitelistPeriod, setIsWhitelistPeriod] = useState<boolean>(true)
  const [endSale, setEndSale] = useState<boolean>(true)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialRef = React.useRef()
  const finalRef = React.useRef()

  // get time start and end ico
  const getIcoTime = async () => {
    if (contractSale) {
      let time = {
        start: await contractSale.methods.icoStartTime().call(),
        end: await contractSale.methods.icoEndTime().call()
      }
      setIcoTime(time)
      let timeStamp = Math.ceil(new Date().getTime() / 1000)
      if (time.end - timeStamp > 0) {
        setEndSale(false)
      } else {
        setEndSale(true)
      }
    }
  }

  // get funding gaol to sale token
  const getFundingGoal = async () => {
    let web3 = connectWeb3.web3
    if (contractSale && web3) {
      let funding = await contractSale.methods.fundingGoal().call()
      let raise = await contractSale.methods.tokenRaised().call()
      console.log('funding', funding)
      console.log('raise', raise)

      setTokenRaised(parseFloat(await web3.utils.fromWei(raise)))
      setFunGoal(parseFloat(await web3.utils.fromWei(funding)))
    }
  }
  // get token lock user
  const getLockUserToken = async () => {
    let web3 = connectWeb3.web3
    if (contractSale && web3) {
      let token = await contractSale.methods
        .lockUserToken(connectWallet.connect?.address)
        .call()
      setLockUserToken(
        parseFloat(await web3.utils.fromWei(token.currectLockToken))
      )
      chuckCurrent = parseInt(token.chunkClaimed)
      console.log(
        'currectLockToken',
        parseFloat(await web3.utils.fromWei(token.currectLockToken))
      )
      console.log('token', token)
    }
  }

  const getLimitTokenPerUser = async () => {
    let web3 = connectWeb3.web3
    if (contractSale && web3) {
      // let limit: boolean = await contractSale.methods.checkLimitPerUser().call()
      // let limitToken = await contractSale.methods.limitTokenReceivePerUser().call()
      let limit: boolean = true
      let limitToken = '3000000000000000000000000'

      let tokenCanBuy = await contractSale.methods
        .userTokenReceiveBalance(connectWallet.connect?.address)
        .call()
      setLimitEnable(limit)
      console.log('limitToken', limitToken)
      setLimitValue(parseFloat(await web3.utils.fromWei(limitToken)))
      setLimitReceiveBalance(
        parseFloat(await web3.utils.fromWei(limitToken)) -
          parseFloat(await web3.utils.fromWei(tokenCanBuy))
      )
    }
  }
  const getLockStartLock = async () => {
    let web3 = connectWeb3.web3
    if (contractSale && web3) {
      console.log('contractSale', contractSale)
      let time: number = parseInt(
        await contractSale.methods.unlockStartTime().call()
      )
      console.log('time', time)
      let chunkTime: number = parseInt(
        await contractSale.methods.timePerChunk().call()
      )

      let chunkArray = xxaClaim.TimeClaim(time, chunkTime)
      setLockStart(chunkArray)
      console.log('chunkArray', chunkArray)

      for (let i = 0; i < 10; i++) {
        console.log(
          'round ' + (i + 1),
          dayjs(chunkArray[i] * 1000).format('DD/MM/YYYY HH:mm:ss')
        )
      }
      // setLockUserToken(parseFloat(await web3.utils.fromWei(token)))
    }
  }
  // get balance of user token
  const getBalanceOfUserToken = async () => {
    let web3 = connectWeb3.web3
    if (useToken === 'BNB') {
      let balance = await web3.eth.getBalance(userAddress)
      setUseTokenBalance(parseFloat(await web3.utils.fromWei(balance)))
    } else if (useToken === 'cUSD' && contractCUsd) {
      let balance = await contractCUsd.methods.balanceOf(userAddress).call()
      setUseTokenBalance(parseFloat(await web3.utils.fromWei(balance)))
      // console.log('asdasdad', balance)
    } else if (useToken === 'ETH' && contractETH) {
      let balance = await contractETH.methods.balanceOf(userAddress).call()
      setUseTokenBalance(parseFloat(await web3.utils.fromWei(balance)))
      // console.log('asdasdad', balance)
    } else if (useToken === 'BTC' && contractBTC) {
      let balance = await contractBTC.methods.balanceOf(userAddress).call()
      setUseTokenBalance(parseFloat(await web3.utils.fromWei(balance)))
      // console.log('asdasdad', balance)
    } else if (useToken === 'BUSD' && contractBUSD) {
      let balance = await contractBUSD.methods.balanceOf(userAddress).call()
      setUseTokenBalance(parseFloat(await web3.utils.fromWei(balance)))
      // console.log('asdasdad', balance)
    } else if (useToken === 'USDT' && contractUSDT) {
      let balance = await contractUSDT.methods.balanceOf(userAddress).call()
      setUseTokenBalance(parseFloat(await web3.utils.fromWei(balance)))
      // console.log('asdasdad', balance)
    } else {
      setUseTokenBalance(0)
    }
  }
  // get moon balance of
  const getMoonBalanceOf = async () => {
    let web3 = connectWeb3.web3
    if (contractMoonToken) {
      let balance = await contractMoonToken.methods
        .balanceOf(connectWallet.connect?.address)
        .call()
      setMoonBalance(parseFloat(await web3.utils.fromWei(balance)))
    }
  }
  // const handleChangeLanguage = (lang: string) => {
  //   xxuLanguage.SetCurrentLanguage(lang)
  // }

  // connect wallet
  const handleConnectWallet = async () => {
    setConnectLoad(true)
    if (connectWeb3.web3) {
      let connect: any = await dispatch(
        xxaConnectWallet.ConnectWallet(connectWeb3.web3)
      )
      console.log('connect', connect)
      if (connect.connectedStatus) {
        setConnectLoad(false)
      } else {
        toaster.push(
          <Message
            onClose={() => {
              setConnectLoad(true)
            }}
            showIcon
            type={'warning'}
          >
            {connect.status}
          </Message>,
          { placement: 'topCenter' }
        )
      }
    }
  }

  // approve CUSD token
  const handleApproveToken = async () => {
    let web3 = connectWeb3.web3
    let maxInt = web3.utils.toBN(
      '115792089237316195423570985008687907853269984665640564039457584007913129639935'
    )
    let option = {
      from: userAddress
    }
    if (
      contractCUsd &&
      contractETH &&
      contractBTC &&
      contractBUSD &&
      contractUSDT
    ) {
      console.log(maxInt.toString())
      let transaction_hash = ''
      let contract =
        useToken === 'cUSD'
          ? contractCUsd
          : useToken === 'ETH'
          ? contractETH
          : useToken === 'BTC'
          ? contractBTC
          : useToken === 'USDT'
          ? contractUSDT
          : useToken === 'BUSD'
          ? contractBUSD
          : contractCUsd
      contract.methods
        .approve(
          xxuContract.GetContract().SaleAddress,
          // parseInt(new BigNumber(256).toString())
          maxInt
        )
        .send(option)
        .on('transactionHash', (transactionHash: any) => {
          setApproveLoad(true)
          transaction_hash = transactionHash
        })
        .on('receipt', function (receipt: any) {
          console.log('receipt', receipt)
          if (receipt.status) {
            setApproveCUsd(true)
            setApproveLoad(false)
            toaster.push(
              <Message duration={0} closable={true} showIcon type={'success'}>
                Approve สำเร็จ ตรวจสอบ{' '}
                <a
                  href={`https://testnet.bscscan.com/tx/${transaction_hash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Transection
                </a>
              </Message>,
              { placement: 'topEnd' }
            )
          } else {
            setBuyLoad(false)
            toaster.push(
              <Message duration={0} closable={true} showIcon type={'error'}>
                เกิดข้อผิดพลาดบางประการ ตรวจสอบ{' '}
                <a
                  href={`https://testnet.bscscan.com/tx/${transaction_hash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Transection
                </a>
              </Message>,
              { placement: 'topEnd' }
            )
          }
        })
        .on('error', () => {
          setApproveLoad(false)
          toaster.push(
            <Message duration={0} closable={true} showIcon type={'error'}>
              เกิดข้อผิดพลาดบางประการ ตรวจสอบ{' '}
              <a
                href={`https://testnet.bscscan.com/tx/${transaction_hash}`}
                target="_blank"
                rel="noreferrer"
              >
                Transection
              </a>
            </Message>,
            { placement: 'topEnd' }
          )
        })
    }
  }

  // check approve user with CUSD
  const checkAllowance = async () => {
    let web3 = connectWeb3.web3
    if (
      contractCUsd &&
      contractBTC &&
      contractBTC &&
      contractETH &&
      contractBUSD &&
      contractUSDT
    ) {
      console.log('useToken', useToken)
      let allowance =
        useToken === 'cUSD'
          ? await contractCUsd.methods
              .allowance(
                connectWallet.connect?.address,
                xxuContract.GetContract().SaleAddress
              )
              .call()
          : useToken === 'BTC'
          ? await contractBTC.methods
              .allowance(
                connectWallet.connect?.address,
                xxuContract.GetContract().SaleAddress
              )
              .call()
          : useToken === 'ETH'
          ? await contractETH.methods
              .allowance(
                connectWallet.connect?.address,
                xxuContract.GetContract().SaleAddress
              )
              .call()
          : useToken === 'BUSD'
          ? await contractBUSD.methods
              .allowance(
                connectWallet.connect?.address,
                xxuContract.GetContract().SaleAddress
              )
              .call()
          : useToken === 'USDT'
          ? await contractUSDT.methods
              .allowance(
                connectWallet.connect?.address,
                xxuContract.GetContract().SaleAddress
              )
              .call()
          : 1
      if (allowance > 0) {
        console.log('allowance', allowance)
        setApproveCUsd(true)
      } else {
        console.log('allowance', allowance)
        setApproveCUsd(false)
      }
    }
  }
  //check permission on buy
  const checkPermissionOnBuy = () => {
    if (!isWhitelistPeriod) {
      return true
    } else {
      if (profile.profile?.tokensale_user_profile.wallet_address) {
        if (
          userAddress.toLowerCase() ===
          profile.profile.tokensale_user_profile.wallet_address.toLowerCase()
        ) {
          return true
        } else {
          console.log('1')
          return false
        }
      } else {
        console.log('2')
        return false
      }
    }
  }
  // buy token by CUSD
  const handleBuyOnToken = async () => {
    // let check = checkPermissionOnBuy()
    // if (!check) {
    //   return toaster.push(
    //     <Message duration={0} closable={true} showIcon type={'error'}>
    //       ไม่มี Permission ในการซื้อ{' '}
    //     </Message>,
    //     { placement: 'topEnd' }
    //   )
    // }
    if (amountTokenPay > useTokenBalance) {
      toaster.push(
        <Message duration={1000} closable={true} showIcon type={'warning'}>
          เงินทุนไม่พอ
        </Message>,
        { placement: 'topEnd' }
      )
      return
    }
    let web3 = connectWeb3.web3
    // let BN = Web3Connect.data.web3.utils.BN
    if (contractSale && amountTokenPay > 0) {
      let option = {
        from: userAddress
      }
      // web3.utils.toWei(amountTokenPay.toString())
      console.log('wei', web3.utils.toWei(amountTokenPay.toString()))
      console.log('addrToken', xxuContract.GetContract().cUSDAddress)
      console.log('userAddress', userAddress)
      let transaction_hash = ''
      let tokenAddress =
        useToken === 'cUSD'
          ? xxuContract.GetContract().cUSDAddress
          : useToken === 'ETH'
          ? xxuContract.GetContract().ETHAddress
          : useToken === 'BTC'
          ? xxuContract.GetContract().BTCAddress
          : useToken === 'USDT'
          ? xxuContract.GetContract().USDTAddress
          : useToken === 'BUSD'
          ? xxuContract.GetContract().BUSDAddress
          : xxuContract.GetContract().cUSDAddress
      contractSale.methods
        .buyWithToken(web3.utils.toWei(amountTokenPay.toString()), tokenAddress)
        .send(option)
        // Transaction already saved to mempool
        .on('transactionHash', (transactionHash: any) => {
          setBuyLoad(true)
          transaction_hash = transactionHash
        })
        .on('receipt', function (receipt: any) {
          console.log('receipt', receipt)
          if (receipt.status) {
            setBuyLoad(false)
            getBalanceOfUserToken()
            getFundingGoal()
            getMoonBalanceOf()
            getLockUserToken()
            toaster.push(
              <Message duration={0} closable={true} showIcon type={'success'}>
                ตัดเหรียญสำเร็จแล้ว ตรวจสอบ{' '}
                <a
                  href={`https://testnet.bscscan.com/tx/${transaction_hash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Transection
                </a>
              </Message>,
              { placement: 'topEnd' }
            )
          } else {
            setBuyLoad(false)
            toaster.push(
              <Message duration={0} closable={true} showIcon type={'error'}>
                เกิดข้อผิดพลาดบางประการ ตรวจสอบ{' '}
                <a
                  href={`https://testnet.bscscan.com/tx/${transaction_hash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Transection
                </a>
              </Message>,
              { placement: 'topEnd' }
            )
          }
        })
        .on('error', async (error: Error) => {
          console.log('error', error)
          setBuyLoad(false)
          toaster.push(
            <Message duration={0} closable={true} showIcon type={'error'}>
              เกิดข้อผิดพลาดบางประการ ตรวจสอบ{' '}
              <a
                href={`https://testnet.bscscan.com/tx/${transaction_hash}`}
                target="_blank"
                rel="noreferrer"
              >
                Transection
              </a>
            </Message>,
            { placement: 'topEnd' }
          )
        })
    } else {
      setBuyLoad(false)
      toaster.push(
        <Message showIcon type={'warning'}>
          กรุณากรอกจำนวนเงิน
        </Message>,
        { placement: 'topEnd' }
      )
    }
  }

  // buy token by BNB
  const handleBuyOnBNB = async () => {
    // let check = checkPermissionOnBuy()
    // if (!check) {
    //   return toaster.push(
    //     <Message duration={0} closable={true} showIcon type={'error'}>
    //       ไม่มี Permission ในการซื้อ{' '}
    //     </Message>,
    //     { placement: 'topEnd' }
    //   )
    // }
    if (amountTokenPay > useTokenBalance) {
      toaster.push(
        <Message duration={1000} closable={true} showIcon type={'warning'}>
          เงินทุนไม่พอ
        </Message>,
        { placement: 'topEnd' }
      )
      return
    }
    let web3 = connectWeb3.web3
    // let BN = Web3Connect.data.web3.utils.BN
    if (contractSale && amountTokenPay > 0 && configTokenRate) {
      let option = {
        from: userAddress,
        value: web3.utils.toWei(amountTokenPay.toString())
      }
      let amount = amountTokenPay * configTokenRate?.BNB.rate
      console.log('amount', web3.utils.toWei(amountTokenPay.toString()))
      // let tokenBought = web3.utils.toWei(
      //   web3.utils.toBN((amountTokenPay * tokenRteEth).toString()).toString()
      // )
      // console.log('amountHaveBuy', amountTokenPay)
      // console.log('userAddress', userAddress)
      // console.log(' tokenBought', tokenBought)
      let transaction_hash = ''
      contractSale.methods
        .buy()
        .send(option)
        .on('transactionHash', (transactionHash: any) => {
          setBuyLoad(true)
          transaction_hash = transactionHash
          console.log('transactionHash', transactionHash)
        })
        .on('receipt', function (receipt: any) {
          console.log('receipt', receipt)
          if (receipt.status) {
            setBuyLoad(false)
            getBalanceOfUserToken()
            getFundingGoal()
            getMoonBalanceOf()
            getLockUserToken()
            toaster.push(
              <Message duration={0} closable={true} showIcon type={'success'}>
                ตัดเหรียญสำเร็จแล้ว ตรวจสอบ{' '}
                <a
                  href={`https://testnet.bscscan.com/tx/${transaction_hash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Transection
                </a>
              </Message>,
              { placement: 'topEnd' }
            )
          } else {
            setBuyLoad(false)
            toaster.push(
              <Message duration={0} closable={true} showIcon type={'error'}>
                เกิดข้อผิดพลาดบางประการ ตรวจสอบ{' '}
                <a
                  href={`https://testnet.bscscan.com/tx/${transaction_hash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Transection
                </a>
              </Message>,
              { placement: 'topEnd' }
            )
          }
        })
        .on('error', async (error: Error) => {
          console.log('error', error.message)
          setBuyLoad(false)
          toaster.push(
            <Message duration={0} closable={true} showIcon type={'error'}>
              เกิดข้อผิดพลาดบางประการ ตรวจสอบ{' '}
              <a
                href={`https://testnet.bscscan.com/tx/${transaction_hash}`}
                target="_blank"
                rel="noreferrer"
              >
                Transection
              </a>
            </Message>,
            { placement: 'topEnd' }
          )
        })
    } else {
      setBuyLoad(false)
      toaster.push(
        <Message showIcon type={'warning'}>
          กรุณากรอกจำนวนเงิน
        </Message>,
        { placement: 'topEnd' }
      )
    }
  }

  //buy token by fait
  const handleBuyOnFait = async () => {
    console.log('typeBuy', typeBuy)
    if (typeBuy === 'CCP') {
      onOpen()
    } else {
      let check = checkPermissionOnBuy()
      if (!check) {
        return toaster.push(
          <Message duration={0} closable={true} showIcon type={'error'}>
            ไม่มี Permission ในการซื้อ{' '}
          </Message>,
          { placement: 'topEnd' }
        )
      }
      if (amountTokenPay > 0) {
        dispatch(xxaPayment.Payment(amountTokenPay, typeBuy))
      } else {
        toaster.push(
          <Message showIcon type={'warning'}>
            กรุณากรอกจำนวนเงิน
          </Message>,
          { placement: 'topEnd' }
        )
      }
    }
  }

  const handleGetBinding = () => {
    dispatch(xxaBinding.GetBinding(mobilePhone))
  }

  const handleSendOTP = () => {
    dispatch(xxaBinding.VerifyBinding(mobilePhone, otp, otpRef))
  }

  const handleRequestPayment = () => {
    dispatch(
      xxaCCPPayment.RequestPayment(
        mobilePhone,
        amountTokenPay,
        parseFloat(payCCP)
      )
    )
  }

  const handleConfirmPayment = () => {
    dispatch(xxaCCPPayment.ConfirmPayment(paymentTid))
  }

  // check event
  const checkEventListener = async () => {
    let web3 = connectWeb3.web3
    let block = await web3.eth.getBlockNumber()
    if (contractSale) {
      console.log('On EventListener', block || blockNUmber)
      contractSale.events
        .BuyTokenWithToken(
          { filter: {}, fromBlock: block || blockNUmber }
          // (event: any) => {
          //   console.log('BuyTokenWithToken', event)
          //   getBlockNUmber()
          //   getFundingGoal()
          //   return
          // }
        )
        .on('data', function (event: any) {
          console.log('BuyTokenWithToken', event)
          getBlockNumber()
          getFundingGoal()
          return
        })
        .on('error', (error: any) => {
          console.log('BuyTokenWithToken error', error)
        })
      contractSale.events
        .BuyToken(
          { filter: {}, fromBlock: block || blockNUmber }
          // (event: any) => {
          //   console.log('BuyToken', event)
          //   getBlockNUmber()
          //   getFundingGoal()
          //   return
          // }
        )
        .on('data', function (event: any) {
          console.log('BuyToken', event)
          getBlockNumber()
          getFundingGoal()
          return
        })
        .on('error', (error: any) => {
          console.log('BuyToken error', error)
        })
    }
  }

  const getBlockNumber = async () => {
    let web3 = connectWeb3.web3
    let block = await web3.eth.getBlockNumber()
    console.log('block', block)
    setBlockNUmber(block)
  }

  //claim token
  const handleClaimToken = async () => {
    let option = {
      from: userAddress
    }
    if (contractSale) {
      let transaction_hash = ''
      contractSale.methods
        .claimLockToken()
        .send(option)
        .on('transactionHash', (transactionHash: any) => {
          setClaimLoad(true)
          transaction_hash = transactionHash
        })
        .on('receipt', function (receipt: any) {
          stopIntervalTask()
          console.log('receipt', receipt)
          if (receipt.status) {
            setClaimLoad(false)
            getBalanceOfUserToken()
            getFundingGoal()
            getMoonBalanceOf()
            getLockUserToken()
            setClaimEnable(true)
            toaster.push(
              <Message duration={0} closable={true} showIcon type={'success'}>
                claim success ตรวจสอบ{' '}
                <a
                  href={`https://testnet.bscscan.com/tx/${transaction_hash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Transection
                </a>
              </Message>,
              { placement: 'topEnd' }
            )
          } else {
            setClaimLoad(false)
            toaster.push(
              <Message duration={0} closable={true} showIcon type={'error'}>
                เกิดข้อผิดพลาดบางประการ ตรวจสอบ{' '}
                <a
                  href={`https://testnet.bscscan.com/tx/${transaction_hash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Transection
                </a>
              </Message>,
              { placement: 'topEnd' }
            )
          }
        })
        .on('error', async (error: Error) => {
          console.log('error', error.message)
          setBuyLoad(false)
          toaster.push(
            <Message duration={0} closable={true} showIcon type={'error'}>
              เกิดข้อผิดพลาดบางประการ ตรวจสอบ{' '}
              <a
                href={`https://testnet.bscscan.com/tx/${transaction_hash}`}
                target="_blank"
                rel="noreferrer"
              >
                Transection
              </a>
            </Message>,
            { placement: 'topEnd' }
          )
        })
    }
  }

  const checkClaimEnable = () => {
    // let web3 = connectWeb3.web3
    if (contractSale) {
      let timeStamp = Math.ceil(new Date().getTime() / 1000)
      // console.log('timeStamp', dayjs(timeStamp * 1000).format('HH:mm:ss'))
      // console.log('chuckCurrent', chuckCurrent)
      if (chuckCurrent === 10) {
        console.log('big##########')
        stopIntervalTask()
        return
      }
      if (timeStamp > lockStart[chuckCurrent]) {
        setClaimEnable(false)
        // console.log('setClaimEnable')
      } else {
        setClaimEnable(true)
        // console.log('setClaimDisable')
      }
    }
  }

  const startIntervalTask = () => {
    return (intervalHandle = setInterval(() => {
      checkClaimEnable()
    }, 1000))
  }
  const stopIntervalTask = () => {
    console.log('clearInterval')
    clearInterval(intervalHandle)
  }

  const maxTokenPay = () => {
    if (useTokenBalance <= configTokenRate[useToken].max) {
      setAmountTokenPay(parseFloat((useTokenBalance - 0.004).toFixed(4)))
    } else {
      setAmountTokenPay(parseFloat(configTokenRate[useToken].max.toFixed(4)))
    }
  }

  const calculateMaxToken = () => {
    if (configTokenRate) {
      let tokenPayBuy = limitReceiveBalance * configTokenRate[useToken].rate
      console.log('tokenPayBuy', tokenPayBuy)
      console.log('amountTokenPay', amountTokenPay)
      if (tokenPayBuy < amountTokenPay) {
        setAmountTokenPay(tokenPayBuy)
      }
    }
  }

  // useMemo(() => {
  //   if (lockStart[0] > 0) {
  //     console.log('chuckCurrent',chuckCurrent);
  //     // setTimeout(() => {
  //     //   startIntervalTask()
  //     // }, 1000);

  //   }
  // }, [chuckCurrent,lockStart])

  // set user address after connect wallet
  useEffect(() => {
    getBlockNumber()
    getIcoTime()
    getFundingGoal()
    dispatch(xxaRateConfig.GetRateConfig())
    console.log(connectWallet)
    if (connectWallet.connect?.connectedStatus) {
      setUserAddress(connectWallet.connect.address)
      setConnectLoad(false)
    } else {
      setUserAddress('')
    }
  }, [connectWallet])

  // connect contract sale call getIcoTime and getFundingGoal
  // useEffect(() => {
  //   if (contractSale) {
  //     getIcoTime()
  //     getFundingGoal()
  //     checkEventListener()
  //   }
  // }, [contractSale])

  useMemo(() => {
    if (contractSale) {
      getIcoTime()
      getFundingGoal()
      checkEventListener()
    }
  }, [])

  // get user token balance after change token pay
  useEffect(() => {
    if (contractCUsd && userAddress) {
      getBalanceOfUserToken()
    }
  }, [useToken, userAddress])

  // call checkAllowance , getTokenRteEth and getTokenRateTokenBuy after set userAddress
  useEffect(() => {
    if (connectWallet.connect?.connectedStatus) {
      // getTokenRateTokenBuy()
      // getTokenRteEth()
      checkAllowance()
      getMoonBalanceOf()
      getLockUserToken()
      getLockStartLock()
      getLimitTokenPerUser()
    }
  }, [connectWallet])

  useEffect(() => {
    if (useToken) {
      checkAllowance()
    }
  }, [useToken])

  // calculate token will git after change amountTokenPay and useToken
  // useEffect(() => {
  //   if (useToken === 'BNB') {
  //     if (tokenRteEth && amountTokenPay) {
  //       setMoonWillGet(amountTokenPay / tokenRteEth)
  //     } else {
  //       setMoonWillGet(0)
  //     }
  //   } else {
  //     if (tokenRateTokenBuy && amountTokenPay) {
  //       setMoonWillGet(amountTokenPay / tokenRateTokenBuy)
  //     } else {
  //       setMoonWillGet(0)
  //     }
  //   }
  // }, [useToken, amountTokenPay])
  useEffect(() => {
    if (configTokenRate && amountTokenPay) {
      setMoonWillGet(
        amountTokenPay /
          (useToken === 'BNB'
            ? configTokenRate?.BNB.rate
            : useToken === 'cUSD'
            ? configTokenRate?.cUSD.rate
            : useToken === 'ETH'
            ? configTokenRate?.ETH.rate
            : useToken === 'BTC'
            ? configTokenRate?.BTC.rate
            : useToken === 'USDT'
            ? configTokenRate?.USDT.rate
            : useToken === 'BUSD'
            ? configTokenRate?.BUSD.rate
            : useToken === 'THB'
            ? configTokenRate?.THB.rate
            : 1)
      )
    } else {
      setMoonWillGet(0)
    }
  }, [useToken, amountTokenPay])

  useEffect(() => {
    if (limitEnable) {
      calculateMaxToken()
    }
  }, [amountTokenPay])

  // calculate token will git after change amountFiatPay
  useEffect(() => {
    setMoonWillGetTHB(amountFiatPay / tokenRteTHB)
  }, [amountFiatPay])

  // have payment redirect url
  useEffect(() => {
    if (payment.payment) {
      dispatch(xxaPayment.ClearPayment())
      window.location.href = payment.payment.link
    }
  }, [payment])

  useEffect(() => {
    if (requestPayment.requestPayment) {
      // setOtpRef(requestPayment.requestPayment.otp_ref_code)
      setPaymentTid(requestPayment.requestPayment.tokensale_payment_tid)
      setccpPayStep(4)
      console.log('verifyPayment.verifyPayment', verifyPayment.verifyPayment)
      setCcpBalance(yourCCP - parseFloat(requestPayment.requestPayment.ccp_pay))
      setCcpPay(parseFloat(requestPayment.requestPayment.ccp_pay))
      setGatewayPay(parseFloat(requestPayment.requestPayment.gateway_pay))
      setccpPayStep(4)
    }
  }, [requestPayment])

  useEffect(() => {
    if (profile.profile) {
      setccpPayStep(3)
      if (
        profile.profile.tokensale_user_profile?.chomchob_user_binding.length > 0
      ) {
        setYourCCP(
          profile.profile.tokensale_user_profile?.chomchob_user_binding[0]
            .ccp_balance
        )
        setBindingSuccess(true)
        setccpPayStep(3)
        setMobilePhone(
          profile.profile.tokensale_user_profile?.chomchob_user_binding[0]
            .mobile_number
        )
      } else if (
        profile.profile.tokensale_user_profile?.chomchob_user_binding.length ===
        0
      ) {
        setYourCCP(0)
        setccpPayStep(1)
        setMobilePhone('')
      }

      // alert('มี ccp '+verifyBalance.verifyBalance.ccp_balance)
    }
  }, [profile])

  //set rateConfig after call api rateConfig
  useEffect(() => {
    if (rateConfig.rateConfig) {
      // setTokenRteTHB(rateConfig.rateConfig.price_rate_thb)
      // setMoonLockRate(rateConfig.rateConfig.lock_percent)
      // setTokenRteEth(rateConfig.rateConfig.price_rate_ether)
      // setTokenRateTokenBuy(rateConfig.rateConfig.price_rate_token)
      // setMoonLockRate(rateConfig.rateConfig.lock_percent)
    }
  }, [rateConfig])

  useEffect(() => {
    if (rateConfig.rateConfig) {
      let data: any = _.keyBy(rateConfig.rateConfig.price_rate_config, 'symbol')
      setConfigTokenRate(data)
      console.log('rate config', data)
      setMoonLockRate(rateConfig.rateConfig.lock_percent)
    }
  }, [rateConfig])

  // if have ref
  useMemo(async () => {
    let web3 = connectWeb3.web3
    let block = await web3.eth.getBlockNumber()
    if (ref && userAddress) {
      if (contractSale) {
        // setBuyLoad(true)
        console.log('ref event on', web3.utils.keccak256(ref))
        let referenceTx = web3.utils.keccak256(ref) ?? ''
        console.log('userAddress sha3', web3.utils.sha3(userAddress))
        contractSale.once(
          'BuyTokenWithDistributor',
          {
            // filter: {
            //   receiver: userAddress
            //   // tokenReceived: 20000000000000000000,
            //   // referenceTx: referenceTx
            // },
            topics: [null, null, referenceTx],
            fromBlock: 0
          },
          function (error: any, event: any) {
            console.log('BuyTokenWithDistributor', event)
            // setBuyLoad(false)
            // console.log('userAddress',userAddress);
            getFundingGoal()
            getMoonBalanceOf()
            getLockUserToken()
            return
          }
        )
      }
    }
  }, [ref, userAddress])

  useEffect(() => {
    if (getBinding.getBinding) {
      setccpPayStep(2)
      setOtpRef(getBinding.getBinding.otp_ref_code)
    }
  }, [getBinding])

  useEffect(() => {
    if (verifyBinding.verifyBinding) {
      setccpPayStep(3)
      dispatch(xxaProfile.GetProfile())
    }
  }, [verifyBinding])

  useEffect(() => {
    if (confirmPayment.confirmPayment) {
      if (confirmPayment.confirmPayment.link) {
        window.location.href = confirmPayment.confirmPayment.link
      } else {
        alert('ชำระเงิน สำเร็จ')
      }
      // alert('มี ccp '+verifyBalance.verifyBalance.ccp_balance)
    }
  }, [confirmPayment])

  useEffect(() => {
    if (lockStart[0] > 0) {
      startIntervalTask()
    }
  }, [lockStart])

  useMemo(() => {
    dispatch(xxaProfile.GetProfile())
  }, [])

  return (
    <div className="container-token-sale">
      <div className="box-sale">
        {/* top */}
        <div className="box-sale-top">
          <div className="box-sale-top-img">
            <img
              height="350"
              width="380"
              src="https://i.pinimg.com/originals/ea/7f/2d/ea7f2dd47969349da148ea0b4ec56815.gif"
              alt=""
            />
            {/* <StlViewer
              style={{ top: 0, left: 0, width: '100%', height: '100%' }}
              url={'https://storage.googleapis.com/ucloud-v3/ccab50f18fb14c91ccca300a.stl'}
            /> */}
          </div>
          <div className="box-sale-top-detail">
            <div className="box-sale-top-detail-title">
              <h2>Optic Token</h2>
            </div>
            {connectWallet.connect?.network ? (
              <>
                <div className="box-sale-top-detail-title">
                  Optic Token Sale is Live
                </div>
                <div className="box-sale-top-detail-fields">
                  <div>Start Sale is</div>
                  <div>
                    {dayjs(icoTime.start * 1000).format('DD-MM-YYYY HH:mm')}
                  </div>
                </div>
                <div className="box-sale-top-detail-fields">
                  <div>End Sale is</div>
                  <div>
                    {dayjs(icoTime.end * 1000).format('DD-MM-YYYY HH:mm')}
                  </div>
                </div>
                <div className="box-sale-top-detail-fields">
                  <div>จำนวนที่ต้องการขาย</div>
                  <div>
                    {funGoal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}{' '}
                    Moon
                  </div>
                </div>
                <div className="box-sale-top-detail-fields">
                  <div>ขายไปแล้ว</div>
                  <div>
                    {tokenRaised.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}{' '}
                    Moon
                  </div>
                </div>
                <div className="box-sale-top-detail-fields">
                  <div>Percent ที่ LOCK ไว้</div>
                  <div>
                    {moonLockRate.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}{' '}
                    %
                  </div>
                </div>
                {userAddress ? (
                  <>
                    <div className="box-sale-top-detail-fields">
                      <div>เหรียญที่ LOCK</div>
                      <div>
                        {lockUserToken.toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}{' '}
                        Moon
                      </div>
                    </div>
                    <div className="box-sale-top-detail-fields">
                      <div>เหรียญของฉัน</div>
                      <div>
                        {moonBalance.toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}{' '}
                        Moon
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            ) : (
              <Placeholder.Paragraph style={{ marginTop: 30 }} />
            )}
          </div>
        </div>
        {/* buy by token */}
        <div className="box-sale-center">
          <div>
            <Placeholder.Paragraph
              active
              style={{ marginTop: 10 }}
              graph="image"
            />
            <Placeholder.Paragraph active rows={15} />
          </div>
          <div>
            <div className="pay-with-token">
              <div className="pay-with-token-title">PAY BY TOKEN</div>
              {connectWallet.connect?.connectedStatus ? (
                <>
                  <div className="pay-with-token-form">
                    <div style={{ width: '100px' }}>
                      <SelectPicker
                        cleanable={false}
                        searchable={false}
                        size="lg"
                        placeholder="Large"
                        defaultValue={useToken}
                        value={useToken}
                        onChange={(value: any) => {
                          setUseToken(value)
                        }}
                        data={[
                          {
                            label: 'BNB',
                            value: 'BNB'
                          },
                          {
                            label: 'cUSD',
                            value: 'cUSD'
                          },
                          {
                            label: 'ETH',
                            value: 'ETH'
                          },
                          {
                            label: 'BTC',
                            value: 'BTC'
                          },
                          {
                            label: 'BUSD',
                            value: 'BUSD'
                          },
                          {
                            label: 'USDT',
                            value: 'USDT'
                          },
                          {
                            label: 'THB',
                            value: 'THB'
                          }
                        ]}
                        style={{ width: '100px' }}
                      />
                    </div>

                    {useToken !== 'BNB' && !approveCUsd ? (
                      <div className="box-approve">
                        <Button
                          size="lg"
                          onClick={() => {
                            handleApproveToken()
                          }}
                          isLoading={approveLoad}
                        >
                          APPROVE
                        </Button>
                      </div>
                    ) : (
                      <Box w="100%">
                        <NumberInput
                          isInvalid={false}
                          min={
                            configTokenRate ? configTokenRate[useToken].min : 0
                          }
                          max={
                            configTokenRate
                              ? useTokenBalance > configTokenRate[useToken].max
                                ? configTokenRate[useToken].max
                                : useTokenBalance - 0.004
                              : 0
                          }
                          value={amountTokenPay}
                          defaultValue={0}
                          onChange={async (value: string) => {
                            console.log('bnb', value)
                            let max =
                              useTokenBalance > configTokenRate[useToken].max
                                ? configTokenRate[useToken].max
                                : useTokenBalance - 0.004
                            if (parseFloat(value) > max) {
                              setAmountTokenPay(parseFloat(max.toFixed(4)))
                            } else {
                              if (!value) {
                                setAmountTokenPay(0)
                              } else {
                                setAmountTokenPay(parseFloat(value))
                              }
                            }
                          }}
                        >
                          <NumberInputField
                            paddingInlineEnd="55px"
                            style={{ textAlign: 'end' }}
                          />
                          <NumberInputStepper
                            display="flex"
                            justifyContent="center"
                            right="4px"
                            width="45px"
                            height="100%"
                            margin="0"
                          >
                            {/* <NumberIncrementStepper /> */}
                            <Button
                              size="sm"
                              onClick={() => {
                                maxTokenPay()
                              }}
                            >
                              Max
                            </Button>
                          </NumberInputStepper>
                        </NumberInput>
                      </Box>
                    )}
                  </div>
                  <div className="pay-with-token-balance">
                    Balance:{' '}
                    {useTokenBalance.toLocaleString('en-US', {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 4
                    })}{' '}
                    {useToken}
                  </div>
                  <div className="pay-with-token-balance">
                    limit can buy token:
                    {limitReceiveBalance.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                  </div>

                  <div className="pay-with-token-balance">
                    min:{configTokenRate ? configTokenRate[useToken].min : null}
                  </div>
                  <div className="pay-with-token-balance">
                    max:{configTokenRate ? configTokenRate[useToken].max : null}
                  </div>
                  <div className="pay-with-token-form">
                    <div style={{ width: '140px' }}>
                      <Input
                        size="lg"
                        value="Moon"
                        readOnly
                        placeholder="Default Input"
                      />
                    </div>
                    <InputNumber
                      size="lg"
                      value={moonWillGet}
                      readOnly
                      scrollable={false}
                      step={0.01}
                      className="custom-input-number"
                      style={{ textAlign: 'end' }}
                      // placeholder="Amount"
                    />
                  </div>
                  {useToken === 'THB' ? (
                    <Box mt="20px">
                      <RadioGroup
                        onChange={(value: any) => {
                          setTypeBuy(value)
                        }}
                        value={typeBuy}
                      >
                        <Stack direction="row">
                          <Radio value="credit_card">
                            <HStack>
                              <Image
                                style={{
                                  border: '1px solid #000',
                                  padding: '4px 10px',
                                  borderRadius: '4px'
                                }}
                                height="28px"
                                htmlHeight="28px"
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/2560px-Mastercard_2019_logo.svg.png"
                                alt=""
                              />
                              <Image
                                style={{
                                  marginLeft: '10px',
                                  border: '1px solid #000',
                                  padding: '6px',
                                  borderRadius: '4px'
                                }}
                                width="55px"
                                htmlWidth="55px"
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5a/Visa_2014.svg/2560px-Visa_2014.svg.png"
                                alt=""
                              />
                            </HStack>
                          </Radio>
                          <Radio value="qr_code">
                            <RiQrCodeLine fontSize="30px" />
                          </Radio>
                          <Radio value="CCP">
                            <Image
                              height="30px"
                              htmlHeight="30px"
                              src="https://fs.ichob.co/v1/external?path=coin/ichob"
                              alt=""
                            />
                          </Radio>
                        </Stack>
                      </RadioGroup>
                    </Box>
                  ) : (
                    <Box h="30px" mt="20px"></Box>
                  )}

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '20px'
                    }}
                  >
                    {endSale ? (
                      <Text fontSize="xl">End Sale</Text>
                    ) : (
                      <Button
                        disabled={
                          amountTokenPay > 0 &&
                          amountTokenPay >= configTokenRate[useToken].min
                            ? false
                            : true
                        }
                        size="lg"
                        isLoading={buyLoad}
                        onClick={() => {
                          if (useToken === 'BNB') {
                            handleBuyOnBNB()
                          } else if (useToken === 'THB') {
                            handleBuyOnFait()
                          } else {
                            handleBuyOnToken()
                          }
                        }}
                        style={{ width: '100px' }}
                      >
                        BUY
                      </Button>
                    )}
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px'
                  }}
                >
                  <Button
                    color="green"
                    size="lg"
                    onClick={() => {
                      handleConnectWallet()
                    }}
                    style={{ color: '#575757' }}
                    isLoading={connectLoad}
                  >
                    <img
                      style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '10px',
                        marginTop: '-2px'
                      }}
                      src="https://raw.githubusercontent.com/MetaMask/brand-resources/master/SVG/metamask-fox.svg"
                      alt=""
                    />
                    Connect Wallet
                  </Button>
                </div>
              )}
            </div>
            <div className="pay-with-fiat">
              <>
                <div className="pay-with-fiat-title">CLAIM</div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px'
                  }}
                >
                  <Button
                    size="lg"
                    disabled={claimEnable}
                    isLoading={claimLoad}
                    onClick={() => handleClaimToken()}
                    style={{ width: '100px' }}
                  >
                    CLAIM
                  </Button>
                </div>
              </>
            </div>
           
          </div>
        </div>
      </div>
      {/* TokenSale */}
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {ccpPayStep === 1 || ccpPayStep === 2
              ? 'USER CHOMCHOM BINDING'
              : 'PAY BY CHOMCHOB POINTS'}
          </ModalHeader>
          <ModalCloseButton />
          {ccpPayStep === 1 ? (
            <>
              {/* <ModalBody pb={0}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<RiPhoneFill />}
                  />
                  <Input
                    value={mobilePhone}
                    onChange={e => {
                      setMobilePhone(e.target.value)
                    }}
                    type="tel"
                    placeholder="Phone number"
                  />
                </InputGroup>
                <NumberInput>
                  <NumberInputField />
                </NumberInput>
              </ModalBody> */}
              <ModalBody pb={0}>
                <FormControl id="first-name">
                  <FormLabel>Mobile Number</FormLabel>
                  <Input
                    value={mobilePhone}
                    onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                      setMobilePhone(value.target.value)
                    }}
                    placeholder="Mobile Number"
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  bg="#53c8bf"
                  colorScheme="teal"
                  onClick={() => {
                    handleGetBinding()
                    // setIsOpenAlert(true)
                  }}
                  color="#fff"
                  mr={3}
                >
                  BINDING
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </>
          ) : ccpPayStep === 2 ? (
            <>
              <ModalBody pb={0}>
                <Center>
                  <Box>
                    <HStack>
                      <PinInput
                        otp
                        placeholder=""
                        defaultValue={otp}
                        onChange={(event: any) => {
                          console.log('opt', event)
                          setOtp(event)
                          setOtpError(false)
                        }}
                      >
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                      </PinInput>
                    </HStack>
                    <Box mt="2">ref : {otpRef}</Box>
                    {otpError ? (
                      <Alert mt="2" borderRadius="8px" status="error">
                        <AlertIcon />
                        OTP not correct
                      </Alert>
                    ) : null}
                  </Box>
                </Center>
              </ModalBody>

              <ModalFooter>
                <Button
                  bg="#53c8bf"
                  colorScheme="teal"
                  color="#fff"
                  disabled={otp.length < 6 ?? false}
                  onClick={() => {
                    handleSendOTP()
                  }}
                  mr={3}
                >
                  Confirm OTP
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </>
          ) : ccpPayStep === 3 ? (
            <>
              <ModalBody pb={0}>
                {/* <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<RiPhoneFill />}
                  />
                  <Input
                    value={payCCP}
                    onChange={e => {
                      if (!e.target.value) {
                        setPayCCP('0')
                      } else {
                        setPayCCP(e.target.value)
                      }
                    }}
                    type="text"
                    pattern="[0-9]*"
                    placeholder="CCP Points"
                  />
                </InputGroup> */}
                <FormControl id="first-name">
                  <FormLabel>Your CCP : {yourCCP} Point(s)</FormLabel>
                  <Input
                    value={payCCP}
                    onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                      setPayCCP(value.target.value)
                    }}
                    placeholder="Enter points to pay"
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button
                  bg="#53c8bf"
                  colorScheme="teal"
                  color="#fff"
                  onClick={() => {
                    handleRequestPayment()
                  }}
                  mr={3}
                >
                  Pay
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalBody pb={0}>
                <Box>ccp ที่เหลือ : {ccpBalance}</Box>
                <Box>ccp ที่จ่าย : {ccpPay}</Box>
                <Box>ต้องจ่ายเพิ่มอีก : {gatewayPay}</Box>
              </ModalBody>
              <ModalFooter>
                <Button
                  bg="#53c8bf"
                  colorScheme="teal"
                  color="#fff"
                  onClick={() => {
                    handleConfirmPayment()
                  }}
                  mr={3}
                >
                  Pay
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  )
}

export default TokenSale
