// Constants
import {
  PAYMENT_SUCCESS,
  PAYMENT_FAIL,
  PAYMENT_CLEAR_MESSAGE
} from 'redux/Constants/Payment'

// Props Types
import IAction from 'redux/Action/types'
import xxrePaymentReducerTypes from 'redux/Reducers/Payment/types'

const payment = (
  state: object = {},
  action: IAction<xxrePaymentReducerTypes.Props>
) => {
  switch (action.type) {
    case PAYMENT_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case PAYMENT_FAIL:
      return {
        ...state,
        ...action.payload
      }
    case PAYMENT_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default payment
