// Utils
import xxuResponse from 'utils/Response'
import dayjs from 'dayjs'

const xxaClaim = {
  TimeClaim: (startDate: number, round: number) => {
    let time: number[] = []
    for (let i = 0; i < 10; i++) {
      time.push(startDate + round * (i))
    }
    return time
  }
}

export default xxaClaim
