import generator from 'generate-password'
import * as ls from 'local-storage'
import dayjs from 'dayjs'

//service
import xxsLoginService from 'services/Auth/Login'

const xxaToken = {
  GetRefreshToken: (
    refresh_token: string
  ): Promise<{ status: number; token?: any; error?: any }> => {
    return new Promise(async resolve => {
      const result = await xxsLoginService.GetRefreshToken(refresh_token)
      console.log('refresh_token',result)
      if (result.status === 200) {
        const token = result.login
        window.localStorage.setItem('u', JSON.stringify(result.login))
        resolve({
          status: 200,
          token: token
        })
      } else {
        resolve({
          status: result.status,
          error: result.error
        })
        // Logout
        // xaToken.SetTokenExpires()
      }
    })
  }
}

export default xxaToken
