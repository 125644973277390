import { Dispatch } from 'redux'

// Redux Constants
import {
  EMAIL_SEND_SUCCESS,
  EMAIL_SEND_FAIL,
  EMAIL_SEND_CLEAR_MESSAGE
} from 'redux/Constants/Email/Send'
import {
  EMAIL_VERIFY_SUCCESS,
  EMAIL_VERIFY_FAIL,
  EMAIL_VERIFY_CLEAR_MESSAGE
} from 'redux/Constants/Email/Verify'

// Utils
import xxuResponse from 'utils/Response'

//service
import xxsEmailSendService from 'services/Email/Send'
import xxsEmailVerifyService from 'services/Email/Verify'

const xxaEmail = {
  ClearEmailSend: () => async (dispatch: Dispatch) => {
    dispatch({
      type: EMAIL_SEND_CLEAR_MESSAGE
    })
  },
  EmailSend: (email: string) => async (dispatch: Dispatch) => {
    // Clear data of example in Redux
    dispatch({
      type: EMAIL_SEND_CLEAR_MESSAGE
    })
    const response = await xxsEmailSendService.EmailSend(email)
    if (response.status === 200) {
      xxuResponse.Success({
        type: EMAIL_SEND_SUCCESS,
        dispatch,
        payload: response
      })
    } else {
      xxuResponse.Error({
        type: EMAIL_SEND_FAIL,
        errorPage: true,
        dispatch,
        error: response.error
      })
    }
  },
  ClearEmailVerify: () => async (dispatch: Dispatch) => {
    dispatch({
      type: EMAIL_VERIFY_CLEAR_MESSAGE
    })
  },
  EmailVerify: (ref: string) => async (dispatch: Dispatch) => {
    // Clear data of example in Redux
    dispatch({
      type: EMAIL_VERIFY_CLEAR_MESSAGE
    })
    const response = await xxsEmailVerifyService.EmailVerify(ref)
    if (response.status === 200) {
      xxuResponse.Success({
        type: EMAIL_VERIFY_SUCCESS,
        dispatch,
        payload: response
      })
    } else {
      xxuResponse.Error({
        type: EMAIL_VERIFY_FAIL,
        errorPage: true,
        dispatch,
        error: response.error
      })
    }
  }
}

export default xxaEmail
