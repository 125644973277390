import axios from 'axios'
import xxsEmailVerifyServiceTypes from 'services/Email/Verify/types'
import xxaDevice from 'actions/Utils/Device'
import { servicePath } from 'configs'

import xxaInitial from 'actions/Initial'

const xxsEmailVerifyService = {
  EmailVerify: async (
    ref: string
  ): Promise<xxsEmailVerifyServiceTypes.EmailVerifyResponse> => {
    let response: xxsEmailVerifyServiceTypes.EmailVerifyResponse
    const token = await xxaInitial.Check()

    const data = {
      ref: ref
    }

    try {
      const responseData =
        await axios.post<xxsEmailVerifyServiceTypes.EmailVerifySuccess>(
          `${servicePath.service.general}/v1/register/confirm/verify-email`,
          data,
          {
            headers: {
              Authorization: token,
              'device-Id': await xxaDevice.GetDeviceId()
            }
          }
        )

      response = {
        status: responseData.status,
        emailVerify: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  }
}

export default xxsEmailVerifyService
