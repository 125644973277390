import { Dispatch } from 'redux'

// Redux Constants
import {
  GET_RATE_CONFIG_SUCCESS,
  GET_RATE_CONFIG_FAIL
  // GET_RATE_CONFIG_CLEAR_MESSAGE
} from 'redux/Constants/RateConfig'

// Utils
import xxuResponse from 'utils/Response'

//service
import xxsRateConfigService from 'services/RateConfig'

const xxaRateConfig = {
  GetRateConfig: () => async (dispatch: Dispatch) => {
    const response = await xxsRateConfigService.GetRateConfig()
    if (response.status === 200) {
      xxuResponse.Success({
        type: GET_RATE_CONFIG_SUCCESS,
        dispatch,
        payload: response
      })
    } else {
      xxuResponse.Error({
        type: GET_RATE_CONFIG_FAIL,
        errorPage: true,
        dispatch,
        error: response.error
      })
    }
  }
}

export default xxaRateConfig
