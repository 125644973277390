import axios from 'axios'
import xxsProfileServiceTypes from 'services/Profile/types'
import xxaDevice from 'actions/Utils/Device'
import { servicePath } from 'configs'

import xxaInitial from 'actions/Initial'

const xxsProfileService = {
  GetProfile: async (): Promise<xxsProfileServiceTypes.GetProfileResponse> => {
    let response: xxsProfileServiceTypes.GetProfileResponse
    const token = await xxaInitial.Check()

    try {
      const responseData =
        await axios.get<xxsProfileServiceTypes.GetUserProfileSuccess>(
          `${servicePath.service.general}/v1/user/profile`,
          {
            headers: {
              Authorization: token,
              'device-Id': await xxaDevice.GetDeviceId()
            }
          }
        )

      response = {
        status: responseData.status,
        profile: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  UpdateProfile: async (
    answers: { question_short_name: string; answer: any }[],
    email: string
  ): Promise<xxsProfileServiceTypes.UpdateProfileResponse> => {
    let response: xxsProfileServiceTypes.UpdateProfileResponse
    const token = await xxaInitial.Check()
    let data: {
      answers: { question_short_name: string; answer: any }[]
      email?: string
    } = {
      answers: answers
    }
    if (email) {
      data.email = email
    }
    try {
      const responseData =
        await axios.post<xxsProfileServiceTypes.UpdateUserProfileSuccess>(
          `${servicePath.service.general}/v1/register/save/answer`,
          data,
          {
            headers: {
              Authorization: token,
              'device-Id': await xxaDevice.GetDeviceId()
            }
          }
        )

      response = {
        status: responseData.status,
        updateProfile: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  }
}

export default xxsProfileService
