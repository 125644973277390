import { Dispatch } from 'redux'
import dayjs from 'dayjs'

// action
import xxaLogin from 'actions/Auth/Login'
import xxaAnonymousToken from 'actions/Auth/AnonymousToken'
import xxaToken from 'actions/Utils/Token'

//service
import xxsAuthAnonymousTokenService from 'services/Auth/AnonymousToken'

import {
  GET_ANONYMOUS_SUCCESS,
  GET_ANONYMOUS_FAIL,
  GET_ANONYMOUS_CLEAR_MESSAGES
} from 'redux/Constants/Auth/AnonymousToken'

const xxaInitial = {
  Clear: () => (dispatch: Dispatch) => {
    dispatch({
      type: GET_ANONYMOUS_CLEAR_MESSAGES
    })
  },
  Check: () => {
    return new Promise(async resolve => {
      const checkToken = await xxaLogin.GetAccessTokenFromLocalStorage()
      console.log(checkToken)
      if (checkToken.status === 200) {
        if (
          dayjs(checkToken.token?.token_expire_at).diff(dayjs(), 'minute') > 0
        ) {
          resolve(checkToken.token?.token)
        } else {
          //call refresh token
          console.log(
            'time',
            dayjs(checkToken.token?.token_expire_at).diff(dayjs(), 'minute')
          )
          console.log('checkToken', checkToken)
          const newToken = await xxaToken.GetRefreshToken(
            checkToken.token.refresh_token
          )
          console.log('newToken', newToken)
          resolve(newToken.token?.token)
        }
      } else {
        const anonymousData =
          await xxaAnonymousToken.GetAnonymousTokenFromLocalStorage()
        if (
          anonymousData.status === 200 &&
          dayjs(anonymousData.anonymous?.token_expire_at).diff(
            dayjs(),
            'minute'
          ) > 0
        ) {
          resolve(anonymousData.anonymous?.token)
        } else {
          // Call Anonymous Token
          const resultAnonymous =
            await xxsAuthAnonymousTokenService.GetAnonymousToken()
          if (resultAnonymous.status === 200) {
            // xaAnonymous.SetAnonymousTokenToLocalStorage(resultAnonymous)
            window.localStorage.setItem(
              'a',
              JSON.stringify(resultAnonymous.anonymous)
            )
            resolve(resultAnonymous.anonymous?.token)
          }
        }
      }
    })
  },
  CheckAnonymous: () => {
    return new Promise(async resolve => {
      const anonymousData =
        await xxaAnonymousToken.GetAnonymousTokenFromLocalStorage()
      if (
        anonymousData.status === 200 &&
        dayjs(anonymousData.anonymous?.token_expire_at).diff(
          dayjs(),
          'minute'
        ) > 0
      ) {
        resolve(anonymousData.anonymous?.token)
      } else {
        // Call Anonymous Token
        const resultAnonymous =
          await xxsAuthAnonymousTokenService.GetAnonymousToken()
        if (resultAnonymous.status === 200) {
          // xaAnonymous.SetAnonymousTokenToLocalStorage(resultAnonymous)
          window.localStorage.setItem(
            'a',
            JSON.stringify(resultAnonymous.anonymous)
          )
          resolve(resultAnonymous.anonymous?.token)
        }
      }
    })
  }
}

export default xxaInitial
