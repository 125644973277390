import axios from 'axios'
import xxsLoginServiceTypes from 'services/Auth/Login/types'
import xxaDevice from 'actions/Utils/Device'
import { servicePath } from 'configs'

import xxaInitial from 'actions/Initial'

const xxsLoginService = {
  Login: async (
    userAddress: string,
    timestamp: string,
    signature: string
  ): Promise<xxsLoginServiceTypes.GetLoginResponse> => {
    let response: xxsLoginServiceTypes.GetLoginResponse
    const token = await xxaInitial.Check()
    const data = {
      wallet_address: userAddress,
      timestamp: timestamp,
      signature: signature
    }

    try {
      const responseData =
        await axios.post<xxsLoginServiceTypes.GetResponseSuccess>(
          `${servicePath.service.general}/v1/auth/token`,
          data,
          {
            headers: {
              Authorization: token,
              'device-Id': await xxaDevice.GetDeviceId()
            }
          }
        )

      response = {
        status: responseData.status,
        login: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  GetRefreshToken: async (
    refresh_token: string
  ): Promise<xxsLoginServiceTypes.GetLoginResponse> => {
    let response: xxsLoginServiceTypes.GetLoginResponse
    try {
      const responseData =
        await axios.get<xxsLoginServiceTypes.GetResponseSuccess>(
          `${servicePath.service.general}/v1/auth/refresh-token`,
          {
            headers: {
              Authorization: refresh_token,
              'device-Id': await xxaDevice.GetDeviceId()
            }
          }
        )

      response = {
        status: responseData.status,
        login: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  }
}

export default xxsLoginService
