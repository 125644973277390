// Types
import { Dispatch } from 'redux'

// Redux Constants
import { WEB3_CONNECT_SUCCESS } from 'redux/Constants/Web3'

// Utils
import xxuWeb3 from 'utils/Web3'

//Web3
import Web3 from 'web3'

//Utils
import xxuContract from 'utils/Contract'

const xxaWeb3 = {
  ConnectWeb3: () => (dispatch: Dispatch, getState: Function) => {
    if (window.ethereum) {
      // let w = new Web3(window.ethereum)
      // let netID = await w.eth.getChainId()
      let web3 = new Web3(window.ethereum)
      // let web3 = new Web3(
      //   new Web3.providers.HttpProvider('http://127.0.0.1:8545/')
      // )

      dispatch({
        type: WEB3_CONNECT_SUCCESS,
        payload: {
          web3
        }
      })
      // return web3
    } else {
      let web3 = new Web3(
        new Web3.providers.HttpProvider(xxuWeb3.GetWeb3().rpc)
      )
      dispatch({
        type: WEB3_CONNECT_SUCCESS,
        payload: {
          web3
        }
      })
      // return web3
    }
  }
}

export default xxaWeb3
