// import { AbiItem } from 'web3-utils'
import xxuContractProps from 'utils/Contract/types'

// let SaleAddress = '0x22B248966639754233a8b105e5AFDd1bCE7A7059'
// let MOONAddress = '0x4079FFA34b111E0221B7e5b8846243053867F974'
let SaleAddress :any = window.localStorage.getItem('SaleAddress') === null ?  '0x22B248966639754233a8b105e5AFDd1bCE7A7059' : window.localStorage.getItem('SaleAddress')  
let MOONAddress:any = window.localStorage.getItem('MOONAddress') === null ? '0x4079FFA34b111E0221B7e5b8846243053867F974': window.localStorage.getItem('MOONAddress')
const xxuContract = {
  GetContract: (): xxuContractProps.Contract => {
    if (process.env.REACT_APP_RUN_ON === 'production') {
      return {
        SaleAbi: [
          {
            inputs: [
              {
                internalType: 'uint256',
                name: '_icoStartTime',
                type: 'uint256'
              },
              { internalType: 'uint256', name: '_icoEndTime', type: 'uint256' },
              {
                internalType: 'uint256',
                name: '_tokenRateEth',
                type: 'uint256'
              },
              {
                internalType: 'address',
                name: '_tokenAddress',
                type: 'address'
              },
              {
                internalType: 'uint256',
                name: '_fundingGoal',
                type: 'uint256'
              },
              {
                internalType: 'address',
                name: '_tokenBuyAddress',
                type: 'address'
              },
              {
                internalType: 'uint256',
                name: '_tokenRateTokenBuy',
                type: 'uint256'
              },
              { internalType: 'address', name: '_distributor', type: 'address' }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'buyer',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'ethPaid',
                type: 'uint256'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenBought',
                type: 'uint256'
              }
            ],
            name: 'BuyToken',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'receiver',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenReceived',
                type: 'uint256'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenLocked',
                type: 'uint256'
              },
              {
                indexed: true,
                internalType: 'string',
                name: 'referenceTx',
                type: 'string'
              }
            ],
            name: 'BuyTokenWithDistributor',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'buyer',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenPaid',
                type: 'uint256'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenBought',
                type: 'uint256'
              }
            ],
            name: 'BuyTokenWithToken',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'withdrawer',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'ethReceived',
                type: 'uint256'
              }
            ],
            name: 'EmergencyWithdrawEther',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'withdrawer',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenReceived',
                type: 'uint256'
              }
            ],
            name: 'EmergencyWithdrawToken',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'withdrawer',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'ethReceived',
                type: 'uint256'
              }
            ],
            name: 'ExtractEther',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'withdrawer',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenReceived',
                type: 'uint256'
              }
            ],
            name: 'ExtractToken',
            type: 'event'
          },
          { stateMutability: 'payable', type: 'fallback' },
          {
            inputs: [],
            name: 'buy',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: '_receiver', type: 'address' },
              { internalType: 'uint256', name: '_amount', type: 'uint256' },
              { internalType: 'string', name: '_reference', type: 'string' }
            ],
            name: 'buyTokenWithDistributor',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'uint256', name: '_amount', type: 'uint256' },
              {
                internalType: 'address',
                name: '_tokenBuyAddress',
                type: 'address'
              }
            ],
            name: 'buyWithToken',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'chunkTime',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'claimLockToken',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'distributor',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'emergencyWithdrawEther',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_tokenAddress',
                type: 'address'
              }
            ],
            name: 'emergencyWithdrawToken',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'etherExceeds',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'etherRaised',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'extractEther',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_tokenAddress',
                type: 'address'
              }
            ],
            name: 'extractToken',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'fundingGoal',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'icoEndTime',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'icoStartTime',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'lockStartTime',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [{ internalType: 'address', name: '', type: 'address' }],
            name: 'lockUserToken',
            outputs: [
              { internalType: 'uint256', name: 'lockToken', type: 'uint256' },
              {
                internalType: 'uint256',
                name: 'currectLockToken',
                type: 'uint256'
              },
              { internalType: 'uint256', name: 'chunkClaimed', type: 'uint256' }
            ],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'maxChunk',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'owner',
            outputs: [
              { internalType: 'address payable', name: '', type: 'address' }
            ],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_tokenAddress',
                type: 'address'
              }
            ],
            name: 'setTokenAddress',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'token',
            outputs: [
              { internalType: 'contract ERC20', name: '', type: 'address' }
            ],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'tokenBuyExceeds',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'tokenBuyRaised',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'tokenLockRate',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'tokenRaised',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'tokenRateEth',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'tokenRateTokenBuy',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'totalTokenLock',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'unBuy',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
          },
          {
            inputs: [{ internalType: 'address', name: '', type: 'address' }],
            name: 'whitelistTokenBuyAddress',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          { stateMutability: 'payable', type: 'receive' }
        ],
        SaleAddress: '0x002f52E5a625dA0F6fB4AC528Ee09f310D1bfe2f',
        MOONAbi: [
          {
            inputs: [
              { internalType: 'uint256', name: '_ICOEndTime', type: 'uint256' },
              { internalType: 'string', name: '_name', type: 'string' },
              { internalType: 'string', name: '_symbol', type: 'string' }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'spender',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Approval',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Transfer',
            type: 'event'
          },
          {
            inputs: [],
            name: 'ICOEndTime',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'owner', type: 'address' },
              { internalType: 'address', name: 'spender', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'balanceOfOwner',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: '_receiver', type: 'address' },
              { internalType: 'uint256', name: '_amount', type: 'uint256' }
            ],
            name: 'buyTokens',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: '_receiver', type: 'address' },
              { internalType: 'uint256', name: '_amount', type: 'uint256' }
            ],
            name: 'claimToken',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'crowdsaleAddress',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              {
                internalType: 'uint256',
                name: 'subtractedValue',
                type: 'uint256'
              }
            ],
            name: 'decreaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'emergencyExtract',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
            ],
            name: 'increaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'name',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'owner',
            outputs: [
              { internalType: 'address payable', name: '', type: 'address' }
            ],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_crowdsaleAddress',
                type: 'address'
              }
            ],
            name: 'setCrowdsale',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: '_to', type: 'address' },
              { internalType: 'uint256', name: '_value', type: 'uint256' }
            ],
            name: 'transfer',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: '_from', type: 'address' },
              { internalType: 'address', name: '_to', type: 'address' },
              { internalType: 'uint256', name: '_value', type: 'uint256' }
            ],
            name: 'transferFrom',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          }
        ],
        MOONAddress: '0xF2573228EC0A8d639f747cCc555aEFD990915027',
        cUSDAbi: [
          {
            inputs: [
              {
                internalType: 'address',
                name: 'depositAddress',
                type: 'address'
              },
              {
                internalType: 'uint256',
                name: 'initialAmount',
                type: 'uint256'
              }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'spender',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Approval',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Paused',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'previousAdminRole',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'newAdminRole',
                type: 'bytes32'
              }
            ],
            name: 'RoleAdminChanged',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleGranted',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleRevoked',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Transfer',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Unpaused',
            type: 'event'
          },
          {
            inputs: [],
            name: 'DEFAULT_ADMIN_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'MINTER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'PAUSER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'owner', type: 'address' },
              { internalType: 'address', name: 'spender', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burnFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              {
                internalType: 'uint256',
                name: 'subtractedValue',
                type: 'uint256'
              }
            ],
            name: 'decreaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleAdmin',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'uint256', name: 'index', type: 'uint256' }
            ],
            name: 'getRoleMember',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleMemberCount',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'grantRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'hasRole',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
            ],
            name: 'increaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'name',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'paused',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'renounceRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'revokeRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }
            ],
            name: 'supportsInterface',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transfer',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'sender', type: 'address' },
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transferFrom',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          }
        ],
        cUSDAddress: '0x564AD0E536f5478375872A46f0B5B96a810F772C',
        BTCAbi: [
          {
            inputs: [
              {
                internalType: 'address',
                name: 'depositAddress',
                type: 'address'
              },
              {
                internalType: 'uint256',
                name: 'initialAmount',
                type: 'uint256'
              }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'spender',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Approval',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Paused',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'previousAdminRole',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'newAdminRole',
                type: 'bytes32'
              }
            ],
            name: 'RoleAdminChanged',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleGranted',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleRevoked',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Transfer',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Unpaused',
            type: 'event'
          },
          {
            inputs: [],
            name: 'DEFAULT_ADMIN_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'MINTER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'PAUSER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'owner', type: 'address' },
              { internalType: 'address', name: 'spender', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burnFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              {
                internalType: 'uint256',
                name: 'subtractedValue',
                type: 'uint256'
              }
            ],
            name: 'decreaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleAdmin',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'uint256', name: 'index', type: 'uint256' }
            ],
            name: 'getRoleMember',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleMemberCount',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'grantRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'hasRole',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
            ],
            name: 'increaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'name',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'paused',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'renounceRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'revokeRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }
            ],
            name: 'supportsInterface',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transfer',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'sender', type: 'address' },
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transferFrom',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          }
        ],
        BTCAddress: '0x32C238e15524C185Df1402b0a3c22304a0A6605D',
        ETHAbi: [
          {
            inputs: [
              {
                internalType: 'address',
                name: 'depositAddress',
                type: 'address'
              },
              {
                internalType: 'uint256',
                name: 'initialAmount',
                type: 'uint256'
              }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'spender',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Approval',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Paused',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'previousAdminRole',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'newAdminRole',
                type: 'bytes32'
              }
            ],
            name: 'RoleAdminChanged',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleGranted',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleRevoked',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Transfer',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Unpaused',
            type: 'event'
          },
          {
            inputs: [],
            name: 'DEFAULT_ADMIN_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'MINTER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'PAUSER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'owner', type: 'address' },
              { internalType: 'address', name: 'spender', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burnFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              {
                internalType: 'uint256',
                name: 'subtractedValue',
                type: 'uint256'
              }
            ],
            name: 'decreaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleAdmin',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'uint256', name: 'index', type: 'uint256' }
            ],
            name: 'getRoleMember',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleMemberCount',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'grantRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'hasRole',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
            ],
            name: 'increaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'name',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'paused',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'renounceRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'revokeRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }
            ],
            name: 'supportsInterface',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transfer',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'sender', type: 'address' },
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transferFrom',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          }
        ],
        ETHAddress: '0x5EB8FDb7ae4b1cE4C8b0933362a2aee3161156e0',
        BUSDAbi: [
          {
            inputs: [
              {
                internalType: 'address',
                name: 'depositAddress',
                type: 'address'
              },
              {
                internalType: 'uint256',
                name: 'initialAmount',
                type: 'uint256'
              }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'spender',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Approval',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Paused',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'previousAdminRole',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'newAdminRole',
                type: 'bytes32'
              }
            ],
            name: 'RoleAdminChanged',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleGranted',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleRevoked',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Transfer',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Unpaused',
            type: 'event'
          },
          {
            inputs: [],
            name: 'DEFAULT_ADMIN_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'MINTER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'PAUSER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'owner', type: 'address' },
              { internalType: 'address', name: 'spender', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burnFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              {
                internalType: 'uint256',
                name: 'subtractedValue',
                type: 'uint256'
              }
            ],
            name: 'decreaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleAdmin',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'uint256', name: 'index', type: 'uint256' }
            ],
            name: 'getRoleMember',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleMemberCount',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'grantRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'hasRole',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
            ],
            name: 'increaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'name',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'paused',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'renounceRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'revokeRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }
            ],
            name: 'supportsInterface',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transfer',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'sender', type: 'address' },
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transferFrom',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          }
        ],
        BUSDAddress: '0xe3D8AF49Bc9D48488942E8d9830c72b5B18EFe16',
        USDTAbi: [
          {
            inputs: [
              {
                internalType: 'address',
                name: 'depositAddress',
                type: 'address'
              },
              {
                internalType: 'uint256',
                name: 'initialAmount',
                type: 'uint256'
              }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'spender',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Approval',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Paused',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'previousAdminRole',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'newAdminRole',
                type: 'bytes32'
              }
            ],
            name: 'RoleAdminChanged',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleGranted',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleRevoked',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Transfer',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Unpaused',
            type: 'event'
          },
          {
            inputs: [],
            name: 'DEFAULT_ADMIN_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'MINTER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'PAUSER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'owner', type: 'address' },
              { internalType: 'address', name: 'spender', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burnFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              {
                internalType: 'uint256',
                name: 'subtractedValue',
                type: 'uint256'
              }
            ],
            name: 'decreaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleAdmin',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'uint256', name: 'index', type: 'uint256' }
            ],
            name: 'getRoleMember',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleMemberCount',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'grantRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'hasRole',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
            ],
            name: 'increaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'name',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'paused',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'renounceRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'revokeRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }
            ],
            name: 'supportsInterface',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transfer',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'sender', type: 'address' },
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transferFrom',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          }
        ],
        USDTAddress: '0x433922997513AefEd493CC970c68be4a9E860b62'
      }
    } else {
      return {
        SaleAbi: [
          {
            inputs: [
              {
                internalType: 'uint256',
                name: '_icoStartTime',
                type: 'uint256'
              },
              { internalType: 'uint256', name: '_icoEndTime', type: 'uint256' },
              {
                internalType: 'uint256',
                name: '_tokenRateEth',
                type: 'uint256'
              },
              {
                internalType: 'address',
                name: '_tokenAddress',
                type: 'address'
              },
              {
                internalType: 'uint256',
                name: '_fundingGoal',
                type: 'uint256'
              },
              {
                internalType: 'address',
                name: '_distributor',
                type: 'address'
              },
              {
                internalType: 'uint256',
                name: '_unlockStartTime',
                type: 'uint256'
              },
              { internalType: 'uint256', name: '_maxChunk', type: 'uint256' },
              {
                internalType: 'uint256',
                name: '_timePerChunk',
                type: 'uint256'
              },
              {
                components: [
                  {
                    internalType: 'address',
                    name: 'tokenBuyAddress',
                    type: 'address'
                  },
                  { internalType: 'uint256', name: 'rate', type: 'uint256' },
                  { internalType: 'uint256', name: 'min', type: 'uint256' },
                  { internalType: 'uint256', name: 'max', type: 'uint256' },
                  { internalType: 'uint8', name: 'decimals', type: 'uint8' }
                ],
                internalType: 'struct ICO.TokenBuy[]',
                name: '_tokenBuys',
                type: 'tuple[]'
              },
              {
                internalType: 'uint256',
                name: '_tokenLockRate',
                type: 'uint256'
              }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'buyer',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'ethPaid',
                type: 'uint256'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenReceived',
                type: 'uint256'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'tokensToLock',
                type: 'uint256'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'timestamp',
                type: 'uint256'
              }
            ],
            name: 'BuyToken',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'receiver',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenReceived',
                type: 'uint256'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenLocked',
                type: 'uint256'
              },
              {
                indexed: true,
                internalType: 'string',
                name: 'referenceTx',
                type: 'string'
              }
            ],
            name: 'BuyTokenWithDistributor',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'buyer',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenPaid',
                type: 'uint256'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenReceived',
                type: 'uint256'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'tokensToLock',
                type: 'uint256'
              },
              {
                indexed: false,
                internalType: 'address',
                name: 'tokenBuyAddress',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'timestamp',
                type: 'uint256'
              }
            ],
            name: 'BuyTokenWithToken',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'withdrawer',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'ethReceived',
                type: 'uint256'
              }
            ],
            name: 'EmergencyWithdrawEther',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'withdrawer',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenReceived',
                type: 'uint256'
              }
            ],
            name: 'EmergencyWithdrawToken',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'withdrawer',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'receiver',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'ethReceived',
                type: 'uint256'
              }
            ],
            name: 'ExtractEther',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'withdrawer',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'receiver',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenReceived',
                type: 'uint256'
              }
            ],
            name: 'ExtractToken',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'previousAdminRole',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'newAdminRole',
                type: 'bytes32'
              }
            ],
            name: 'RoleAdminChanged',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleGranted',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleRevoked',
            type: 'event'
          },
          { stateMutability: 'payable', type: 'fallback' },
          {
            inputs: [],
            name: 'CONFIG_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'DEFAULT_ADMIN_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'DISTRIBUTOR_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'FUND_OWNER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'PAUSER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              {
                internalType: 'address[]',
                name: '_whitelistUsersAddress',
                type: 'address[]'
              }
            ],
            name: 'addWhitelistUser',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'buy',
            outputs: [],
            stateMutability: 'payable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: '_receiver', type: 'address' },
              { internalType: 'uint256', name: '_amount', type: 'uint256' },
              { internalType: 'string', name: '_reference', type: 'string' }
            ],
            name: 'buyTokenWithDistributor',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'uint256', name: '_amount', type: 'uint256' },
              {
                internalType: 'address',
                name: '_tokenBuyAddress',
                type: 'address'
              }
            ],
            name: 'buyWithToken',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'checkLimitPerUser',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'claimLockToken',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'disableCheckLimitPerUser',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'enableCheckLimitPerUser',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'etherExceeds',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'etherRaised',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: '_fundOwner', type: 'address' },
              { internalType: 'uint256', name: '_amount', type: 'uint256' }
            ],
            name: 'extractEther',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              {
                internalType: 'address',
                name: '_tokenBuyAddress',
                type: 'address'
              },
              { internalType: 'address', name: '_fundOwner', type: 'address' },
              { internalType: 'uint256', name: '_amount', type: 'uint256' }
            ],
            name: 'extractTokenBuy',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'fundingGoal',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleAdmin',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'grantRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'hasRole',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'icoEndTime',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'icoStartTime',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'icoTokenDecimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'isPauseSaleWithCrypto',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'isPauseSaleWithDistributor',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'isWhitelistPeriod',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'limitTokenReceivePerUser',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [{ internalType: 'address', name: '', type: 'address' }],
            name: 'lockUserToken',
            outputs: [
              { internalType: 'uint256', name: 'lockToken', type: 'uint256' },
              {
                internalType: 'uint256',
                name: 'currectLockToken',
                type: 'uint256'
              },
              { internalType: 'uint256', name: 'chunkClaimed', type: 'uint256' }
            ],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'maxChunk',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'owner',
            outputs: [
              { internalType: 'address payable', name: '', type: 'address' }
            ],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'pauseSaleWithCrypto',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'pauseSaleWithDistributor',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'renounceRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'revokeRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'setICOComplete',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: '_limitTokenReceivePerUser',
                type: 'uint256'
              }
            ],
            name: 'setLimitTokenReceivePerUser',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: '_whitelistStartTime',
                type: 'uint256'
              },
              {
                internalType: 'uint256',
                name: '_whitelistEndTime',
                type: 'uint256'
              }
            ],
            name: 'setWhitelistPeriod',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }
            ],
            name: 'supportsInterface',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'timePerChunk',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'token',
            outputs: [
              { internalType: 'contract IERC20', name: '', type: 'address' }
            ],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'tokenBuyExceeds',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'tokenBuyRaised',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [{ internalType: 'address', name: '', type: 'address' }],
            name: 'tokenBuys',
            outputs: [
              {
                internalType: 'address',
                name: 'tokenBuyAddress',
                type: 'address'
              },
              { internalType: 'uint256', name: 'rate', type: 'uint256' },
              { internalType: 'uint256', name: 'min', type: 'uint256' },
              { internalType: 'uint256', name: 'max', type: 'uint256' },
              { internalType: 'uint8', name: 'decimals', type: 'uint8' }
            ],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'tokenLockRate',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'tokenRaised',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'tokenRateEth',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [{ internalType: 'address', name: '', type: 'address' }],
            name: 'tokenRateTokenBuys',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'totalTokenLock',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'unlockStartTime',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'unpauseSaleWithCrypto',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'unpauseSaleWithDistributor',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [{ internalType: 'address', name: '', type: 'address' }],
            name: 'userNativeBuyBalance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: '', type: 'address' },
              { internalType: 'address', name: '', type: 'address' }
            ],
            name: 'userTokenBuyBalance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [{ internalType: 'address', name: '', type: 'address' }],
            name: 'userTokenReceiveBalance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'whitelistEndTime',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'whitelistStartTime',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [{ internalType: 'address', name: '', type: 'address' }],
            name: 'whitelistTokenBuyAddress',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [{ internalType: 'address', name: '', type: 'address' }],
            name: 'whitelistUsers',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          { stateMutability: 'payable', type: 'receive' }
        ],
        SaleAddress: SaleAddress,
        MOONAbi: [
          {
            inputs: [
              { internalType: 'string', name: '_name', type: 'string' },
              { internalType: 'string', name: '_symbol', type: 'string' }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'spender',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Approval',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Transfer',
            type: 'event'
          },
          {
            inputs: [],
            name: 'ICOEndTime',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'owner', type: 'address' },
              { internalType: 'address', name: 'spender', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'balanceOfOwner',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'uint256', name: '_value', type: 'uint256' }
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'crowdsaleAddress',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              {
                internalType: 'uint256',
                name: 'subtractedValue',
                type: 'uint256'
              }
            ],
            name: 'decreaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'emergencyExtract',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
            ],
            name: 'increaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: '_to', type: 'address' },
              { internalType: 'uint256', name: '_value', type: 'uint256' }
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'name',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'owner',
            outputs: [
              { internalType: 'address payable', name: '', type: 'address' }
            ],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transfer',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'sender', type: 'address' },
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transferFrom',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          }
        ],
        MOONAddress: MOONAddress,
        cUSDAbi: [
          {
            inputs: [
              {
                internalType: 'address',
                name: 'depositAddress',
                type: 'address'
              },
              {
                internalType: 'uint256',
                name: 'initialAmount',
                type: 'uint256'
              }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'spender',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Approval',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Paused',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'previousAdminRole',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'newAdminRole',
                type: 'bytes32'
              }
            ],
            name: 'RoleAdminChanged',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleGranted',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleRevoked',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Transfer',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Unpaused',
            type: 'event'
          },
          {
            inputs: [],
            name: 'DEFAULT_ADMIN_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'MINTER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'PAUSER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'owner', type: 'address' },
              { internalType: 'address', name: 'spender', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burnFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              {
                internalType: 'uint256',
                name: 'subtractedValue',
                type: 'uint256'
              }
            ],
            name: 'decreaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleAdmin',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'uint256', name: 'index', type: 'uint256' }
            ],
            name: 'getRoleMember',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleMemberCount',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'grantRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'hasRole',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
            ],
            name: 'increaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'name',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'paused',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'renounceRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'revokeRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }
            ],
            name: 'supportsInterface',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transfer',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'sender', type: 'address' },
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transferFrom',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          }
        ],
        cUSDAddress: '0x564AD0E536f5478375872A46f0B5B96a810F772C',
        BTCAbi: [
          {
            inputs: [
              {
                internalType: 'address',
                name: 'depositAddress',
                type: 'address'
              },
              {
                internalType: 'uint256',
                name: 'initialAmount',
                type: 'uint256'
              }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'spender',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Approval',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Paused',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'previousAdminRole',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'newAdminRole',
                type: 'bytes32'
              }
            ],
            name: 'RoleAdminChanged',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleGranted',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleRevoked',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Transfer',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Unpaused',
            type: 'event'
          },
          {
            inputs: [],
            name: 'DEFAULT_ADMIN_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'MINTER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'PAUSER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'owner', type: 'address' },
              { internalType: 'address', name: 'spender', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burnFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              {
                internalType: 'uint256',
                name: 'subtractedValue',
                type: 'uint256'
              }
            ],
            name: 'decreaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleAdmin',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'uint256', name: 'index', type: 'uint256' }
            ],
            name: 'getRoleMember',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleMemberCount',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'grantRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'hasRole',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
            ],
            name: 'increaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'name',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'paused',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'renounceRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'revokeRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }
            ],
            name: 'supportsInterface',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transfer',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'sender', type: 'address' },
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transferFrom',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          }
        ],
        BTCAddress: '0x32C238e15524C185Df1402b0a3c22304a0A6605D',
        ETHAbi: [
          {
            inputs: [
              {
                internalType: 'address',
                name: 'depositAddress',
                type: 'address'
              },
              {
                internalType: 'uint256',
                name: 'initialAmount',
                type: 'uint256'
              }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'spender',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Approval',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Paused',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'previousAdminRole',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'newAdminRole',
                type: 'bytes32'
              }
            ],
            name: 'RoleAdminChanged',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleGranted',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleRevoked',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Transfer',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Unpaused',
            type: 'event'
          },
          {
            inputs: [],
            name: 'DEFAULT_ADMIN_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'MINTER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'PAUSER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'owner', type: 'address' },
              { internalType: 'address', name: 'spender', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burnFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              {
                internalType: 'uint256',
                name: 'subtractedValue',
                type: 'uint256'
              }
            ],
            name: 'decreaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleAdmin',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'uint256', name: 'index', type: 'uint256' }
            ],
            name: 'getRoleMember',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleMemberCount',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'grantRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'hasRole',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
            ],
            name: 'increaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'name',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'paused',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'renounceRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'revokeRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }
            ],
            name: 'supportsInterface',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transfer',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'sender', type: 'address' },
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transferFrom',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          }
        ],
        ETHAddress: '0x5EB8FDb7ae4b1cE4C8b0933362a2aee3161156e0',
        BUSDAbi: [
          {
            inputs: [
              {
                internalType: 'address',
                name: 'depositAddress',
                type: 'address'
              },
              {
                internalType: 'uint256',
                name: 'initialAmount',
                type: 'uint256'
              }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'spender',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Approval',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Paused',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'previousAdminRole',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'newAdminRole',
                type: 'bytes32'
              }
            ],
            name: 'RoleAdminChanged',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleGranted',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleRevoked',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Transfer',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Unpaused',
            type: 'event'
          },
          {
            inputs: [],
            name: 'DEFAULT_ADMIN_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'MINTER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'PAUSER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'owner', type: 'address' },
              { internalType: 'address', name: 'spender', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burnFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              {
                internalType: 'uint256',
                name: 'subtractedValue',
                type: 'uint256'
              }
            ],
            name: 'decreaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleAdmin',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'uint256', name: 'index', type: 'uint256' }
            ],
            name: 'getRoleMember',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleMemberCount',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'grantRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'hasRole',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
            ],
            name: 'increaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'name',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'paused',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'renounceRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'revokeRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }
            ],
            name: 'supportsInterface',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transfer',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'sender', type: 'address' },
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transferFrom',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          }
        ],
        BUSDAddress: '0xe3D8AF49Bc9D48488942E8d9830c72b5B18EFe16',
        USDTAbi: [
          {
            inputs: [
              {
                internalType: 'address',
                name: 'depositAddress',
                type: 'address'
              },
              {
                internalType: 'uint256',
                name: 'initialAmount',
                type: 'uint256'
              }
            ],
            stateMutability: 'nonpayable',
            type: 'constructor'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'owner',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'spender',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Approval',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Paused',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'previousAdminRole',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'newAdminRole',
                type: 'bytes32'
              }
            ],
            name: 'RoleAdminChanged',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleGranted',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'bytes32',
                name: 'role',
                type: 'bytes32'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'account',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'sender',
                type: 'address'
              }
            ],
            name: 'RoleRevoked',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: true,
                internalType: 'address',
                name: 'from',
                type: 'address'
              },
              {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address'
              },
              {
                indexed: false,
                internalType: 'uint256',
                name: 'value',
                type: 'uint256'
              }
            ],
            name: 'Transfer',
            type: 'event'
          },
          {
            anonymous: false,
            inputs: [
              {
                indexed: false,
                internalType: 'address',
                name: 'account',
                type: 'address'
              }
            ],
            name: 'Unpaused',
            type: 'event'
          },
          {
            inputs: [],
            name: 'DEFAULT_ADMIN_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'MINTER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'PAUSER_ROLE',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'owner', type: 'address' },
              { internalType: 'address', name: 'spender', type: 'address' }
            ],
            name: 'allowance',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'approve',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'balanceOf',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burn',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'account', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'burnFrom',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'decimals',
            outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              {
                internalType: 'uint256',
                name: 'subtractedValue',
                type: 'uint256'
              }
            ],
            name: 'decreaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleAdmin',
            outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'uint256', name: 'index', type: 'uint256' }
            ],
            name: 'getRoleMember',
            outputs: [{ internalType: 'address', name: '', type: 'address' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' }
            ],
            name: 'getRoleMemberCount',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'grantRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'hasRole',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'addedValue', type: 'uint256' }
            ],
            name: 'increaseAllowance',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'to', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'mint',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'name',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'pause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'paused',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'renounceRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes32', name: 'role', type: 'bytes32' },
              { internalType: 'address', name: 'account', type: 'address' }
            ],
            name: 'revokeRole',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }
            ],
            name: 'supportsInterface',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'symbol',
            outputs: [{ internalType: 'string', name: '', type: 'string' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [],
            name: 'totalSupply',
            outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
            stateMutability: 'view',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transfer',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [
              { internalType: 'address', name: 'sender', type: 'address' },
              { internalType: 'address', name: 'recipient', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' }
            ],
            name: 'transferFrom',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'nonpayable',
            type: 'function'
          },
          {
            inputs: [],
            name: 'unpause',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function'
          }
        ],
        USDTAddress: '0x433922997513AefEd493CC970c68be4a9E860b62'
      }
    }
  },
  SetContract: (saleAddressValue: string, MOONAddressValue: string) => {
    SaleAddress = saleAddressValue
    MOONAddress = MOONAddressValue
    window.localStorage.setItem('SaleAddress', saleAddressValue)
    window.localStorage.setItem('MOONAddress', MOONAddressValue)
    window.location.href = '/whitelist'
  }
}

export default xxuContract
